import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-10" };
const _hoisted_3 = { class: "text-title-h6 text-bold text-primary" };
const _hoisted_4 = { class: "text-primary" };
const _hoisted_5 = { class: "col-2 text-center self-center" };
const _hoisted_6 = { class: "col-12" };
const _hoisted_7 = { class: "col-10" };
const _hoisted_8 = { class: "text-title-h6 text-bold text-primary" };
const _hoisted_9 = { class: "text-primary" };
const _hoisted_10 = { class: "col-2 text-center self-center" };
const _hoisted_11 = { class: "col-12" };
const _hoisted_12 = { class: "col-12" };
const _hoisted_13 = { class: "row" };
const _hoisted_14 = { class: "col-10" };
const _hoisted_15 = { class: "text-title-h6 text-bold text-primary" };
const _hoisted_16 = { class: "text-primary" };
const _hoisted_17 = { class: "col-2 text-center self-center" };
const _hoisted_18 = { class: "col-12" };
const _hoisted_19 = { class: "col-10" };
const _hoisted_20 = { class: "text-title-h6 text-bold text-primary" };
const _hoisted_21 = { class: "text-primary" };
const _hoisted_22 = { class: "col-2 text-center self-center" };
const _hoisted_23 = { class: "col-12" };
const _hoisted_24 = { class: "col-12" };
const _hoisted_25 = { class: "row" };
const _hoisted_26 = { class: "col-10" };
const _hoisted_27 = { class: "text-title-h6 text-bold text-primary" };
const _hoisted_28 = { class: "text-primary" };
const _hoisted_29 = { class: "col-2 text-center self-center" };
const _hoisted_30 = { class: "col-12" };
const _hoisted_31 = { class: "col-10" };
const _hoisted_32 = { class: "text-title-h6 text-bold text-primary" };
const _hoisted_33 = { class: "text-primary" };
const _hoisted_34 = { class: "col-2 text-center self-center" };
const _hoisted_35 = { class: "col-12" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AdditionalParameters = _resolveComponent("AdditionalParameters");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_OrganizationInvoice = _resolveComponent("OrganizationInvoice");
    const _component_BankAccountSettings = _resolveComponent("BankAccountSettings");
    const _component_OrganizationSettings = _resolveComponent("OrganizationSettings");
    const _component_DefaultShipper = _resolveComponent("DefaultShipper");
    const _component_CourierPickupSettings = _resolveComponent("CourierPickupSettings");
    const _component_CardWrapper = _resolveComponent("CardWrapper");
    const _component_MainComponent = _resolveComponent("MainComponent");
    const _component_q_page = _resolveComponent("q-page");
    const _directive_permission = _resolveDirective("permission");
    return (_openBlock(), _createBlock(_component_q_page, null, {
        default: _withCtx(() => [
            _createVNode(_component_MainComponent, null, {
                default: _withCtx(() => [
                    _createVNode(_component_CardWrapper, null, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("settings.parametersSelection")), 1),
                                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("settings.parametersSelectionDescription")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_AdditionalParameters)
                                ]),
                                _createElementVNode("div", _hoisted_6, [
                                    _createVNode(_component_q_separator, { class: "q-mt-md q-mb-md" })
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("settings.invoice")), 1),
                                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("settings.invoiceDescription")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_10, [
                                    _createVNode(_component_OrganizationInvoice)
                                ]),
                                _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_q_separator, { class: "q-mt-md q-mb-md" })
                                ]),
                                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_12, [
                                    _createElementVNode("div", _hoisted_13, [
                                        _createElementVNode("div", _hoisted_14, [
                                            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("settings.bankAccountSettings")), 1),
                                            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("settings.bankAccountSettingsDescription")), 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_17, [
                                            _createVNode(_component_BankAccountSettings)
                                        ]),
                                        _createElementVNode("div", _hoisted_18, [
                                            _createVNode(_component_q_separator, { class: "q-mt-md q-mb-md" })
                                        ])
                                    ])
                                ])), [
                                    [_directive_permission, { permissions: _ctx.adminAndManager }]
                                ]),
                                _createElementVNode("div", _hoisted_19, [
                                    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t("settings.organizationSettings")), 1),
                                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("settings.organizationSettingsDescription")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_22, [
                                    _createVNode(_component_OrganizationSettings)
                                ]),
                                _createElementVNode("div", _hoisted_23, [
                                    _createVNode(_component_q_separator, { class: "q-mt-md q-mb-md" })
                                ]),
                                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_24, [
                                    _createElementVNode("div", _hoisted_25, [
                                        _createElementVNode("div", _hoisted_26, [
                                            _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.$t("settings.organizationDetails")), 1),
                                            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t("settings.organizationDetailsDescription")), 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_29, [
                                            _createVNode(_component_DefaultShipper)
                                        ]),
                                        _createElementVNode("div", _hoisted_30, [
                                            _createVNode(_component_q_separator, { class: "q-mt-md q-mb-md" })
                                        ])
                                    ])
                                ])), [
                                    [_directive_permission, { permissions: _ctx.adminAndManager }]
                                ]),
                                _createElementVNode("div", _hoisted_31, [
                                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("settings.courierPickupSettings")), 1),
                                    _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t("settings.courierPickupSettingsDescription")), 1)
                                ]),
                                _createElementVNode("div", _hoisted_34, [
                                    _createVNode(_component_CourierPickupSettings)
                                ]),
                                _createElementVNode("div", _hoisted_35, [
                                    _createVNode(_component_q_separator, { class: "q-mt-md q-mb-md" })
                                ])
                            ])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
