import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-59975e59"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row bg-color-secondary" };
const _hoisted_2 = { class: "box-padding" };
const _hoisted_3 = { class: "row q-pa-md" };
const _hoisted_4 = { class: "col col-6" };
const _hoisted_5 = { class: "row q-pa-sm" };
const _hoisted_6 = { class: "col col-12" };
const _hoisted_7 = { class: "font-service q-ml-md text-primary" };
const _hoisted_8 = ["disabled"];
const _hoisted_9 = { class: "row q-pa-sm" };
const _hoisted_10 = { class: "col col-6" };
const _hoisted_11 = { class: "font-service q-ml-md text-primary" };
const _hoisted_12 = { class: "col col-6" };
const _hoisted_13 = { class: "font-service q-ml-md text-primary" };
const _hoisted_14 = { class: "row q-pa-sm" };
const _hoisted_15 = { class: "col col-6 q-pr-xs" };
const _hoisted_16 = { class: "col col-6 q-pl-xs" };
const _hoisted_17 = { key: 0 };
const _hoisted_18 = { class: "row q-pa-sm" };
const _hoisted_19 = { class: "col col-6 q-pr-xs" };
const _hoisted_20 = { class: "col col-6 q-pl-xs" };
const _hoisted_21 = { class: "row q-pa-sm" };
const _hoisted_22 = { class: "col col-12" };
const _hoisted_23 = { class: "row q-pa-sm q-mb-md" };
const _hoisted_24 = { class: "col col-12" };
const _hoisted_25 = { class: "row q-pa-sm" };
const _hoisted_26 = { class: "col col-6 q-pr-xs" };
const _hoisted_27 = { class: "col col-6 q-pl-xs" };
const _hoisted_28 = { class: "row items-center justify-end" };
const _hoisted_29 = { class: "row q-pa-sm" };
const _hoisted_30 = { class: "col col-12 q-px-sm" };
const _hoisted_31 = { class: "col col-6" };
const _hoisted_32 = ["disabled"];
const _hoisted_33 = { class: "row q-pa-sm" };
const _hoisted_34 = { class: "col col-12" };
const _hoisted_35 = { class: "row q-col-gutter-sm q-pa-sm q-pb-md q-pt-md" };
const _hoisted_36 = { class: "col col-6" };
const _hoisted_37 = { class: "col col-6" };
const _hoisted_38 = { class: "row q-col-gutter-sm q-pa-sm q-pb-md q-pt-md" };
const _hoisted_39 = { class: "col col-4" };
const _hoisted_40 = { class: "col col-4" };
const _hoisted_41 = { class: "col col-4" };
const _hoisted_42 = {
    key: 0,
    class: "row"
};
const _hoisted_43 = { class: "col-12 q-mb-xs" };
const _hoisted_44 = { class: "row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_radio = _resolveComponent("q-radio");
    const _component_q_select = _resolveComponent("q-select");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_input = _resolveComponent("q-input");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_date = _resolveComponent("q-date");
    const _component_q_popup_proxy = _resolveComponent("q-popup-proxy");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_file = _resolveComponent("q-file");
    const _component_CountrySelect = _resolveComponent("CountrySelect");
    const _component_q_space = _resolveComponent("q-space");
    const _component_q_expansion_item = _resolveComponent("q-expansion-item");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_card = _resolveComponent("q-card");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_q_card, null, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$q.screen.lt.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                        }, [
                            _createElementVNode("div", _hoisted_2, [
                                (_ctx.declaration && _ctx.declaration.invoice)
                                    ? (_openBlock(), _createBlock(_component_q_form, {
                                        key: 0,
                                        ref: "form"
                                    }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_3, [
                                                _createElementVNode("div", _hoisted_4, [
                                                    _createElementVNode("div", _hoisted_5, [
                                                        _createElementVNode("div", _hoisted_6, [
                                                            _createVNode(_component_q_checkbox, {
                                                                disable: !_ctx.userSelectedNoClearanceAvailable,
                                                                modelValue: _ctx.declaration.userSelectedNoClearanceShipment,
                                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.declaration.userSelectedNoClearanceShipment) = $event))
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("customs.userSelectedNoClearanceShipment")), 1)
                                                                ]),
                                                                _: 1
                                                            }, 8, ["disable", "modelValue"])
                                                        ])
                                                    ]),
                                                    _createElementVNode("fieldset", {
                                                        class: "hiddenFrame",
                                                        style: _normalizeStyle([_ctx.declaration.userSelectedNoClearanceShipment ? 'pointer-events: none;' : '']),
                                                        disabled: _ctx.declaration.userSelectedNoClearanceShipment
                                                    }, [
                                                        _createElementVNode("div", _hoisted_9, [
                                                            _createElementVNode("div", _hoisted_10, [
                                                                _createVNode(_component_q_radio, {
                                                                    modelValue: _ctx.customsType,
                                                                    "onUpdate:modelValue": [
                                                                        _cache[1] || (_cache[1] = ($event) => ((_ctx.customsType) = $event)),
                                                                        _ctx.updateCommercial
                                                                    ],
                                                                    val: _ctx.CustomsType.Commercial,
                                                                    class: "delivery-service__radio"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("customs.commercial")), 1)
                                                                    ]),
                                                                    _: 1
                                                                }, 8, ["modelValue", "val", "onUpdate:modelValue"])
                                                            ]),
                                                            _createElementVNode("div", _hoisted_12, [
                                                                _createVNode(_component_q_radio, {
                                                                    modelValue: _ctx.customsType,
                                                                    "onUpdate:modelValue": [
                                                                        _cache[2] || (_cache[2] = ($event) => ((_ctx.customsType) = $event)),
                                                                        _ctx.updateCommercial
                                                                    ],
                                                                    val: _ctx.CustomsType.NonCommercial,
                                                                    class: "delivery-service__radio"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("customs.nonCommercial")), 1)
                                                                    ]),
                                                                    _: 1
                                                                }, 8, ["modelValue", "val", "onUpdate:modelValue"])
                                                            ])
                                                        ]),
                                                        _createElementVNode("div", _hoisted_14, [
                                                            _createElementVNode("div", _hoisted_15, [
                                                                _createVNode(_component_LabelWrapper, {
                                                                    label: _ctx.$t('customs.incoterm'),
                                                                    "label-class": "text-primary",
                                                                    "inner-class": "bg-light",
                                                                    "outer-class": "bg-color-secondary"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_select, {
                                                                            modelValue: _ctx.declaration.incoterm,
                                                                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.declaration.incoterm) = $event)),
                                                                            outlined: "",
                                                                            "bg-color": "light",
                                                                            "no-error-icon": "",
                                                                            options: _ctx.incoterms,
                                                                            "option-value": (item) => item.code,
                                                                            "option-label": (item) => item.name,
                                                                            "map-options": "",
                                                                            "emit-value": "",
                                                                            "virtual-scroll-slice-size": "300",
                                                                            "lazy-rules": "",
                                                                            rules: [(val) => val != null || _ctx.$t('customs.incotermRequired')]
                                                                        }, null, 8, ["modelValue", "options", "option-value", "option-label", "rules"])
                                                                    ]),
                                                                    _: 1
                                                                }, 8, ["label"])
                                                            ]),
                                                            _createElementVNode("div", _hoisted_16, [
                                                                _createVNode(_component_LabelWrapper, {
                                                                    label: _ctx.$t('customs.currency'),
                                                                    "label-class": "text-primary",
                                                                    "inner-class": "bg-light",
                                                                    "outer-class": "bg-color-secondary"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_select, {
                                                                            modelValue: _ctx.declaration.currencyCode,
                                                                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.declaration.currencyCode) = $event)),
                                                                            outlined: "",
                                                                            "bg-color": "light",
                                                                            "no-error-icon": "",
                                                                            options: _ctx.currencies,
                                                                            "option-value": (item) => item.currencyCode,
                                                                            "option-label": (item) => item.currencyCode,
                                                                            "map-options": "",
                                                                            "emit-value": "",
                                                                            "virtual-scroll-slice-size": "300",
                                                                            "lazy-rules": "",
                                                                            rules: [(val) => val != null || _ctx.$t('customs.currencyRequired')]
                                                                        }, null, 8, ["modelValue", "options", "option-value", "option-label", "rules"])
                                                                    ]),
                                                                    _: 1
                                                                }, 8, ["label"])
                                                            ])
                                                        ]),
                                                        (_ctx.displayCommercialFields)
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                                _createElementVNode("div", _hoisted_18, [
                                                                    _createElementVNode("div", _hoisted_19, [
                                                                        _createVNode(_component_LabelWrapper, {
                                                                            label: _ctx.$t('customs.shipperEori'),
                                                                            "label-class": "text-primary",
                                                                            "inner-class": "bg-light",
                                                                            "outer-class": "bg-color-secondary"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_input, {
                                                                                    modelValue: _ctx.declaration.shipperEori,
                                                                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.declaration.shipperEori) = $event)),
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    placeholder: _ctx.$t('customs.shipperEoriPlaceholder'),
                                                                                    "no-error-icon": "",
                                                                                    "lazy-rules": "",
                                                                                    rules: [
                                                                                        (val) => _ctx.requiredField(val) || _ctx.$t('customs.shipperEoriRequired'),
                                                                                        (val) => _ctx.isEori(val) || _ctx.$t('customs.eoriIncorrect'),
                                                                                    ]
                                                                                }, null, 8, ["modelValue", "placeholder", "rules"])
                                                                            ]),
                                                                            _: 1
                                                                        }, 8, ["label"])
                                                                    ]),
                                                                    _createElementVNode("div", _hoisted_20, [
                                                                        _createVNode(_component_LabelWrapper, {
                                                                            label: _ctx.$t('customs.recipientEori'),
                                                                            "label-class": "text-primary",
                                                                            "inner-class": "bg-light",
                                                                            "outer-class": "bg-color-secondary"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_input, {
                                                                                    modelValue: _ctx.declaration.recipientEori,
                                                                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.declaration.recipientEori) = $event)),
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    placeholder: _ctx.$t('customs.recipientEoriPlaceholder'),
                                                                                    "no-error-icon": "",
                                                                                    "lazy-rules": "",
                                                                                    rules: [
                                                                                        (val) => _ctx.requiredField(val) || _ctx.$t('customs.recipientEoriRequired'),
                                                                                        (val) => _ctx.isEori(val) || _ctx.$t('customs.eoriIncorrect'),
                                                                                    ]
                                                                                }, null, 8, ["modelValue", "placeholder", "rules"])
                                                                            ]),
                                                                            _: 1
                                                                        }, 8, ["label"])
                                                                    ])
                                                                ]),
                                                                _createElementVNode("div", _hoisted_21, [
                                                                    _createElementVNode("div", _hoisted_22, [
                                                                        _createVNode(_component_LabelWrapper, {
                                                                            label: _ctx.$t('customs.shipperVatRegistrationNumber'),
                                                                            "label-class": "text-primary",
                                                                            "inner-class": "bg-light",
                                                                            "outer-class": "bg-color-secondary"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_input, {
                                                                                    modelValue: _ctx.declaration.shipperVatRegistrationNumber,
                                                                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((_ctx.declaration.shipperVatRegistrationNumber) = $event)),
                                                                                    number: "",
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    "no-error-icon": "",
                                                                                    rules: [
                                                                                        (val) => _ctx.requiredField(val) || _ctx.$t('customs.shipperVatRegistrationNumberRequired'),
                                                                                    ]
                                                                                }, null, 8, ["modelValue", "rules"])
                                                                            ]),
                                                                            _: 1
                                                                        }, 8, ["label"])
                                                                    ])
                                                                ]),
                                                                _createElementVNode("div", _hoisted_23, [
                                                                    _createElementVNode("div", _hoisted_24, [
                                                                        _createVNode(_component_LabelWrapper, {
                                                                            label: _ctx.$t('customs.bn'),
                                                                            "label-class": "text-primary",
                                                                            "inner-class": "bg-light",
                                                                            "outer-class": "bg-color-secondary"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_input, {
                                                                                    modelValue: _ctx.declaration.businessNumber,
                                                                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.declaration.businessNumber) = $event)),
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    placeholder: _ctx.$t('customs.bnPlaceholder'),
                                                                                    "no-error-icon": ""
                                                                                }, null, 8, ["modelValue", "placeholder"])
                                                                            ]),
                                                                            _: 1
                                                                        }, 8, ["label"])
                                                                    ])
                                                                ]),
                                                                _createElementVNode("div", _hoisted_25, [
                                                                    _createElementVNode("div", _hoisted_26, [
                                                                        _createVNode(_component_LabelWrapper, {
                                                                            label: _ctx.$t('customs.invoice'),
                                                                            "label-class": "text-primary",
                                                                            "inner-class": "bg-light",
                                                                            "outer-class": "bg-color-secondary"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_input, {
                                                                                    modelValue: _ctx.declaration.invoice.invoiceNumber,
                                                                                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((_ctx.declaration.invoice.invoiceNumber) = $event)),
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    placeholder: _ctx.$t('customs.bnPlaceholder'),
                                                                                    "no-error-icon": "",
                                                                                    "lazy-rules": "",
                                                                                    rules: [(val) => _ctx.requiredField(val) || _ctx.$t('customs.invoiceRequired')]
                                                                                }, null, 8, ["modelValue", "placeholder", "rules"])
                                                                            ]),
                                                                            _: 1
                                                                        }, 8, ["label"])
                                                                    ]),
                                                                    _createElementVNode("div", _hoisted_27, [
                                                                        _createVNode(_component_LabelWrapper, {
                                                                            label: _ctx.$t('customs.invoiceDate'),
                                                                            "label-class": "text-primary",
                                                                            "inner-class": "bg-light",
                                                                            "outer-class": "bg-color-secondary"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_input, {
                                                                                    modelValue: _ctx.declaration.invoice.date,
                                                                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((_ctx.declaration.invoice.date) = $event)),
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    "no-error-icon": "",
                                                                                    mask: "date",
                                                                                    "lazy-rules": "",
                                                                                    rules: [(val) => _ctx.requiredField(val) || _ctx.$t('customs.invoiceDateRequired')]
                                                                                }, {
                                                                                    append: _withCtx(() => [
                                                                                        _createVNode(_component_q_icon, {
                                                                                            name: "event",
                                                                                            class: "cursor-pointer"
                                                                                        }, {
                                                                                            default: _withCtx(() => [
                                                                                                _createVNode(_component_q_popup_proxy, {
                                                                                                    cover: "",
                                                                                                    "transition-show": "scale",
                                                                                                    "transition-hide": "scale"
                                                                                                }, {
                                                                                                    default: _withCtx(() => [
                                                                                                        _createVNode(_component_q_date, {
                                                                                                            modelValue: _ctx.declaration.invoice.date,
                                                                                                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((_ctx.declaration.invoice.date) = $event)),
                                                                                                            minimal: "",
                                                                                                            locale: _ctx.getLocale,
                                                                                                            "first-day-of-week": "1"
                                                                                                        }, {
                                                                                                            default: _withCtx(() => [
                                                                                                                _createElementVNode("div", _hoisted_28, [
                                                                                                                    _withDirectives(_createVNode(_component_q_btn, {
                                                                                                                        label: _ctx.$t('customs.invoiceDateClose'),
                                                                                                                        color: "primary"
                                                                                                                    }, null, 8, ["label"]), [
                                                                                                                        [_directive_close_popup]
                                                                                                                    ])
                                                                                                                ])
                                                                                                            ]),
                                                                                                            _: 1
                                                                                                        }, 8, ["modelValue", "locale"])
                                                                                                    ]),
                                                                                                    _: 1
                                                                                                })
                                                                                            ]),
                                                                                            _: 1
                                                                                        })
                                                                                    ]),
                                                                                    _: 1
                                                                                }, 8, ["modelValue", "rules"])
                                                                            ]),
                                                                            _: 1
                                                                        }, 8, ["label"])
                                                                    ])
                                                                ]),
                                                                _createElementVNode("div", _hoisted_29, [
                                                                    _createElementVNode("div", _hoisted_30, [
                                                                        _createVNode(_component_q_file, {
                                                                            modelValue: _ctx.file,
                                                                            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event) => ((_ctx.file) = $event)),
                                                                            label: _ctx.$t('customs.attachments'),
                                                                            clearable: "",
                                                                            accept: ".jpg, .pdf"
                                                                        }, null, 8, ["modelValue", "label"])
                                                                    ])
                                                                ])
                                                            ]))
                                                            : _createCommentVNode("", true)
                                                    ], 12, _hoisted_8)
                                                ]),
                                                _createElementVNode("div", _hoisted_31, [
                                                    _createElementVNode("fieldset", {
                                                        class: "hiddenFrame",
                                                        disabled: _ctx.declaration.userSelectedNoClearanceShipment
                                                    }, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.declaration.items, (pack, index) => {
                                                            return (_openBlock(), _createElementBlock("div", {
                                                                key: index,
                                                                class: "row q-pa-sm"
                                                            }, [
                                                                _createVNode(_component_q_expansion_item, {
                                                                    class: "shadow-1 overflow-hidden bg-color-secondary text-primary",
                                                                    "header-class": "bg-color-secondary text-primary q-px-lg",
                                                                    "expand-icon-class": "text-primary",
                                                                    "default-opened": "",
                                                                    "expand-icon-toggle": "",
                                                                    "expand-separator": "",
                                                                    label: _ctx.$t('customs.item') + ' ' + (index + 1),
                                                                    style: { "width": "100%" }
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createElementVNode("div", _hoisted_33, [
                                                                            _createElementVNode("div", _hoisted_34, [
                                                                                _createVNode(_component_LabelWrapper, {
                                                                                    label: _ctx.$t('customs.description'),
                                                                                    "label-class": "text-primary",
                                                                                    "inner-class": "bg-light",
                                                                                    "outer-class": "bg-color-secondary"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_q_input, {
                                                                                            modelValue: pack.description,
                                                                                            "onUpdate:modelValue": ($event) => ((pack.description) = $event),
                                                                                            number: "",
                                                                                            outlined: "",
                                                                                            "bg-color": "light",
                                                                                            "no-error-icon": "",
                                                                                            "lazy-rules": "",
                                                                                            rules: [
                                                                                                (val) => _ctx.requiredField(val) || _ctx.$t('customs.descriptionRequired'),
                                                                                                (val) => _ctx.isCorrectLength(val, 10, Number.MAX_SAFE_INTEGER) ||
                                                                                                    _ctx.$t('customs.descriptionMinLength'),
                                                                                            ]
                                                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["label"])
                                                                            ])
                                                                        ]),
                                                                        _createElementVNode("div", _hoisted_35, [
                                                                            _createElementVNode("div", _hoisted_36, [
                                                                                _createVNode(_component_CountrySelect, {
                                                                                    modelValue: pack.manufacturerCountryCode,
                                                                                    "onUpdate:modelValue": ($event) => ((pack.manufacturerCountryCode) = $event),
                                                                                    label: "customs.country",
                                                                                    "label-required": "customs.countryRequired"
                                                                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                                                            ]),
                                                                            _createElementVNode("div", _hoisted_37, [
                                                                                _createVNode(_component_LabelWrapper, {
                                                                                    label: _ctx.$t('customs.price'),
                                                                                    "label-class": "text-primary",
                                                                                    "inner-class": "bg-light",
                                                                                    "outer-class": "bg-color-secondary"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_q_input, {
                                                                                            modelValue: pack.price,
                                                                                            "onUpdate:modelValue": ($event) => ((pack.price) = $event),
                                                                                            type: "number",
                                                                                            outlined: "",
                                                                                            "bg-color": "light",
                                                                                            suffix: _ctx.currency,
                                                                                            "no-error-icon": "",
                                                                                            "lazy-rules": "",
                                                                                            rules: [(val) => _ctx.isPositive(val) || _ctx.$t('customs.pricePositive')]
                                                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "suffix", "rules"])
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["label"])
                                                                            ])
                                                                        ]),
                                                                        _createElementVNode("div", _hoisted_38, [
                                                                            _createElementVNode("div", _hoisted_39, [
                                                                                _createVNode(_component_LabelWrapper, {
                                                                                    label: _ctx.$t('customs.amount'),
                                                                                    "label-class": "text-primary",
                                                                                    "inner-class": "bg-light",
                                                                                    "outer-class": "bg-color-secondary"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_q_input, {
                                                                                            modelValue: pack.quantity,
                                                                                            "onUpdate:modelValue": ($event) => ((pack.quantity) = $event),
                                                                                            type: "number",
                                                                                            outlined: "",
                                                                                            "bg-color": "light",
                                                                                            "no-error-icon": "",
                                                                                            "lazy-rules": "",
                                                                                            rules: [(val) => _ctx.isPositive(val) || _ctx.$t('customs.amountPositive')]
                                                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["label"])
                                                                            ]),
                                                                            _createElementVNode("div", _hoisted_40, [
                                                                                _createVNode(_component_LabelWrapper, {
                                                                                    label: _ctx.$t('customs.weight'),
                                                                                    "label-class": "text-primary",
                                                                                    "inner-class": "bg-light",
                                                                                    "outer-class": "bg-color-secondary"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_q_input, {
                                                                                            modelValue: pack.weight,
                                                                                            "onUpdate:modelValue": ($event) => ((pack.weight) = $event),
                                                                                            suffix: "kg",
                                                                                            type: "number",
                                                                                            outlined: "",
                                                                                            "bg-color": "light",
                                                                                            "no-error-icon": "",
                                                                                            "lazy-rules": "",
                                                                                            rules: [(val) => _ctx.isPositive(val) || _ctx.$t('customs.weightPositive')]
                                                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["label"])
                                                                            ]),
                                                                            _createElementVNode("div", _hoisted_41, [
                                                                                _createVNode(_component_LabelWrapper, {
                                                                                    label: _ctx.$t('customs.isztar'),
                                                                                    "label-class": "text-primary",
                                                                                    "inner-class": "bg-light",
                                                                                    "outer-class": "bg-color-secondary"
                                                                                }, {
                                                                                    default: _withCtx(() => [
                                                                                        _createVNode(_component_q_input, {
                                                                                            modelValue: pack.commodityCode,
                                                                                            "onUpdate:modelValue": ($event) => ((pack.commodityCode) = $event),
                                                                                            number: "",
                                                                                            outlined: "",
                                                                                            "bg-color": "light",
                                                                                            "no-error-icon": "",
                                                                                            "lazy-rules": "",
                                                                                            rules: [(val) => _ctx.requiredField(val) || _ctx.$t('customs.isztarRequired')]
                                                                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["label"])
                                                                            ])
                                                                        ]),
                                                                        (_ctx.canRemove())
                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                                                                _createElementVNode("div", _hoisted_43, [
                                                                                    _createElementVNode("div", _hoisted_44, [
                                                                                        _createVNode(_component_q_space),
                                                                                        _createVNode(_component_q_btn, {
                                                                                            class: "q-mr-sm",
                                                                                            color: "primary",
                                                                                            label: _ctx.$t('common.remove'),
                                                                                            onClick: ($event) => (_ctx.remove(index))
                                                                                        }, null, 8, ["label", "onClick"])
                                                                                    ])
                                                                                ])
                                                                            ]))
                                                                            : _createCommentVNode("", true)
                                                                    ]),
                                                                    _: 2
                                                                }, 1032, ["label"])
                                                            ]));
                                                        }), 128))
                                                    ], 8, _hoisted_32)
                                                ])
                                            ])
                                        ]),
                                        _: 1
                                    }, 512))
                                    : _createCommentVNode("", true)
                            ])
                        ], 2)
                    ], 2)
                ])
            ]),
            _: 1
        }),
        (_openBlock(), _createBlock(_Teleport, { to: "#footer-actions" }, [
            _createVNode(_component_q_btn, {
                "no-caps": "",
                class: "bg-color-secondary text-bold text-primary",
                onClick: _ctx.addNewItem
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                        class: "q-mr-md",
                        name: "mdi-plus-circle",
                        color: "accent"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("customs.addNextItem")), 1)
                ]),
                _: 1
            }, 8, ["onClick"])
        ]))
    ], 64));
}
