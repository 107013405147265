export class FilterCollection {
    types;
    constructor(o) {
        // works as a copy constructor as well
        this.types = (o?.types ?? []).map((v) => {
            return {
                key: v.key,
                label: v.label,
                multiples: v.multiples,
                asCore: v.asCore,
                values: v.values?.map((vv) => {
                    return {
                        key: vv.key,
                        label: vv.label,
                        id: vv.id,
                        parameters: Object.assign({}, vv.parameters),
                    };
                }),
            };
        });
    }
    getActiveFilters() {
        return this.types.filter((v) => v.values.length);
    }
    getFilterValues() {
        return this.types.flatMap((v) => v.values);
    }
    getParameters() {
        const params = {};
        this.getActiveFilters().map((v) => {
            v.values.forEach((vv) => {
                Object.keys(vv.parameters).forEach((vvv) => {
                    if (!v.multiples) {
                        params[vvv] = vv.parameters[vvv];
                    }
                    else {
                        if (!Array.isArray(params[vvv]))
                            params[vvv] = [];
                        params[vvv].push(vv.parameters[vvv]);
                    }
                });
            });
        });
        return params;
    }
    apply(request) {
        request.filters = this.getActiveFilters().map((v) => {
            return { key: v.key, disable: false, asCore: v.asCore ?? false };
        });
        const parameters = this.getParameters();
        request.parameters = Object.keys(parameters).map((v) => {
            return {
                key: v,
                value: JSON.stringify(parameters[v]),
            };
        });
    }
    clearFilters(key) {
        if (!key)
            this.types.forEach((v) => (v.values = []));
        else {
            const type = this.types.find((v) => v.key == key);
            if (type)
                type.values = [];
        }
    }
    setFilter(value) {
        const type = this.types.find((v) => v.key == value.key);
        if (!type)
            return;
        if (value.id && type.values.some((v) => v.id == value.id))
            return;
        if (!type.multiples)
            type.values = [];
        type.values.push(value);
    }
    removeFilter(key, id) {
        const type = this.types.find((v) => v.key == key);
        if (!type)
            return;
        const index = type.values.findIndex((v) => v.id == id);
        if (index >= 0)
            type.values.splice(index, 1);
    }
    hasFilter(key, id) {
        const type = this.types.find((v) => v.key == key);
        if (!type)
            return false;
        return !id ? type.values.length > 0 : !!type.values.find((v) => v.id == id);
    }
}
