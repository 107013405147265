import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/menu/logo.svg';
import _imports_1 from '@/assets/menu/logo_top.svg';
const _withScopeId = n => (_pushScopeId("data-v-1abc1b17"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    src: _imports_0,
    class: "no-pointer-events"
};
const _hoisted_2 = {
    key: 1,
    src: _imports_1,
    class: "no-pointer-events logo-top"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = {
    class: "text-white",
    style: { "position": "fixed", "left": "5px", "bottom": "0px", "z-index": "1001" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_item_label = _resolveComponent("q-item-label");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_expansion_item = _resolveComponent("q-expansion-item");
    const _component_q_drawer = _resolveComponent("q-drawer");
    const _directive_permission = _resolveDirective("permission");
    return (_openBlock(), _createBlock(_component_q_drawer, {
        modelValue: _ctx.leftDrawerOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.leftDrawerOpen) = $event)),
        "show-if-above": "",
        bordered: "",
        "mini-to-overlay": "",
        mini: _ctx.menuMinimized || !_ctx.loggedIn,
        class: "bg-primary",
        "mini-width": 65,
        onMouseover: _cache[1] || (_cache[1] = ($event) => (_ctx.menuMinimized = false)),
        onMouseout: _cache[2] || (_cache[2] = ($event) => (_ctx.menuMinimized = true))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_list, { class: "menu" }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_item, { class: "logo-item menu-item" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, {
                                avatar: "",
                                class: "menu-icon"
                            }, {
                                default: _withCtx(() => [
                                    (_ctx.showFullLogo)
                                        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
                                        : (_openBlock(), _createElementBlock("img", _hoisted_2))
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item_section)
                        ]),
                        _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (item, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                            (_ctx.loggedIn)
                                ? _withDirectives((_openBlock(), _createBlock(_component_q_expansion_item, {
                                    key: 0,
                                    clickable: "",
                                    tag: item.tag || 'router-link',
                                    to: item.to,
                                    active: item.isActive === true || _ctx.isActive(item.isActive),
                                    "content-inset-level": 0.5,
                                    "default-opened": _ctx.isChildActive(item),
                                    "expand-icon-class": item.subtabs?.length ? 'menu-item__expand-icon' : 'hidden',
                                    "header-class": "menu-item"
                                }, {
                                    header: _withCtx(() => [
                                        _createVNode(_component_q_item_section, {
                                            avatar: "",
                                            class: "menu-icon"
                                        }, {
                                            default: _withCtx(() => [
                                                _createElementVNode("img", {
                                                    src: require('@/assets/menu/' + item.img)
                                                }, null, 8, _hoisted_3)
                                            ]),
                                            _: 2
                                        }, 1024),
                                        _createVNode(_component_q_item_section, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_item_label, null, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t(item.label)), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]),
                                    default: _withCtx(() => [
                                        (item.subtabs?.length)
                                            ? (_openBlock(), _createBlock(_component_q_list, { key: 0 }, {
                                                default: _withCtx(() => [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.subtabs, (subitem, index2) => {
                                                        return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                            key: index2,
                                                            clickable: "",
                                                            tag: subitem.tag || 'router-link',
                                                            to: subitem.to,
                                                            active: subitem.isActive === true || _ctx.isActive(subitem.isActive),
                                                            class: "menu-item"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_q_item_section, {
                                                                    avatar: "",
                                                                    class: "menu-icon"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        (subitem.img)
                                                                            ? (_openBlock(), _createElementBlock("img", {
                                                                                key: 0,
                                                                                src: require('@/assets/menu/' + subitem.img)
                                                                            }, null, 8, _hoisted_4))
                                                                            : (_openBlock(), _createBlock(_component_q_icon, {
                                                                                key: 1,
                                                                                color: "white",
                                                                                name: "mdi-menu-right"
                                                                            }))
                                                                    ]),
                                                                    _: 2
                                                                }, 1024),
                                                                _createVNode(_component_q_item_section, null, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_item_label, null, {
                                                                            default: _withCtx(() => [
                                                                                _createTextVNode(_toDisplayString(_ctx.$t(subitem.label)), 1)
                                                                            ]),
                                                                            _: 2
                                                                        }, 1024)
                                                                    ]),
                                                                    _: 2
                                                                }, 1024)
                                                            ]),
                                                            _: 2
                                                        }, 1032, ["tag", "to", "active"])), [
                                                            [_directive_permission, { permissions: subitem.permitted }]
                                                        ]);
                                                    }), 128))
                                                ]),
                                                _: 2
                                            }, 1024))
                                            : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                }, 1032, ["tag", "to", "active", "content-inset-level", "default-opened", "expand-icon-class"])), [
                                    [_directive_permission, { permissions: item.permitted }]
                                ])
                                : _createCommentVNode("", true)
                        ], 64));
                    }), 128))
                ]),
                _: 1
            }),
            _createElementVNode("div", _hoisted_5, "v." + _toDisplayString(_ctx.version), 1)
        ]),
        _: 1
    }, 8, ["modelValue", "mini"]));
}
