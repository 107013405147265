import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-984ba76c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "q-pa-lg address-book__contents" };
const _hoisted_2 = { class: "address-book__inner-label" };
const _hoisted_3 = { class: "q-px-lg q-py-sm address-book__contents" };
const _hoisted_4 = { class: "no-border text-left" };
const _hoisted_5 = { class: "row" };
const _hoisted_6 = { class: "text-h6" };
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { class: "text-left" };
const _hoisted_9 = { class: "text-left" };
const _hoisted_10 = { class: "text-left" };
const _hoisted_11 = { key: 1 };
const _hoisted_12 = { class: "text-left" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_markup_table = _resolveComponent("q-markup-table");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_select = _resolveComponent("q-select");
    return (_openBlock(), _createBlock(_component_q_select, {
        modelValue: _ctx.query,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.query) = $event)),
        class: "address-book__select bg-color-secondary",
        "input-class": "placeholder-center placeholder-large",
        outlined: "",
        "stack-label": "",
        "use-input": "",
        loading: _ctx.loading,
        options: _ctx.items,
        "emit-value": "",
        "no-error-icon": "",
        placeholder: _ctx.$t('common.searchCompanyName'),
        "lazy-rules": "",
        onInputValue: _ctx.fetch
    }, _createSlots({
        "before-options": _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("common.addressBook")), 1)
            ])
        ]),
        option: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_card, {
                    flat: "",
                    bordered: ""
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_markup_table, _mergeProps({ class: "sizes-table border-light q-pa-xs cursor-pointer" }, scope.itemProps, {
                            dense: "",
                            flat: ""
                        }), {
                            default: _withCtx(() => [
                                _createElementVNode("thead", null, [
                                    _createElementVNode("tr", null, [
                                        _createElementVNode("th", _hoisted_4, [
                                            _createElementVNode("div", _hoisted_5, [
                                                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getMainName(scope.opt.contact)), 1)
                                            ])
                                        ])
                                    ])
                                ]),
                                _createElementVNode("tbody", null, [
                                    (!!scope.opt.contact.vatId)
                                        ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                                            _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("common.nip")) + ": " + _toDisplayString(scope.opt.contact.vatId), 1)
                                        ]))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("tr", null, [
                                        _createElementVNode("th", _hoisted_9, _toDisplayString(scope.opt.contact.firstName) + " " + _toDisplayString(scope.opt.contact.lastName) + ", " + _toDisplayString(scope.opt.contact.phone), 1)
                                    ]),
                                    _createElementVNode("tr", null, [
                                        _createElementVNode("th", _hoisted_10, _toDisplayString(scope.opt.address.street) + " " + _toDisplayString(scope.opt.address.streetNumber) + _toDisplayString(!!scope.opt.address.localNumber ? "/" + scope.opt.address.localNumber : "") + ", " + _toDisplayString(scope.opt.address.postalCode) + " " + _toDisplayString(scope.opt.address.city), 1)
                                    ]),
                                    (!!scope.opt.address.additionalNotes)
                                        ? (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                                            _createElementVNode("th", _hoisted_12, [
                                                _createVNode(_component_q_separator, { class: "q-my-sm" }),
                                                _createTextVNode(" " + _toDisplayString(scope.opt.address.additionalNotes), 1)
                                            ])
                                        ]))
                                        : _createCommentVNode("", true)
                                ])
                            ]),
                            _: 2
                        }, 1040)
                    ]),
                    _: 2
                }, 1024)
            ])
        ]),
        "no-option": _withCtx(() => [
            _createVNode(_component_q_item, null, {
                default: _withCtx(() => [
                    _createVNode(_component_q_item_section, { class: "text-grey" }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("common.noResults")), 1)
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            })
        ]),
        "selected-item": _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(scope.opt.contact.companyName), 1)
        ]),
        _: 2
    }, [
        (_ctx.standalone)
            ? {
                name: "prepend",
                fn: _withCtx(() => [
                    _createVNode(_component_q_icon, { name: "search" })
                ])
            }
            : undefined
    ]), 1032, ["modelValue", "loading", "options", "placeholder", "onInputValue"]));
}
