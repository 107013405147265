import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { style: { "white-space": "pre" } };
const _hoisted_2 = { style: { "white-space": "pre" } };
const _hoisted_3 = { style: { "white-space": "pre" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_FilteringPopover = _resolveComponent("FilteringPopover");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_Popover = _resolveComponent("Popover");
    const _component_CompanyPopover = _resolveComponent("CompanyPopover");
    const _component_q_chip = _resolveComponent("q-chip");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_Dialog = _resolveComponent("Dialog");
    return (_openBlock(), _createBlock(_component_Dialog, {
        ref: "dialog",
        "close-on-submit": false,
        title: "dataTable.pickup.actions.add",
        action: _ctx.save,
        clear: _ctx.clear
    }, {
        default: _withCtx(() => [
            _createVNode(_component_DataTable, {
                ref: "dataTable",
                pagination: _ctx.pagination,
                "onUpdate:pagination": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                style: { "width": "1200px" },
                "data-type": _ctx.type,
                loading: _ctx.loading,
                entries: _ctx.entries,
                columns: _ctx.columns,
                filters: _ctx.filters,
                "can-select-fn": _ctx.canSelect,
                "selection-column": "id",
                onSelect: _ctx.onSelect,
                onDeselect: _ctx.onDeselect,
                onDeselectKey: _ctx.onDeselect,
                onFetch: _ctx.fetchEntries
            }, {
                actions: _withCtx((slot) => [
                    _createVNode(_component_q_btn, {
                        "no-caps": "",
                        dense: "",
                        class: "bg-light color-slate q-mr-md q-px-md"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getFilterLabel("add")) + " ", 1),
                            _createVNode(_component_q_icon, {
                                right: "",
                                name: "mdi-plus"
                            }),
                            _createVNode(_component_FilteringPopover, {
                                filters: slot.dirtyFilters,
                                dirty: slot.dirty,
                                onSetFilter: slot.setFilter,
                                onRemoveFilter: slot.removeFilter,
                                onSave: slot.saveFilters,
                                "onUpdate:modelValue": _ctx.onFiltersMenu
                            }, null, 8, ["filters", "dirty", "onSetFilter", "onRemoveFilter", "onSave", "onUpdate:modelValue"])
                        ]),
                        _: 2
                    }, 1024),
                    _createVNode(_component_q_btn, {
                        "no-caps": "",
                        dense: "",
                        class: "bg-light color-slate q-mr-md q-px-md"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                                left: "",
                                name: "mdi-calendar-blank-outline"
                            }),
                            _createTextVNode(" " + _toDisplayString(_ctx.period.label) + " ", 1),
                            _createVNode(_component_q_icon, {
                                right: "",
                                name: "mdi-chevron-down"
                            }),
                            _createVNode(_component_Popover, { position: "bottom" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_Calendar, {
                                        period: _ctx.period,
                                        "onUpdate:period": _cache[0] || (_cache[0] = ($event) => ((_ctx.period) = $event)),
                                        date: _ctx.date,
                                        "onUpdate:date": [
                                            _cache[1] || (_cache[1] = ($event) => ((_ctx.date) = $event)),
                                            ($event) => (slot.fetch())
                                        ]
                                    }, null, 8, ["period", "date", "onUpdate:date"])
                                ]),
                                _: 2
                            }, 1024)
                        ]),
                        _: 2
                    }, 1024)
                ]),
                "body-cell-courier": _withCtx((cell) => [
                    _createTextVNode(_toDisplayString(_ctx.getCourierName(cell.row.courier.courierCode)), 1)
                ]),
                "body-cell-shipper": _withCtx((cell) => [
                    _createVNode(_component_q_chip, {
                        size: "0.9em",
                        clickable: "",
                        class: "chip-blue text-primary text-bold"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getRecipientName(cell.value.contact)) + " ", 1),
                            _createVNode(_component_CompanyPopover, {
                                company: cell.value
                            }, null, 8, ["company"])
                        ]),
                        _: 2
                    }, 1024)
                ]),
                "body-cell-sourceAddress": _withCtx((cell) => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getRecipientAddress(cell.value)), 1)
                ]),
                "body-cell-sourceContact": _withCtx((cell) => [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getRecipientName(cell.value)), 1)
                ]),
                "body-cell-recipient": _withCtx((cell) => [
                    _createVNode(_component_q_chip, {
                        size: "0.9em",
                        clickable: "",
                        class: "chip-blue text-primary text-bold"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getRecipientName(cell.value.contact)) + " ", 1),
                            _createVNode(_component_CompanyPopover, {
                                company: cell.value
                            }, null, 8, ["company"])
                        ]),
                        _: 2
                    }, 1024)
                ]),
                "body-cell-packages": _withCtx((cell) => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getPackagesSummary(cell.value)), 1)
                ]),
                "body-cell-shippingOnSaturday": _withCtx((cell) => [
                    _createElementVNode("div", null, [
                        (cell.value)
                            ? (_openBlock(), _createBlock(_component_q_icon, {
                                key: 0,
                                name: "mdi-check",
                                size: "sm"
                            }))
                            : _createCommentVNode("", true)
                    ])
                ]),
                _: 1
            }, 8, ["pagination", "data-type", "loading", "entries", "columns", "filters", "can-select-fn", "onSelect", "onDeselect", "onDeselectKey", "onFetch"])
        ]),
        _: 1
    }, 8, ["action", "clear"]));
}
