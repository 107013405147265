import "./styles/quasar.scss";
import "@/fonts/roboto-v30-latin-ext_latin-regular.eot";
import "@/fonts/roboto-v30-latin-ext_latin-regular.svg";
import "@/fonts/roboto-v30-latin-ext_latin-regular.ttf";
import "@/fonts/roboto-v30-latin-ext_latin-regular.woff";
import "@/fonts/roboto-v30-latin-ext_latin-regular.woff2";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import Notify from 'quasar/src/plugins/Notify.js';
;
export default {
    config: {
        brand: {
            "bg-lightblue-0": "#F0F4FC",
            "bg-lightblue-1": "#F7F8FD",
            "bg-light": "#FCFCFE",
            "border-light-grey": "#EBEEF5",
        },
        screen: {
            bodyClasses: true,
        },
    },
    plugins: { Notify },
};
