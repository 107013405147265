import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d5ca8924"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "courier-icon__radio" };
const _hoisted_2 = { class: "courier-icon__content" };
const _hoisted_3 = {
    key: 0,
    class: "courier-icon__best-price"
};
const _hoisted_4 = { class: "courier-icon__content__label" };
const _hoisted_5 = ["src"];
const _hoisted_6 = { class: "courier-icon__content__bottom-content" };
const _hoisted_7 = {
    key: 0,
    class: "text-h6 text-bold"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_tooltip = _resolveComponent("q-tooltip");
    const _component_q_radio = _resolveComponent("q-radio");
    const _component_q_card = _resolveComponent("q-card");
    return (_openBlock(), _createBlock(_component_q_card, {
        class: _normalizeClass(["courier-icon q-ma-sm courier-card-shadow", { 'courier-icon-active': _ctx.active, 'courier-icon-disabled': _ctx.disabled }]),
        onClick: _ctx.select
    }, {
        default: _withCtx(() => [
            (_ctx.disabled)
                ? (_openBlock(), _createBlock(_component_q_tooltip, {
                    key: 0,
                    anchor: "center middle",
                    self: "center middle"
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("shipmentValuation.serviceUnavailable")), 1)
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_q_radio, {
                    modelValue: _ctx.selected,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selected) = $event)),
                    disable: _ctx.disabled,
                    color: _ctx.active ? 'white' : 'primary',
                    val: _ctx.valuation.courierCode
                }, null, 8, ["modelValue", "disable", "color", "val"])
            ]),
            _createElementVNode("div", _hoisted_2, [
                (_ctx.isCheapest)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t("shipmentValuation.cheapestOption")), 1))
                    : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
                ]),
                _createElementVNode("div", null, [
                    _createElementVNode("img", {
                        src: `${_ctx.getCourierIconPath}`
                    }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("div", _hoisted_6, [
                    (!_ctx.disabled)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.price), 1))
                        : _createCommentVNode("", true)
                ]),
                (_ctx.appendBottomBadge)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(["courier-icon__furgonetka-badge", { 'furgonetka-badge-disabled': _ctx.disabled }])
                    }, " Furgonetka ", 2))
                    : _createCommentVNode("", true)
            ])
        ]),
        _: 1
    }, 8, ["class", "onClick"]));
}
