import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import { authModule } from "@/store/modules/auth";
import SendPackage from "@/views/send-package/SendPackage.vue";
import Settings from "@/views/settings/Settings.vue";
import ShipmentReport from "@/views/reports/ShipmentReport.vue";
import ValuationReport from "@/views/reports/ValuationReport.vue";
import PickupReport from "@/views/reports/PickupReport.vue";
import Users from "@/views/admin/Users.vue";
import SizeLibrary from "@/views/packages/SizeLibrary.vue";
import { ShipmentStep } from "@/store/modules/shipment/data";
import Reports from "@/views/reports/Reports.vue";
import PickupsAndDeliveries from "@/views/reports/pickups-and-deliveries/PickupsAndDeliveries.vue";
import MyAccount from "@/views/my-account/MyAccount.vue";
import { RoleEnum } from "@/api/client";
import AddressBook from "@/views/data/AddressBook.vue";
const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            requiresAuth: true,
        },
        redirect: {
            path: "/send-package/",
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/send-package/:active",
        name: "sendPackage",
        component: SendPackage,
        meta: {
            requiresAuth: true,
            showFooter: true,
        },
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/my-account",
        name: "myAccount",
        component: MyAccount,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/send-package/",
        redirect: {
            path: `/send-package/${ShipmentStep.Package}`,
        },
        meta: {
            requiresAuth: true,
            showFooter: true,
        },
    },
    {
        path: "/admin/",
        redirect: {
            path: `/admin/users`,
        },
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/admin/users",
        name: "users",
        component: Users,
        meta: {
            requiresAuth: true,
            permissions: [RoleEnum.Administrator, RoleEnum.Manager],
        },
    },
    {
        path: "/reports/",
        component: Reports,
        children: [
            {
                path: "pickups-and-deliveries",
                name: "pickupDeliveryReport",
                component: PickupsAndDeliveries,
                meta: {
                    requiresAuth: true,
                    permissions: [RoleEnum.Administrator, RoleEnum.Manager],
                },
            },
            {
                path: "pickup",
                name: "pickupReport",
                component: PickupReport,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "shipment",
                name: "shipmentReport",
                component: ShipmentReport,
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "valuation",
                name: "valuationReport",
                component: ValuationReport,
                meta: {
                    requiresAuth: true,
                    permissions: [RoleEnum.Administrator, RoleEnum.Manager],
                },
            },
        ],
    },
    {
        path: "/packages/size-library",
        name: "sizeLibrary",
        component: SizeLibrary,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/address-book/",
        name: "addressBook",
        component: AddressBook,
        meta: {
            requiresAuth: true,
        },
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    const isLoggedIn = authModule.state.isLoggedIn;
    if (to.name == "login" && isLoggedIn) {
        let redirect = null;
        if (typeof to.params.redirect === "string")
            redirect = router.resolve(to.params.redirect);
        if (redirect)
            next(redirect);
        else
            next({ name: "home" });
    }
    else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (!isLoggedIn) {
                next({ name: "login", params: { redirect: to.fullPath } });
            }
            else {
                if (to.meta.permissions) {
                    if (authModule.hasPermission(to.meta.permissions)) {
                        next();
                    }
                    else {
                        next({ name: "home" });
                    }
                }
                else {
                    next();
                }
            }
        }
        else {
            next();
        }
    }
});
export default router;
