import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-efdda6d2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "label-wrapper" };
const _hoisted_2 = { class: "label-wrapper__label no-pointer-events" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true),
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
                class: _normalizeClass(["label-wrapper__label__top", _ctx.outerClass]),
                style: _normalizeStyle(_ctx.outerBackgroundStyle)
            }, null, 6),
            _createElementVNode("div", {
                class: _normalizeClass(["label-wrapper__label__bottom", _ctx.innerClass]),
                style: _normalizeStyle(_ctx.innerBackgroundStyle)
            }, null, 6),
            _createElementVNode("label", {
                class: _normalizeClass(["q-mx-sm", _ctx.labelClass])
            }, _toDisplayString(_ctx.label), 3)
        ])
    ]));
}
