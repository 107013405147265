import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row q-py-md" };
const _hoisted_2 = { class: "col col-12" };
const _hoisted_3 = { class: "row q-col-gutter-lg q-mb-md" };
const _hoisted_4 = { class: "col col-3" };
const _hoisted_5 = { class: "col col-3" };
const _hoisted_6 = { class: "col col-3" };
const _hoisted_7 = { class: "col col-3" };
const _hoisted_8 = { class: "row q-col-gutter-lg" };
const _hoisted_9 = { class: "col col-6" };
const _hoisted_10 = { class: "col col-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_SelectionPlate = _resolveComponent("SelectionPlate");
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('packageShape.description'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.description,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.value.description) = $event)),
                            type: "text",
                            class: "input-border",
                            outlined: "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('package.fillDescription'),
                                (val) => _ctx.isCorrectLength(val, 1, 30) || _ctx.$t('package.descriptionLength'),
                            ]
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('package.length'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            "model-value": _ctx.lengthInput,
                            type: "number",
                            class: "input-border",
                            outlined: "",
                            suffix: "cm",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('package.fillLength'),
                                (val) => _ctx.isPositive(val) || _ctx.$t('common.mustBePositive'),
                            ],
                            onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.lengthInput = $event))
                        }, null, 8, ["model-value", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('package.width'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            "model-value": _ctx.widthInput,
                            type: "number",
                            class: "input-border",
                            outlined: "",
                            suffix: "cm",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('package.fillWidth'),
                                (val) => _ctx.isPositive(val) || _ctx.$t('common.mustBePositive'),
                            ],
                            onChange: _cache[2] || (_cache[2] = ($event) => (_ctx.widthInput = $event))
                        }, null, 8, ["model-value", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('package.height'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            "model-value": _ctx.heightInput,
                            type: "number",
                            class: "input-border",
                            outlined: "",
                            suffix: "cm",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('package.fillHeight'),
                                (val) => _ctx.isPositive(val) || _ctx.$t('common.mustBePositive'),
                            ],
                            onChange: _cache[3] || (_cache[3] = ($event) => (_ctx.heightInput = $event))
                        }, null, 8, ["model-value", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('package.weight'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            "model-value": _ctx.weightInput,
                            type: "number",
                            step: "0.1",
                            class: "input-border",
                            outlined: "",
                            suffix: "kg",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('package.fillWeight'),
                                (val) => _ctx.isPositive(val) || _ctx.$t('common.mustBePositive'),
                            ],
                            onChange: _cache[4] || (_cache[4] = ($event) => (_ctx.weightInput = $event))
                        }, null, 8, ["model-value", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_SelectionPlate, {
                    modelValue: _ctx.value.shape,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.value.shape) = $event)),
                    type: "radio",
                    flat: "",
                    label: _ctx.$t('package.standardShape'),
                    "label-secondary": _ctx.$t('package.cardboardCuboidFormat'),
                    val: _ctx.shapeStandard,
                    background: "light",
                    "selected-background": "primary",
                    "selected-color": "white",
                    tooltip: "true",
                    "tooltip-primary": _ctx.$t('package.standardShape'),
                    "tooltip-secondary": _ctx.$t('package.standardShapeTooltip'),
                    "tooltip-images": _ctx.standardShapeImages
                }, null, 8, ["modelValue", "label", "label-secondary", "val", "tooltip-primary", "tooltip-secondary", "tooltip-images"])
            ]),
            _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_SelectionPlate, {
                    modelValue: _ctx.value.shape,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.value.shape) = $event)),
                    type: "radio",
                    flat: "",
                    label: _ctx.$t('package.customFormat'),
                    "label-secondary": _ctx.$t('package.rest'),
                    val: _ctx.shapeCustom,
                    background: "light",
                    "selected-background": "primary",
                    "selected-color": "white",
                    tooltip: "true",
                    "tooltip-primary": _ctx.$t('package.customFormat'),
                    "tooltip-secondary": _ctx.$t('package.customFormatTooltip'),
                    "tooltip-images": _ctx.customShapeImages
                }, null, 8, ["modelValue", "label", "label-secondary", "val", "tooltip-primary", "tooltip-secondary", "tooltip-images"])
            ])
        ])
    ]));
}
