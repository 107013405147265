import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "column" };
const _hoisted_2 = { class: "row q-mb-md" };
const _hoisted_3 = {
    key: 0,
    class: "row q-mb-md flex items-baseline"
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = ["innerHTML"];
const _hoisted_6 = { class: "row q-mb-md full-width" };
const _hoisted_7 = { class: "q-mr-md" };
const _hoisted_8 = {
    key: 0,
    class: "q-px-md q-py-sm full-width q-mb-sm"
};
const _hoisted_9 = { class: "q-px-md q-py-sm flex row full-width q-mb-sm" };
const _hoisted_10 = { class: "row items-center" };
const _hoisted_11 = { class: "text-bold text-primary" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_input = _resolveComponent("q-input");
    const _component_q_space = _resolveComponent("q-space");
    const _component_q_chip = _resolveComponent("q-chip");
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_th = _resolveComponent("q-th");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_tr = _resolveComponent("q-tr");
    const _component_q_td = _resolveComponent("q-td");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_pagination = _resolveComponent("q-pagination");
    const _component_q_table = _resolveComponent("q-table");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "actionbar", {
            filters: _ctx.dataFilters,
            search: _ctx.searchValue
        }, () => [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_input, {
                    modelValue: _ctx.searchValue,
                    "onUpdate:modelValue": [
                        _cache[0] || (_cache[0] = ($event) => ((_ctx.searchValue) = $event)),
                        _cache[1] || (_cache[1] = ($event) => (_ctx.debounceFetch()))
                    ],
                    outlined: "",
                    dense: "",
                    class: "bg-light q-mr-md",
                    style: { "min-width": "25em" },
                    placeholder: _ctx.getLabelLocale('search'),
                    clearable: "",
                    onChange: _cache[2] || (_cache[2] = ($event) => (_ctx.fetchData())),
                    onClear: _cache[3] || (_cache[3] = ($event) => (_ctx.fetchData()))
                }, {
                    prepend: _withCtx(() => [
                        _createVNode(_component_q_icon, {
                            left: "",
                            name: "mdi-magnify"
                        })
                    ]),
                    _: 1
                }, 8, ["modelValue", "placeholder"]),
                _createVNode(_component_q_space),
                _renderSlot(_ctx.$slots, "actions", {
                    filters: _ctx.dataFilters,
                    dirty: _ctx.dirty,
                    dirtyFilters: _ctx.dirtyFilters,
                    debounce: _ctx.debounceFetch,
                    fetch: _ctx.fetchData,
                    setFilter: _ctx.setFilter,
                    removeFilter: _ctx.removeFilter,
                    clearFilters: _ctx.clearFilters,
                    saveFilters: _ctx.saveFilters,
                    resetFilters: _ctx.resetDirtyFilters
                })
            ])
        ]),
        (_ctx.dataFilters.getActiveFilters().length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataFilters.getActiveFilters(), (filter) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filter.values, (value, index) => {
                            return (_openBlock(), _createBlock(_component_q_chip, {
                                key: index,
                                class: "q-pl-sm",
                                color: "primary",
                                "text-color": "white",
                                square: "",
                                removable: "",
                                onRemove: ($event) => (_ctx.removeFilter(value.key, value.id, false))
                            }, {
                                default: _withCtx(() => [
                                    (filter.label)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(filter.label) + " : ", 1))
                                        : _createCommentVNode("", true),
                                    _createElementVNode("span", {
                                        innerHTML: value.label ?? value.key
                                    }, null, 8, _hoisted_5)
                                ]),
                                _: 2
                            }, 1032, ["onRemove"]));
                        }), 128))
                    ], 64));
                }), 256)),
                _createElementVNode("a", {
                    class: "text-bold text-primary",
                    href: "#",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event) => (_ctx.clearFilters(false)), ["prevent"]))
                }, _toDisplayString(_ctx.$t("reports.filters.clear")), 1)
            ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_q_table, {
                pagination: _ctx.dataPagination,
                "onUpdate:pagination": _cache[8] || (_cache[8] = ($event) => ((_ctx.dataPagination) = $event)),
                class: "full-width",
                "table-header-class": "text-bold text-primary",
                columns: _ctx.displayColumns,
                rows: _ctx.entries,
                "no-data-label": _ctx.$t('common.noResults'),
                "no-results-label": _ctx.$t('common.noResults'),
                "loading-label": "",
                loading: _ctx.loading,
                "row-key": "name",
                "binary-state-sort": "",
                onRequest: _ctx.fetchData
            }, {
                header: _withCtx((props) => [
                    _createVNode(_component_q_tr, { props: props }, {
                        default: _withCtx(() => [
                            (!_ctx.isSelectable || !_ctx.hasSelected())
                                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(props.cols, (col) => {
                                    return (_openBlock(), _createBlock(_component_q_th, {
                                        key: col.name,
                                        props: props,
                                        class: "text-bold text-primary"
                                    }, {
                                        default: _withCtx(() => [
                                            (col.name === 'select')
                                                ? (_openBlock(), _createBlock(_component_q_checkbox, {
                                                    key: 0,
                                                    "model-value": _ctx.checkboxValue(),
                                                    onClick: _cache[5] || (_cache[5] = ($event) => (_ctx.toggleAll()))
                                                }, null, 8, ["model-value"]))
                                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                    _createTextVNode(_toDisplayString(col.label), 1)
                                                ], 64))
                                        ]),
                                        _: 2
                                    }, 1032, ["props"]));
                                }), 128))
                                : (_openBlock(), _createBlock(_component_q_th, {
                                    key: "select",
                                    props: props,
                                    class: "text-bold text-primary",
                                    colspan: props.cols.length
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_checkbox, {
                                            "model-value": _ctx.checkboxValue(),
                                            onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.toggleAll()))
                                        }, null, 8, ["model-value"]),
                                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("common.selectionCount")) + ": " + _toDisplayString(_ctx.selection.length), 1),
                                        (!!_ctx.selectionColumn)
                                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                                key: 0,
                                                flat: "",
                                                "no-caps": "",
                                                onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.deselectAll()))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_q_icon, {
                                                        class: "q-mr-md",
                                                        name: "mdi-delete-sweep",
                                                        color: "primary"
                                                    }),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("common.deselectAll")), 1)
                                                ]),
                                                _: 1
                                            }))
                                            : _createCommentVNode("", true),
                                        _renderSlot(_ctx.$slots, "group-actions", { selection: _ctx.selection })
                                    ]),
                                    _: 2
                                }, 1032, ["props", "colspan"]))
                        ]),
                        _: 2
                    }, 1032, ["props"])
                ]),
                "body-cell": _withCtx((scope) => [
                    _createVNode(_component_q_td, {
                        class: _normalizeClass(_ctx.getCellClass(scope)),
                        props: scope
                    }, {
                        default: _withCtx(() => [
                            (_ctx.hasSlot(_ctx.getCellSlot(scope)))
                                ? _renderSlot(_ctx.$slots, _ctx.getCellSlot(scope), _normalizeProps(_mergeProps({ key: 0 }, scope)))
                                : (_ctx.hasSlot('body-cell'))
                                    ? _renderSlot(_ctx.$slots, "body-cell", _normalizeProps(_mergeProps({ key: 1 }, scope)))
                                    : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                        _createTextVNode(_toDisplayString(scope.value), 1)
                                    ], 64))
                        ]),
                        _: 2
                    }, 1032, ["class", "props"])
                ]),
                "body-cell-select": _withCtx((scope) => [
                    _createVNode(_component_q_td, {
                        class: _normalizeClass(_ctx.getCellClass(scope)),
                        props: scope
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_checkbox, {
                                "model-value": _ctx.isSelected(scope.row),
                                class: _normalizeClass(_ctx.isSelected(scope.row) ? 'q-checkbox--invert' : ''),
                                disable: !_ctx.canSelect(scope.row),
                                "keep-color": "",
                                onClick: ($event) => (_ctx.toggle(scope.row))
                            }, null, 8, ["model-value", "class", "disable", "onClick"])
                        ]),
                        _: 2
                    }, 1032, ["class", "props"])
                ]),
                bottom: _withCtx(() => [
                    (_ctx.hasSlot('summary'))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createVNode(_component_q_card, {
                                flat: "",
                                bordered: "",
                                class: "full-width"
                            }, {
                                default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "summary")
                                ]),
                                _: 3
                            })
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_q_space),
                        _renderSlot(_ctx.$slots, "pagination", {}, () => [
                            _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", null, [
                                    _createTextVNode(_toDisplayString(_ctx.rowStart) + "-" + _toDisplayString(_ctx.rowEnd) + " " + _toDisplayString(_ctx.$t("common.from")) + " ", 1),
                                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.labelRows), 1)
                                ]),
                                _createVNode(_component_q_pagination, {
                                    "model-value": _ctx.dataPagination.page,
                                    max: _ctx.lastPage,
                                    "boundary-numbers": false,
                                    "boundary-links": "",
                                    "direction-links": "",
                                    "max-pages": 4,
                                    disable: _ctx.loading,
                                    "onUpdate:modelValue": _ctx.setPage
                                }, null, 8, ["model-value", "max", "disable", "onUpdate:modelValue"])
                            ])
                        ])
                    ])
                ]),
                _: 3
            }, 8, ["pagination", "columns", "rows", "no-data-label", "no-results-label", "loading", "onRequest"])
        ])
    ]));
}
