import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7de1af4b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row content__main" };
const _hoisted_2 = { class: "box-padding q-pa-lg" };
const _hoisted_3 = { class: "row q-col-gutter-lg" };
const _hoisted_4 = { class: "col col-12 col-sm-12 col-md-6" };
const _hoisted_5 = { class: "row q-col-gutter-lg" };
const _hoisted_6 = { class: "col col-12" };
const _hoisted_7 = { class: "col col-12" };
const _hoisted_8 = { class: "col col-12 col-sm-12 col-md-6" };
const _hoisted_9 = { class: "row q-col-gutter-lg" };
const _hoisted_10 = { class: "col col-12" };
const _hoisted_11 = { class: "col col-12" };
const _hoisted_12 = { class: "row info-flex" };
const _hoisted_13 = { class: "q-pr-md q-pl-md text-justify text-primary right-data-warning" };
const _hoisted_14 = { class: "q-mr-lg summary-text text-bold" };
const _hoisted_15 = ["src"];
const _hoisted_16 = {
    key: 0,
    class: "q-mr-lg text-bold"
};
const _hoisted_17 = { class: "q-mr-lg text-bold" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SummaryDestination = _resolveComponent("SummaryDestination");
    const _component_SummaryPackage = _resolveComponent("SummaryPackage");
    const _component_SummaryServices = _resolveComponent("SummaryServices");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_card = _resolveComponent("q-card");
    const _component_SuccessfulShipment = _resolveComponent("SuccessfulShipment");
    const _component_q_dialog = _resolveComponent("q-dialog");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_q_card, { class: "summary-card" }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$q.screen.lg.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                        }, [
                            _createElementVNode("div", _hoisted_2, [
                                _createElementVNode("div", _hoisted_3, [
                                    _createElementVNode("div", _hoisted_4, [
                                        _createElementVNode("div", _hoisted_5, [
                                            _createElementVNode("div", _hoisted_6, [
                                                _createVNode(_component_SummaryDestination, {
                                                    title: "summary.shipperTitle",
                                                    value: { contact: _ctx.request.shipperContact, address: _ctx.request.shipperAddress }
                                                }, null, 8, ["value"])
                                            ]),
                                            _createElementVNode("div", _hoisted_7, [
                                                _createVNode(_component_SummaryPackage, {
                                                    value: _ctx.request.packages
                                                }, null, 8, ["value"])
                                            ])
                                        ])
                                    ]),
                                    _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("div", _hoisted_9, [
                                            _createElementVNode("div", _hoisted_10, [
                                                _createVNode(_component_SummaryDestination, {
                                                    title: "summary.recipientTitle",
                                                    value: { contact: _ctx.request.recipientContact, address: _ctx.request.recipientAddress }
                                                }, null, 8, ["value"])
                                            ]),
                                            _createElementVNode("div", _hoisted_11, [
                                                _createVNode(_component_SummaryServices)
                                            ])
                                        ])
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_12, [
                                    _createElementVNode("div", null, [
                                        _createVNode(_component_q_icon, {
                                            name: "mdi-information-outline",
                                            size: "md"
                                        })
                                    ]),
                                    _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("contactDataForm.owuAcceptanceWarning")), 1)
                                ])
                            ])
                        ], 2)
                    ], 2)
                ])
            ]),
            _: 1
        }),
        (_openBlock(), _createBlock(_Teleport, { to: "#summary-text" }, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("summary.footer")) + ":", 1),
            _createElementVNode("img", {
                class: "q-mr-lg",
                src: _ctx.courierIcon
            }, null, 8, _hoisted_15),
            (_ctx.isFurgonetka)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("shipmentValuation.via")) + " Furgonetka", 1))
                : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.cost) + " " + _toDisplayString(_ctx.currency), 1)
        ])),
        _createVNode(_component_q_dialog, {
            modelValue: _ctx.showDialog,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.showDialog) = $event)),
            persistent: ""
        }, {
            default: _withCtx(() => [
                _createVNode(_component_SuccessfulShipment)
            ]),
            _: 1
        }, 8, ["modelValue"])
    ], 64));
}
