var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Module, Mutation, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { AreaEnum, ReportsClient, ShipmentOptionFilter, ShipmentMethodFilterEnum, } from "@/api/client";
import extend from 'quasar/src/utils/extend.js';
;
import moment from "moment";
import { authModule } from "../auth";
const defaultState = () => {
    return {
        selectedCourier: null,
        reports: {
            rangePunctuality: [],
        },
        reportFilters: {
            from: moment().format(moment.HTML5_FMT.DATE),
            to: moment().format(moment.HTML5_FMT.DATE),
            selectedArea: AreaEnum.Countries,
            shipmentMethodFilter: ShipmentMethodFilterEnum.DomesticStandard,
            shipmentOptionFilter: ShipmentOptionFilter.Deliveries,
        },
        averageDeliveryTimes: {
            averageDeliveryTimes: [],
            average: 0,
        },
    };
};
let ReportsModule = class ReportsModule extends VuexModule {
    _client = new ReportsClient();
    state = extend(true, {}, defaultState());
    get isDomesticSelected() {
        return [
            ShipmentMethodFilterEnum.DomesticStandard,
            ShipmentMethodFilterEnum.DomesticExpressMorning,
            ShipmentMethodFilterEnum.DomesticExpressMidDay,
        ].includes(this.state.reportFilters.shipmentMethodFilter);
    }
    clearStore() {
        this.state = extend(true, {}, defaultState());
    }
    async fetchShipmentDeliveryReport() {
        try {
            this.state.reports = await this._client.getShipmentDeliveryReport(this.state.reportFilters);
        }
        catch (error) {
            //
        }
    }
    async fetchLocationPunctuality() {
        try {
            const request = {
                area: this.state.reportFilters.selectedArea,
                from: this.state.reportFilters.from,
                to: this.state.reportFilters.to,
                shipmentOptionFilter: this.state.reportFilters.shipmentOptionFilter,
                shipmentMethodFilter: this.state.reportFilters.shipmentMethodFilter,
            };
            const data = await this._client.getLocationsPunctuality(request);
            this.state.locationPunctuality = data.locationPunctualities;
        }
        catch (error) {
            //
        }
    }
    async fetchAverageDeliveryTimes() {
        try {
            const data = await this._client.getAverageDeliveryTime({
                selectedArea: this.state.reportFilters.selectedArea,
                countryCode: this.state.reportFilters.countryCode,
                from: this.state.reportFilters.from,
                to: this.state.reportFilters.to,
                shipmentOptionFilter: this.state.reportFilters.shipmentOptionFilter,
                shipmentMethodFilter: this.state.reportFilters.shipmentMethodFilter,
            });
            this.state.averageDeliveryTimes = data;
        }
        catch (error) {
            //
        }
    }
};
__decorate([
    Mutation
], ReportsModule.prototype, "clearStore", null);
ReportsModule = __decorate([
    Module
], ReportsModule);
export const reportsModule = new ReportsModule({ store, name: "reports" });
reportsModule.$watch((m) => m.state.reportFilters, async () => {
    if (!authModule.state.isLoggedIn)
        return;
    await reportsModule.fetchShipmentDeliveryReport();
    await reportsModule.fetchLocationPunctuality();
    await reportsModule.fetchAverageDeliveryTimes();
}, { deep: true });
