import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ce577f8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row items-lg-center q-col-gutter-sm text-small full-width" };
const _hoisted_2 = { class: "col-3 text-bold" };
const _hoisted_3 = { class: "col-7" };
const _hoisted_4 = { class: "col-2" };
const _hoisted_5 = { class: "row items-lg-center q-col-gutter-sm text-small full-width" };
const _hoisted_6 = { class: "col-3 text-bold" };
const _hoisted_7 = { class: "col-7" };
const _hoisted_8 = { class: "col-2" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_linear_progress = _resolveComponent("q-linear-progress");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_list = _resolveComponent("q-list");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_q_list, {
            dense: "",
            class: _normalizeClass(_ctx.shouldScroll ? 'scroll' : '')
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                        key: index,
                        clickable: "",
                        dense: "",
                        class: _normalizeClass(["punctual-delivery", _ctx.isSelected(item) ? 'selected text-white' : 'text-primary']),
                        onClick: ($event) => (_ctx.select(item))
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                        _createElementVNode("div", _hoisted_2, _toDisplayString(item.courierCode ? _ctx.$t("courier." + item.courierCode.toLowerCase()) : _ctx.$t("common.average")), 1),
                                        _createElementVNode("div", _hoisted_3, [
                                            _createVNode(_component_q_linear_progress, {
                                                class: "punctual-delivery__progress",
                                                size: "15px",
                                                value: item.procentage / 100,
                                                color: !item.courierCode ? 'primary' : _ctx.isSelected(item) ? 'white' : 'indigo-12',
                                                dark: _ctx.isSelected(item)
                                            }, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(["absolute-full flex flex-center text-small", _ctx.isSelected(item) ? 'text-primary' : 'text-white'])
                                                    }, _toDisplayString(_ctx.integerFormat(item.procentage)) + "% ", 3)
                                                ]),
                                                _: 2
                                            }, 1032, ["value", "color", "dark"])
                                        ]),
                                        _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("span", null, _toDisplayString(_ctx.integerFormat(item.total)), 1)
                                        ])
                                    ])
                                ]),
                                _: 2
                            }, 1024)
                        ]),
                        _: 2
                    }, 1032, ["class", "onClick"]));
                }), 128))
            ]),
            _: 1
        }, 8, ["class"]),
        _createVNode(_component_q_item, {
            clickable: "",
            dense: "",
            class: _normalizeClass(["punctual-delivery", _ctx.isSelected(_ctx.total) ? 'selected text-white' : 'text-primary']),
            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.select(_ctx.total)))
        }, {
            default: _withCtx(() => [
                _createVNode(_component_q_item_section, null, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.total.courierCode ? _ctx.$t("courier." + _ctx.total.courierCode.toLowerCase()) : _ctx.$t("common.average")), 1),
                            _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_q_linear_progress, {
                                    class: "punctual-delivery__progress",
                                    size: "15px",
                                    value: _ctx.total.procentage / 100,
                                    color: !_ctx.total.courierCode ? 'primary' : _ctx.isSelected(_ctx.total) ? 'white' : 'indigo-12',
                                    dark: _ctx.isSelected(_ctx.total)
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", {
                                            class: _normalizeClass(["absolute-full flex flex-center text-small", _ctx.isSelected(_ctx.total) ? 'text-primary' : 'text-white'])
                                        }, _toDisplayString(_ctx.integerFormat(_ctx.total.procentage)) + "% ", 3)
                                    ]),
                                    _: 1
                                }, 8, ["value", "color", "dark"])
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.integerFormat(_ctx.total.total)), 1)
                            ])
                        ])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["class"])
    ], 64));
}
