import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "row bg-color-secondary" };
const _hoisted_2 = { class: "box-padding" };
const _hoisted_3 = { class: "q-pa-md" };
const _hoisted_4 = { class: "row items-stretch" };
const _hoisted_5 = { class: "col-grow col-5 q-pa-xs" };
const _hoisted_6 = { class: "col-auto q-px-lg q-pb-lg" };
const _hoisted_7 = { class: "col-grow col-5 q-pa-xs" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RequestContactComponent = _resolveComponent("RequestContactComponent");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_RequestAddressComponent = _resolveComponent("RequestAddressComponent");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_card = _resolveComponent("q-card");
    const _component_ButtonDialog = _resolveComponent("ButtonDialog");
    return (_openBlock(), _createBlock(_component_ButtonDialog, {
        ref: "orgDialog",
        label: "common.setup",
        title: "settings.organizationDetails",
        action: _ctx.update,
        clear: _ctx.clear
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, { style: { "width": "1200px" } }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$q.screen.lt.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                            }, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_q_form, { ref: "form" }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_3, [
                                                _createElementVNode("div", _hoisted_4, [
                                                    _createElementVNode("div", _hoisted_5, [
                                                        _createVNode(_component_RequestContactComponent, {
                                                            "hide-user-data": true,
                                                            value: _ctx.requestContact,
                                                            hasCostCenter: true
                                                        }, null, 8, ["value"])
                                                    ]),
                                                    _createElementVNode("div", _hoisted_6, [
                                                        _createVNode(_component_q_separator, {
                                                            vertical: "",
                                                            inset: "",
                                                            style: { "height": "100%" }
                                                        })
                                                    ]),
                                                    _createElementVNode("div", _hoisted_7, [
                                                        _createVNode(_component_RequestAddressComponent, { value: _ctx.requestAddress }, null, 8, ["value"])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                        _: 1
                                    }, 512)
                                ])
                            ], 2)
                        ], 2)
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["action", "clear"]));
}
