import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "column q-pa-md bg-light" };
const _hoisted_2 = {
    key: 0,
    class: "row q-mb-sm"
};
const _hoisted_3 = { class: "row q-py-md" };
const _hoisted_4 = { class: "row q-mb-lg" };
const _hoisted_5 = { class: "row q-mb-lg" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_card = _resolveComponent("q-card");
    const _component_ButtonDialog = _resolveComponent("ButtonDialog");
    return (_openBlock(), _createBlock(_component_ButtonDialog, {
        ref: "orgDialog",
        label: "common.setup",
        title: "userSettings.changeContact",
        action: _ctx.update,
        clear: _ctx.clear,
        "close-on-submit": false,
        onFormClosed: _ctx.formClosed
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, { style: { "width": "600px" } }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_form, { ref: "form" }, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                                (_ctx.getModelError('summary'))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createElementVNode("ul", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getModelError('summary'), (err, ix) => {
                                                return (_openBlock(), _createElementBlock("li", {
                                                    key: ix,
                                                    class: "text-danger"
                                                }, _toDisplayString(err), 1));
                                            }), 128))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('email'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: _ctx.user.email,
                                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.user.email) = $event)),
                                                disable: "",
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('email') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('emailRequired'),
                                                    (v) => _ctx.isValidEmail(v) || _ctx.getValidationLabel('incorrectEmail'),
                                                ],
                                                onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.clearModelError('email')))
                                            }, null, 8, ["modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('firstName'),
                                        class: "col q-mr-sm",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: _ctx.user.firstName,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.user.firstName) = $event)),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('firstName') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('firstNameRequired'),
                                                ],
                                                onChange: _cache[3] || (_cache[3] = ($event) => (_ctx.clearModelError('firstName')))
                                            }, null, 8, ["modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"]),
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('lastName'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: _ctx.user.lastName,
                                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.user.lastName) = $event)),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('lastName') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('lastNameRequired'),
                                                ],
                                                onChange: _cache[5] || (_cache[5] = ($event) => (_ctx.clearModelError('lastName')))
                                            }, null, 8, ["modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('phoneNumber'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: _ctx.user.phoneNumber,
                                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.user.phoneNumber) = $event)),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('phoneNumber') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('phoneNumberRequired'),
                                                ],
                                                onChange: _cache[7] || (_cache[7] = ($event) => (_ctx.clearModelError('phoneNumber')))
                                            }, null, 8, ["modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 1
                                    }, 8, ["label"])
                                ])
                            ])
                        ]),
                        _: 1
                    }, 512)
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["action", "clear", "onFormClosed"]));
}
