import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "column" };
const _hoisted_2 = {
    key: 0,
    class: "row q-mb-sm"
};
const _hoisted_3 = { class: "row items-stretch" };
const _hoisted_4 = { class: "col-grow col-5 q-pa-xs" };
const _hoisted_5 = { class: "col-auto q-px-lg q-pb-lg" };
const _hoisted_6 = { class: "col-grow col-5 q-pa-xs" };
const _hoisted_7 = { class: "q-px-sm" };
const _hoisted_8 = { class: "row" };
const _hoisted_9 = { class: "col col-12 border-light info-flex q-px-md q-py-sm" };
const _hoisted_10 = { class: "q-pr-md q-pl-md text-sm text-justify" };
const _hoisted_11 = { style: { "white-space": "pre" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dialog = _resolveComponent("Dialog");
    const _component_RequestContactComponent = _resolveComponent("RequestContactComponent");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_RequestAddressComponent = _resolveComponent("RequestAddressComponent");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_EditorDialog = _resolveComponent("EditorDialog");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_menu = _resolveComponent("q-menu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_MainComponent = _resolveComponent("MainComponent");
    const _component_q_page = _resolveComponent("q-page");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_openBlock(), _createBlock(_component_q_page, null, {
        default: _withCtx(() => [
            _createVNode(_component_MainComponent, null, {
                default: _withCtx(() => [
                    _createVNode(_component_Dialog, {
                        ref: "deleteMultipleDialog",
                        title: "dataTable.addressBook.actions.deleteMultipleTitle",
                        action: _ctx.deleteMultipleConfirm,
                        "submit-label": "common.confirm"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("dataTable.addressBook.actions.deleteMultipleMessage")), 1)
                        ]),
                        _: 1
                    }, 8, ["action"]),
                    _createVNode(_component_EditorDialog, {
                        modelValue: _ctx.entry,
                        "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event) => ((_ctx.entry) = $event)),
                            _ctx.processEntry
                        ],
                        open: _ctx.editorOpen,
                        "onUpdate:open": _cache[1] || (_cache[1] = ($event) => ((_ctx.editorOpen) = $event)),
                        mode: _ctx.editorMode,
                        "dialog-class": "editor-dialog",
                        "label-entry": _ctx.entryLabel,
                        "label-entry-type": _ctx.$t('dataTable.addressBook.editorEntryType'),
                        "label-new": _ctx.$t('dataTable.addressBook.editorNew'),
                        "model-builder": _ctx.makeEntry,
                        "has-model-errors": _ctx.hasModelErrors,
                        processing: _ctx.processing
                    }, {
                        editor: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_1, [
                                (_ctx.getModelError('summary'))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createElementVNode("ul", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getModelError('summary'), (err, ix) => {
                                                return (_openBlock(), _createElementBlock("li", {
                                                    key: ix,
                                                    class: "text-danger"
                                                }, _toDisplayString(err), 1));
                                            }), 128))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_3, [
                                    _createElementVNode("div", _hoisted_4, [
                                        _createVNode(_component_RequestContactComponent, {
                                            ref: "contact",
                                            value: scope.value.contact,
                                            shorthand: ""
                                        }, null, 8, ["value"])
                                    ]),
                                    _createElementVNode("div", _hoisted_5, [
                                        _createVNode(_component_q_separator, {
                                            vertical: "",
                                            inset: "",
                                            style: { "height": "100%" }
                                        })
                                    ]),
                                    _createElementVNode("div", _hoisted_6, [
                                        _createVNode(_component_RequestAddressComponent, {
                                            value: scope.value.address
                                        }, null, 8, ["value"])
                                    ])
                                ]),
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", _hoisted_8, [
                                        _createElementVNode("div", _hoisted_9, [
                                            _createElementVNode("span", null, [
                                                _createVNode(_component_q_icon, {
                                                    name: "mdi-information-outline",
                                                    size: "sm"
                                                })
                                            ]),
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("contactDataForm.fillRightDataWarning")), 1)
                                        ])
                                    ])
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["modelValue", "open", "mode", "label-entry", "label-entry-type", "label-new", "model-builder", "has-model-errors", "processing", "onUpdate:modelValue"]),
                    _createVNode(_component_DataTable, {
                        ref: "dataTable",
                        pagination: _ctx.pagination,
                        "onUpdate:pagination": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                        "data-type": "addressBook",
                        "editor-class": "editor-dialog",
                        loading: _ctx.loading,
                        "has-model-errors": _ctx.hasModelErrors,
                        entries: _ctx.entries,
                        columns: _ctx.columns,
                        filters: _ctx.filters,
                        "model-builder": _ctx.makeEntry,
                        "selection-column": "id",
                        onFetch: _ctx.fetchEntries,
                        onCreate: _ctx.createEntry
                    }, {
                        actions: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                                class: "bg-accent text-white",
                                onClick: _ctx.addNewEntry
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        class: "q-mr-md",
                                        name: "mdi-plus-circle",
                                        color: "white"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.addressBook.actions.add")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ]),
                        "group-actions": _withCtx((props) => [
                            _createVNode(_component_q_btn, {
                                flat: "",
                                "no-caps": "",
                                onClick: ($event) => (_ctx.deleteMultiple(props.selection))
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        class: "q-mr-md",
                                        name: "mdi-delete-sweep",
                                        color: "primary"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.addressBook.actions.deleteMultiple")), 1)
                                ]),
                                _: 2
                            }, 1032, ["onClick"])
                        ]),
                        "body-cell": _withCtx((cell) => [
                            _createElementVNode("div", _hoisted_11, _toDisplayString(cell.value), 1)
                        ]),
                        "body-cell-actions": _withCtx((cell) => [
                            _createVNode(_component_q_btn, {
                                flat: "",
                                round: ""
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, { name: "mdi-dots-vertical" }),
                                    _createVNode(_component_q_menu, { style: { "min-width": "10em" } }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_list, null, {
                                                default: _withCtx(() => [
                                                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                        clickable: "",
                                                        onClick: ($event) => (_ctx.editEntry(cell.row.entry))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_q_item_section, null, {
                                                                default: _withCtx(() => [
                                                                    _createElementVNode("div", null, [
                                                                        _createVNode(_component_q_icon, {
                                                                            name: "mdi-pencil",
                                                                            size: "xs",
                                                                            class: "q-mr-sm"
                                                                        }),
                                                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("dataTable.addressBook.actions.edit")), 1)
                                                                    ])
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["onClick"])), [
                                                        [_directive_close_popup]
                                                    ]),
                                                    _createVNode(_component_q_separator),
                                                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                        clickable: "",
                                                        onClick: ($event) => (_ctx.copyEntry(cell.row.entry))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_q_item_section, null, {
                                                                default: _withCtx(() => [
                                                                    _createElementVNode("div", null, [
                                                                        _createVNode(_component_q_icon, {
                                                                            name: "mdi-plus-box-multiple",
                                                                            size: "xs",
                                                                            class: "q-mr-sm"
                                                                        }),
                                                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("dataTable.addressBook.actions.copy")), 1)
                                                                    ])
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["onClick"])), [
                                                        [_directive_close_popup]
                                                    ]),
                                                    _createVNode(_component_q_separator),
                                                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                        clickable: "",
                                                        onClick: ($event) => (_ctx.deleteEntry(cell.row.entry))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_q_item_section, null, {
                                                                default: _withCtx(() => [
                                                                    _createElementVNode("div", null, [
                                                                        _createVNode(_component_q_icon, {
                                                                            name: "mdi-delete-sweep",
                                                                            size: "xs",
                                                                            class: "q-mr-sm"
                                                                        }),
                                                                        _createElementVNode("span", null, _toDisplayString(_ctx.$t("dataTable.addressBook.actions.delete")), 1)
                                                                    ])
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["onClick"])), [
                                                        [_directive_close_popup]
                                                    ])
                                                ]),
                                                _: 2
                                            }, 1024)
                                        ]),
                                        _: 2
                                    }, 1024)
                                ]),
                                _: 2
                            }, 1024)
                        ]),
                        _: 1
                    }, 8, ["pagination", "loading", "has-model-errors", "entries", "columns", "filters", "model-builder", "onFetch", "onCreate"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
