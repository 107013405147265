import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f81080a0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "orders full-height"
};
const _hoisted_2 = { class: "row q-mt-sm justify-between" };
const _hoisted_3 = { class: "col col-5 chart-flex" };
const _hoisted_4 = { class: "text-primary text-bold" };
const _hoisted_5 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DoughnutChart = _resolveComponent("DoughnutChart");
    const _component_q_separator = _resolveComponent("q-separator");
    return (_ctx.shipments && _ctx.shipments.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
                _createVNode(_component_DoughnutChart, {
                    class: "d-chart",
                    "chart-data": _ctx.chartData,
                    options: _ctx.chartOptions,
                    width: 300,
                    height: 200
                }, null, 8, ["chart-data", "options"])
            ]),
            _createVNode(_component_q_separator),
            _createElementVNode("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipments, (item, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("courier." + item.courierCode.toLowerCase())), 1),
                            _createElementVNode("div", {
                                class: "text-bold",
                                style: _normalizeStyle(`color:${_ctx.colors[index]}`)
                            }, _toDisplayString(item.total), 5)
                        ]),
                        (index % 2 === 0 && index !== _ctx.shipments.length - 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createVNode(_component_q_separator, {
                                    class: "col-separator",
                                    vertical: ""
                                })
                            ]))
                            : _createCommentVNode("", true)
                    ], 64));
                }), 128))
            ])
        ]))
        : _createCommentVNode("", true);
}
