import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "column" };
const _hoisted_2 = {
    key: 0,
    class: "row q-mb-sm"
};
const _hoisted_3 = { class: "row q-py-md" };
const _hoisted_4 = { class: "row q-mb-lg" };
const _hoisted_5 = { class: "row q-mb-lg" };
const _hoisted_6 = {
    key: 1,
    class: "row q-mb-lg"
};
const _hoisted_7 = { class: "row q-mb-lg" };
const _hoisted_8 = { class: "row q-mb-lg" };
const _hoisted_9 = { class: "row q-mb-lg" };
const _hoisted_10 = { key: 0 };
const _hoisted_11 = { style: { "white-space": "pre" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_select = _resolveComponent("q-select");
    const _component_EditorDialog = _resolveComponent("EditorDialog");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_menu = _resolveComponent("q-menu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_MainComponent = _resolveComponent("MainComponent");
    const _component_q_page = _resolveComponent("q-page");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_openBlock(), _createBlock(_component_q_page, null, {
        default: _withCtx(() => [
            _createVNode(_component_MainComponent, null, {
                default: _withCtx(() => [
                    _createVNode(_component_EditorDialog, {
                        modelValue: _ctx.entry,
                        "onUpdate:modelValue": [
                            _cache[9] || (_cache[9] = ($event) => ((_ctx.entry) = $event)),
                            _ctx.processUser
                        ],
                        open: _ctx.editorOpen,
                        "onUpdate:open": _cache[10] || (_cache[10] = ($event) => ((_ctx.editorOpen) = $event)),
                        mode: _ctx.editorMode,
                        "dialog-class": "editor-dialog",
                        "label-entry": _ctx.entryLabel,
                        "label-entry-type": _ctx.$t('dataTable.user.editorEntryType'),
                        "label-new": _ctx.$t('dataTable.user.editorNew'),
                        "model-builder": _ctx.makeUser,
                        "has-model-errors": _ctx.hasModelErrors,
                        processing: _ctx.processing
                    }, {
                        editor: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_1, [
                                (_ctx.getModelError('summary'))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                        _createElementVNode("ul", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getModelError('summary'), (err, ix) => {
                                                return (_openBlock(), _createElementBlock("li", {
                                                    key: ix,
                                                    class: "text-danger"
                                                }, _toDisplayString(err), 1));
                                            }), 128))
                                        ])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('email'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: scope.value.email,
                                                "onUpdate:modelValue": ($event) => ((scope.value.email) = $event),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('email') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('emailRequired'),
                                                    (v) => _ctx.isValidEmail(v) || _ctx.getValidationLabel('incorrectEmail'),
                                                ],
                                                onChange: _cache[0] || (_cache[0] = ($event) => (_ctx.clearModelError('email')))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_4, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('password'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: scope.value.password,
                                                "onUpdate:modelValue": ($event) => ((scope.value.password) = $event),
                                                outlined: "",
                                                type: "password",
                                                rules: [
                                                    (v) => _ctx.getModelError('password') || true,
                                                    (v) => scope.mode != _ctx.EEditorMode.Create || _ctx.requiredField(v) || _ctx.getValidationLabel('passwordRequired'),
                                                ],
                                                onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.clearModelError('password')))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('repeatPassword'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: _ctx.repeatPassword,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.repeatPassword) = $event)),
                                                outlined: "",
                                                type: "password",
                                                rules: [(v) => _ctx.isMatchOrFalsy(v, scope.value.password) || _ctx.getValidationLabel('passwordMismatch')]
                                            }, null, 8, ["modelValue", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"])
                                ]),
                                (_ctx.canSelectOptions)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                        _createVNode(_component_LabelWrapper, {
                                            label: _ctx.getPropertyLabel('organization'),
                                            class: "col q-mr-sm",
                                            "inner-class": "bg-light",
                                            "outer-class": "bg-light"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_select, {
                                                    outlined: "",
                                                    disable: !_ctx.canSelectOptions || _ctx.editorMode != _ctx.EEditorMode.Create,
                                                    "model-value": scope.value.organization,
                                                    options: _ctx.organizations,
                                                    "option-label": "name",
                                                    "use-input": "",
                                                    "hide-selected": "",
                                                    "fill-input": "",
                                                    clearable: "",
                                                    placeholder: scope.value.organization?.name,
                                                    rules: [
                                                        (v) => _ctx.getModelError('organization') || true,
                                                        (v) => !!v || _ctx.getValidationLabel('organizationRequired'),
                                                    ],
                                                    onFilter: _ctx.filterOrganizations,
                                                    onNewValue: ($event) => (_ctx.createOrganization(scope.value, $event)),
                                                    "onUpdate:modelValue": ($event) => (_ctx.selectOrganization(scope.value, $event))
                                                }, null, 8, ["disable", "model-value", "options", "placeholder", "rules", "onFilter", "onNewValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                        }, 1032, ["label"]),
                                        _createVNode(_component_LabelWrapper, {
                                            label: _ctx.getPropertyLabel('customerCode'),
                                            class: "col q-mr-sm col-3",
                                            "inner-class": "bg-light",
                                            "outer-class": "bg-light"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_input, {
                                                    modelValue: _ctx.customerCode,
                                                    "onUpdate:modelValue": [
                                                        _cache[3] || (_cache[3] = ($event) => ((_ctx.customerCode) = $event)),
                                                        (val) => {
                                                            scope.value.organization.customerCode = val;
                                                        }
                                                    ],
                                                    modelModifiers: { trim: true },
                                                    outlined: "",
                                                    disable: !_ctx.canSelectOptions ||
                                                        scope.value.organization == null ||
                                                        (scope.value.organization && scope.value.organization.id != null),
                                                    type: "text",
                                                    "fill-input": "",
                                                    clearable: "",
                                                    rules: [
                                                        (v) => _ctx.getModelError('organization.CustomerCode') || true,
                                                        (v) => _ctx.validateCustomerCode(v, scope.value.organization) || _ctx.getValidationLabel('customerCodeRequired'),
                                                    ],
                                                    onChange: _cache[4] || (_cache[4] = ($event) => (_ctx.clearModelError('organization.CustomerCode')))
                                                }, null, 8, ["modelValue", "disable", "rules", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                        }, 1032, ["label"]),
                                        _createVNode(_component_LabelWrapper, {
                                            label: _ctx.getPropertyLabel('roles'),
                                            class: "col col-4",
                                            "inner-class": "bg-light",
                                            "outer-class": "bg-light"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_select, {
                                                    outlined: "",
                                                    disable: !_ctx.canSelectOptions || _ctx.editorMode != _ctx.EEditorMode.Create,
                                                    "model-value": (scope.value.roles || [])[0],
                                                    options: _ctx.assignableRoles,
                                                    "option-label": "name",
                                                    "reactive-rules": _ctx.hasModelErrors,
                                                    rules: [(v) => _ctx.getModelError('roles') || true, (v) => !!v || _ctx.getValidationLabel('rolesRequired')],
                                                    "onUpdate:modelValue": ($event) => {
                                                        scope.value.roles = [$event];
                                                        _ctx.clearModelError('roles');
                                                    }
                                                }, null, 8, ["disable", "model-value", "options", "reactive-rules", "rules", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                        }, 1032, ["label"])
                                    ]))
                                    : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_7, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('costCentre'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: scope.value.costCentre,
                                                "onUpdate:modelValue": ($event) => ((scope.value.costCentre) = $event),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [(v) => _ctx.getModelError('costCentre') || true],
                                                onChange: _cache[5] || (_cache[5] = ($event) => (_ctx.clearModelError('costCentre')))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_8, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('firstName'),
                                        class: "col q-mr-sm",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: scope.value.firstName,
                                                "onUpdate:modelValue": ($event) => ((scope.value.firstName) = $event),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('firstName') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('firstNameRequired'),
                                                ],
                                                onChange: _cache[6] || (_cache[6] = ($event) => (_ctx.clearModelError('firstName')))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"]),
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('lastName'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: scope.value.lastName,
                                                "onUpdate:modelValue": ($event) => ((scope.value.lastName) = $event),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [
                                                    (v) => _ctx.getModelError('lastName') || true,
                                                    (v) => _ctx.requiredField(v) || _ctx.getValidationLabel('lastNameRequired'),
                                                ],
                                                onChange: _cache[7] || (_cache[7] = ($event) => (_ctx.clearModelError('lastName')))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"])
                                ]),
                                _createElementVNode("div", _hoisted_9, [
                                    _createVNode(_component_LabelWrapper, {
                                        label: _ctx.getPropertyLabel('phoneNumber'),
                                        class: "col",
                                        "inner-class": "bg-light",
                                        "outer-class": "bg-light"
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_input, {
                                                modelValue: scope.value.phoneNumber,
                                                "onUpdate:modelValue": ($event) => ((scope.value.phoneNumber) = $event),
                                                outlined: "",
                                                "reactive-rules": _ctx.hasModelErrors,
                                                rules: [(v) => _ctx.getModelError('phoneNumber') || true],
                                                onChange: _cache[8] || (_cache[8] = ($event) => (_ctx.clearModelError('phoneNumber')))
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "reactive-rules", "rules"])
                                        ]),
                                        _: 2
                                    }, 1032, ["label"])
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["modelValue", "open", "mode", "label-entry", "label-entry-type", "label-new", "model-builder", "has-model-errors", "processing", "onUpdate:modelValue"]),
                    (_ctx.setupDone)
                        ? (_openBlock(), _createBlock(_component_DataTable, {
                            key: 0,
                            ref: "dataTable",
                            pagination: _ctx.pagination,
                            "onUpdate:pagination": _cache[11] || (_cache[11] = ($event) => ((_ctx.pagination) = $event)),
                            "data-type": "user",
                            "editor-class": "editor-dialog",
                            loading: _ctx.loading,
                            "has-model-errors": _ctx.hasModelErrors,
                            entries: _ctx.entries,
                            columns: _ctx.columns,
                            filters: _ctx.filters,
                            "model-builder": _ctx.makeUser,
                            onFetch: _ctx.fetchEntries,
                            onCreate: _ctx.createUser
                        }, {
                            actions: _withCtx(() => [
                                _createVNode(_component_q_btn, {
                                    class: "bg-accent text-white",
                                    onClick: _ctx.addNewEntry
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_icon, {
                                            class: "q-mr-md",
                                            name: "mdi-plus-circle",
                                            color: "white"
                                        }),
                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.user.actions.add")), 1)
                                    ]),
                                    _: 1
                                }, 8, ["onClick"])
                            ]),
                            "body-cell-organization": _withCtx((cell) => [
                                (_ctx.canSelectOptions)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.organizationSummary(cell.value)), 1))
                                    : _createCommentVNode("", true)
                            ]),
                            "body-cell": _withCtx((cell) => [
                                _createElementVNode("div", _hoisted_11, _toDisplayString(cell.value), 1)
                            ]),
                            "body-cell-roles": _withCtx((cell) => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.rolesSummary(cell.value)), 1)
                            ]),
                            "body-cell-actions": _withCtx((cell) => [
                                _createVNode(_component_q_btn, { flat: "" }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_icon, { name: "mdi-dots-vertical" }),
                                        _createVNode(_component_q_menu, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_list, null, {
                                                    default: _withCtx(() => [
                                                        _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                            clickable: "",
                                                            onClick: ($event) => (_ctx.editEntry(cell.row.entry))
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_q_item_section, null, {
                                                                    default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.$t("dataTable.user.actions.edit")), 1)
                                                                    ]),
                                                                    _: 1
                                                                })
                                                            ]),
                                                            _: 2
                                                        }, 1032, ["onClick"])), [
                                                            [_directive_close_popup]
                                                        ])
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]),
                                            _: 2
                                        }, 1024)
                                    ]),
                                    _: 2
                                }, 1024)
                            ]),
                            _: 1
                        }, 8, ["pagination", "loading", "has-model-errors", "entries", "columns", "filters", "model-builder", "onFetch", "onCreate"]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
