import { isNullOrWhitespace } from "@/helper/stringUtils";
const requiredField = (field) => {
    return !isNullOrWhitespace(field);
};
const isPositive = (field) => {
    const num = +field;
    return !isNaN(num) && num > 0;
};
const isValidEmail = (email) => {
    if (!email)
        return true;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return !!email.match(emailRegex);
};
const isValidNIP = (nip) => {
    if (!nip)
        return true;
    const nipRegex = "^[0-9]{10}$";
    return !!nip.match(nipRegex);
};
const isPercentageValue = (value) => {
    if (value >= 0 && value <= 100)
        return true;
    return false;
};
const areEqual = (firstVal, secondVal) => {
    return firstVal === secondVal;
};
const areNull = (firstVal, secondVal) => {
    return !firstVal || !secondVal;
};
const isMatchOrFalsy = (value, expected) => {
    return (!value && !expected) || areEqual(value, expected);
};
const isCorrectLength = (val, min, max) => {
    return !!(val && val.length >= min && val.length <= max);
};
const isCorrectLengthOptional = (val, min, max) => {
    return !!(!val || (val.length >= min && val.length <= max));
};
const isEori = (val) => {
    const regex = /^[a-zA-Z]{2}[0-9]{8,15}/;
    return !!val.match(regex);
};
export { requiredField, isPositive, isValidNIP, isValidEmail, isPercentageValue, areEqual, areNull, isMatchOrFalsy, isCorrectLength, isCorrectLengthOptional, isNullOrWhitespace, isEori, };
