import { authModule } from "@/store/modules/auth";
const permissionDirective = {
    async created(el, binding) {
        const userPermitted = authModule.hasPermission(binding.value.permissions);
        if (!userPermitted) {
            el.style.display = "none";
        }
    },
};
export default permissionDirective;
