import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "row bg-color-secondary" };
const _hoisted_2 = { class: "box-padding" };
const _hoisted_3 = { class: "q-pa-md" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col col-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_select = _resolveComponent("q-select");
    const _component_q_card = _resolveComponent("q-card");
    const _component_ButtonDialog = _resolveComponent("ButtonDialog");
    return (_openBlock(), _createBlock(_component_ButtonDialog, {
        ref: "languageDialog",
        label: "common.setup",
        title: "userSettings.appLanguage",
        action: _ctx.update,
        clear: _ctx.clear,
        onFormClosed: _ctx.formClosed
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, { style: { "width": "600px" } }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$q.screen.lt.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                            }, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createElementVNode("div", _hoisted_3, [
                                        _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("div", _hoisted_5, [
                                                _createVNode(_component_q_select, {
                                                    modelValue: _ctx.language,
                                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.language) = $event)),
                                                    outlined: "",
                                                    options: _ctx.languages,
                                                    label: _ctx.$t('toolbar.menu.lang')
                                                }, null, 8, ["modelValue", "options", "label"])
                                            ])
                                        ])
                                    ])
                                ])
                            ], 2)
                        ], 2)
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["action", "clear", "onFormClosed"]));
}
