import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_toolbar_title = _resolveComponent("q-toolbar-title");
    const _component_q_toolbar = _resolveComponent("q-toolbar");
    const _component_q_footer = _resolveComponent("q-footer");
    return _withDirectives((_openBlock(), _createBlock(_component_q_footer, { class: "footer" }, {
        default: _withCtx(() => [
            _createVNode(_component_q_toolbar, { class: "main-toolbar" }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_toolbar_title, {
                        id: "footer",
                        class: "q-pa-sm"
                    })
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 512)), [
        [_vShow, _ctx.show]
    ]);
}
