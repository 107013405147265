var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { IncotermEnum, ShipmentClient, ShipmentServiceEnum, } from "@/api/client";
import { notifyImportant, notifyProblems } from "@/helper/notifyHelper";
import extend from 'quasar/src/utils/extend.js';
;
import { ShipmentStep } from "./data";
import { canShipmentBeEvaluated, customsCompleteOrNotNeeded, isAddressComplete, isReevaluationRequired, } from "./helper";
import i18n from "@/i18n";
import { settingsModule } from "@/store/modules/settings";
import { userModule } from "@/store/modules/user";
import moment from "moment";
export var CustomsType;
(function (CustomsType) {
    CustomsType[CustomsType["Commercial"] = 0] = "Commercial";
    CustomsType[CustomsType["NonCommercial"] = 1] = "NonCommercial";
})(CustomsType || (CustomsType = {}));
const disabledServices = [
    ShipmentServiceEnum.DeliveryIn24H,
    ShipmentServiceEnum.DeliveryUpTo15,
    ShipmentServiceEnum.ReturnDocument,
];
const defaultState = () => {
    return {
        packageSent: false,
        triggerValuation: true,
        history: [],
        valuations: [],
        waybills: [],
        additionalReponseInfo: undefined,
        shipmentRequest: {
            recipientAddress: {},
            recipientContact: { isIndividual: true },
            shipperAddress: {},
            shipperContact: {},
            packages: [],
            valuation: {
                shipmentValuation: {},
            },
            shipperCorrespondeceAddress: {},
            shipperCorrespondeceAddressDefined: false,
            recipientCorrespondeceAddress: {},
            recipientCorrespondeceAddressDefined: false,
            selectedServices: Object.values(ShipmentServiceEnum).map((x) => {
                return { service: x, state: false, value: "", disabled: disabledServices.includes(x) };
            }),
            valuations: [],
            bankAccount: {},
            customsDeclaration: undefined,
            reference: "",
        },
        dayOfWeek: -1,
        calculatedServices: Object.values(ShipmentServiceEnum).map((x) => {
            return { service: x, state: false, value: "", disabled: disabledServices.includes(x) };
        }),
        addressSet: false,
        customsPackages: [],
        isReturnShipment: false,
        customsType: CustomsType.Commercial,
        invoiceFile: null,
    };
};
let ShipmentModule = class ShipmentModule extends VuexModule {
    _client = new ShipmentClient();
    state = defaultState(); //extend(true, {}, defaultState()) as ShipmentModuleState;
    get ranking() {
        const valuations = this.state.valuations ?? [];
        return valuations
            .map((valuation) => {
            let extraServicesCost = 0;
            if (valuation.shipmentValuation.extraServicesInfo.length) {
                extraServicesCost = valuation.shipmentValuation.extraServicesInfo
                    .map((x) => x.cost)
                    .reduce((prev, curr) => prev + curr, 0);
            }
            const cost = valuation.shipmentValuation.basePrice + extraServicesCost + valuation.shipmentValuation.surchargePrice;
            return { valuation: valuation, cost: cost };
        })
            .sort((x, y) => {
            return Number(x.valuation.failed) - Number(y.valuation.failed) || x.cost - y.cost;
        });
    }
    clearStore() {
        this.state = defaultState(); // extend(true, {}, defaultState()) as ShipmentModuleState;
    }
    async resetStore() {
        this.clearStore();
        await this.loadOrganizationDetails();
    }
    swapRecipients() {
        const request = this.state.shipmentRequest;
        this.state.isReturnShipment = !this.state.isReturnShipment;
        const t1 = request.shipperAddress;
        const t2 = request.shipperCorrespondeceAddress;
        const t3 = request.shipperContact;
        const t4 = request.shipperCorrespondeceAddressDefined;
        request.shipperAddress = request.recipientAddress;
        request.shipperCorrespondeceAddress = request.recipientCorrespondeceAddress;
        request.shipperContact = request.recipientContact;
        request.shipperCorrespondeceAddressDefined = request.recipientCorrespondeceAddressDefined;
        request.recipientAddress = t1;
        request.recipientCorrespondeceAddress = t2;
        request.recipientContact = t3;
        request.recipientCorrespondeceAddressDefined = t4;
    }
    // data fetch
    async fetchPackageSizes() {
        try {
            const response = await this._client.getPackageSizes();
            this.state.packageSizes = response.packageSizes;
        }
        catch (error) {
            notifyProblems(error);
            this.state.packageSizes = undefined;
        }
    }
    async fetchCountries() {
        // NOTE: currently downloads roughly 30kB of data, and that amount might still get slightly bigger
        // so possible TODO would be to split this API call to multiple lesser ones
        // for comparison, the app itself is roughly 400kB, while vendors 1.3MB - however these two ideally load once for a lifetime
        try {
            const getCountriesResponse = await this._client.getCountries();
            this.state.countries = getCountriesResponse.items;
        }
        catch (error) {
            notifyProblems(error);
            this.state.countries = undefined;
        }
    }
    async fetchCurrencies() {
        try {
            const res = await this._client.getCurrencies();
            this.state.currencies = res.items;
        }
        catch (error) {
            notifyProblems(error);
            this.state.currencies = undefined;
        }
    }
    async fetchIncoterms() {
        try {
            const res = await this._client.getIncoterms();
            this.state.incoterms = res.incoterms;
        }
        catch (error) {
            notifyProblems(error);
            this.state.incoterms = undefined;
        }
    }
    async fetchDestinations(value) {
        // TEMP: fetch all to local store when undef, perform query on local items
        // this is to allow client-only destination saving (since there is currently no db to save this data)
        const { query } = value;
        let items = this.state.destinations;
        if (!items) {
            try {
                const result = await this._client.getAddressBookItems(query);
                this.state.destinations = result.items;
                items = result.items;
            }
            catch (error) {
                notifyProblems(error);
            }
        }
        if (!items)
            return [];
        return query
            ? items.filter((v) => {
                const queryToLower = query.toLowerCase();
                const companyName = v.contact.companyName.toLowerCase();
                return (companyName.indexOf(queryToLower) != -1 ||
                    (!companyName &&
                        (`${v.contact.lastName} ${v.contact.firstName}`.toLowerCase().includes(queryToLower) ||
                            `${v.contact.firstName} ${v.contact.lastName}`.toLowerCase().includes(queryToLower))));
            })
            : items;
    }
    // moved here, so it could be invoked right at the start of the shipment creation
    // previously, beign part of a step 3 mounting, you couldn't swap addresses as part of step 2 without prior visit to step 3
    async loadOrganizationDetails() {
        if (this.state.addressSet)
            return;
        this.state.addressSet = true; // clearStore/resetStore required
        const request = this.state.shipmentRequest;
        // was parsing strigified response before - like what? why?
        const orgDetails = await settingsModule.getOrganizationDetails();
        const userDetails = await userModule.getUserOrganizationDetails();
        if (!userDetails) {
            return;
        }
        if (orgDetails && orgDetails.detailsExist) {
            request.shipperContact.nip = orgDetails.nip;
            request.shipperContact.companyName = orgDetails.company;
            request.shipperContact.costCenter = orgDetails.costCenterCode;
        }
        else {
            request.shipperContact.nip = userDetails.nip;
            request.shipperContact.companyName = userDetails.companyName;
        }
        request.shipperContact.firstName = userDetails.firstName;
        request.shipperContact.lastName = userDetails.lastName;
        request.shipperContact.email = userDetails.userEmail;
        request.shipperContact.phone = userDetails.userPhoneNumber;
        if (userDetails.userAddress) {
            request.shipperAddress = userDetails.userAddress;
        }
        if (userDetails.userCorrespondenceAddress && isAddressComplete(userDetails.userCorrespondenceAddress)) {
            request.shipperCorrespondeceAddressDefined = true;
            request.shipperCorrespondeceAddress = userDetails.userCorrespondenceAddress;
        }
    }
    // server sync
    async addPackageSize(template) {
        const res = (await this._client.addPackageSize({
            packageModel: template,
        }));
        if (!res.errors) {
            await shipmentModule.fetchPackageSizes();
        }
    }
    async addDestination(value) {
        const { type, address, contact } = value;
        const entry = {
            type: type,
            address: {
                countryCode: address.countryCode,
                postalCode: address.postalCode,
                city: address.city,
                street: address.street,
                streetNumber: address.streetNumber,
                localNumber: address.localNumber,
                additionalNotes: address.additionalNotes,
            },
            contact: {
                companyName: contact.companyName,
                isIndividual: contact.isIndividual,
                firstName: contact.firstName,
                lastName: contact.lastName,
                vatId: contact.nip,
                email: contact.email,
                phone: contact.phone,
            },
        };
        try {
            await this._client.addAddressBookItem({ addressBook: entry });
            this.state.destinations?.push(entry);
        }
        catch (error) {
            notifyProblems(error);
            return false; // or rethrow?
        }
        return true;
    }
    async fetchDayOfWeek() {
        if (this.state.dayOfWeek == -1)
            this.state.dayOfWeek = await this._client.getDayOfWeek();
    }
    revaluateShipment() {
        this.state.triggerValuation = true;
    }
    // external apis
    async valuate() {
        if (!(this.state.triggerValuation || (this.state.valuations && this.state.valuations.length === 0))) {
            return;
        }
        if (shipmentModule.state.shipmentRequest.customsDeclaration && !this.customsNeeded()) {
            shipmentModule.state.shipmentRequest.customsDeclaration.isDeclared = false;
        }
        const request = {
            shipperAddress: this.state.shipmentRequest.shipperAddress,
            recipientAddress: this.state.shipmentRequest.recipientAddress,
            selectedServices: this.state.calculatedServices,
            packages: this.state.shipmentRequest.packages,
            insuranceValue: 1,
            packageValue: 1,
            shipperContact: this.state.shipmentRequest.shipperContact,
            recipientContact: this.state.shipmentRequest.recipientContact,
            customsDeclaration: this.mapCustomsDeclaration(),
            bankAccount: this.state.shipmentRequest.bankAccount,
        };
        if (request.customsDeclaration != undefined &&
            request.customsDeclaration.invoice != undefined &&
            request.customsDeclaration.commercialShipment &&
            request.customsDeclaration.invoice.date) {
            request.customsDeclaration.invoice.date = this.formatCustomsDate(request.customsDeclaration.invoice.date);
        }
        try {
            const result = await this._client.rateShipment(request);
            this.state.valuations = result.shipmentValuations;
            this.state.shipmentRequest.valuations = result.shipmentValuations;
            this.state.triggerValuation = false;
            this.state.shipmentRequest.valuation = {
                shipmentValuation: {},
            };
        }
        catch (error) {
            this.state.valuations = [];
            notifyProblems(error);
        }
    }
    mapCustomsDeclaration() {
        if (shipmentModule.state.shipmentRequest.customsDeclaration &&
            (!shipmentModule.state.shipmentRequest.customsDeclaration.isDeclared ||
                shipmentModule.state.shipmentRequest.customsDeclaration.userSelectedNoClearanceShipment)) {
            return {
                isDeclared: shipmentModule.state.shipmentRequest.customsDeclaration.isDeclared,
                userSelectedNoClearanceShipment: shipmentModule.state.shipmentRequest.customsDeclaration.userSelectedNoClearanceShipment,
                currencyCode: "",
                invoice: { date: undefined, invoiceNumber: "" },
                items: [],
                shipperEori: undefined,
                recipientEori: undefined,
                businessNumber: undefined,
                incoterm: IncotermEnum.DDP,
                shipperVatRegistrationNumber: undefined,
                attachment: undefined,
                commercialShipment: true,
            };
        }
        else
            return shipmentModule.state.shipmentRequest.customsDeclaration;
    }
    formatCustomsDate(date) {
        if (date) {
            const d = new Date(date);
            return moment(d).format("YYYY-MM-DD");
        }
        return date;
    }
    async validateAddress(requestAddress) {
        if (!isAddressComplete(requestAddress))
            return false;
        try {
            const validationResult = await this._client.validateAddress(requestAddress.countryCode, requestAddress.postalCode, requestAddress.city, requestAddress.street, requestAddress.streetNumber);
            return validationResult.addressExists;
        }
        catch (ex) {
            notifyImportant({
                title: i18n.global.t("contactDataForm.addressDoesntExist"),
                body: i18n.global.t("contactDataForm.addressDoesntExistDescription"),
                timeout: 4000,
            });
        }
        return false;
    }
    get activeSteps() {
        let result = [ShipmentStep.Package, ShipmentStep.Recipient, ShipmentStep.Shipper];
        if (canShipmentBeEvaluated(this.state.shipmentRequest) && this.customsNeeded()) {
            result = [...result, ShipmentStep.Customs];
        }
        if (canShipmentBeEvaluated(this.state.shipmentRequest) && customsCompleteOrNotNeeded(this.state.shipmentRequest)) {
            result = [...result, ShipmentStep.Valuation];
        }
        if (!this.state.triggerValuation &&
            this.state.valuations &&
            this.state.valuations.length > 0 &&
            this.state.valuations.filter((x) => !x.failed).length > 0 &&
            result.findIndex((a) => a == ShipmentStep.Valuation) >= 0) {
            result = [...result, ShipmentStep.Summary];
        }
        return result;
    }
    customsNeeded() {
        const recipientCode = shipmentModule.state.shipmentRequest.recipientAddress.countryCode;
        const shipperCode = shipmentModule.state.shipmentRequest.shipperAddress.countryCode;
        const euCountries = this.state.countries?.filter((x) => x.inEU).map((x) => x.countryCode) ?? [];
        return (recipientCode != null &&
            recipientCode != undefined &&
            recipientCode != shipperCode &&
            (!euCountries.includes(shipperCode) || !euCountries.includes(recipientCode)));
    }
};
__decorate([
    Mutation
], ShipmentModule.prototype, "clearStore", null);
__decorate([
    Action
], ShipmentModule.prototype, "resetStore", null);
__decorate([
    Mutation
], ShipmentModule.prototype, "swapRecipients", null);
__decorate([
    Action
], ShipmentModule.prototype, "fetchPackageSizes", null);
__decorate([
    Action
], ShipmentModule.prototype, "fetchCountries", null);
__decorate([
    Action
], ShipmentModule.prototype, "fetchCurrencies", null);
__decorate([
    Action
], ShipmentModule.prototype, "fetchIncoterms", null);
__decorate([
    Action
], ShipmentModule.prototype, "fetchDestinations", null);
__decorate([
    Action
], ShipmentModule.prototype, "loadOrganizationDetails", null);
__decorate([
    Action
], ShipmentModule.prototype, "addPackageSize", null);
__decorate([
    Action
], ShipmentModule.prototype, "addDestination", null);
__decorate([
    Action
], ShipmentModule.prototype, "fetchDayOfWeek", null);
__decorate([
    Mutation
], ShipmentModule.prototype, "revaluateShipment", null);
__decorate([
    Action
], ShipmentModule.prototype, "valuate", null);
ShipmentModule = __decorate([
    Module
], ShipmentModule);
export const shipmentModule = new ShipmentModule({ store, name: "shipment" });
shipmentModule.$watch((m) => m.state.shipmentRequest, async (current) => {
    const previous = shipmentModule.state.history.pop();
    if (previous) {
        shipmentModule.state.triggerValuation = isReevaluationRequired(current, previous);
    }
    shipmentModule.state.history.push(extend(true, {}, current));
}, { deep: true, immediate: true });
