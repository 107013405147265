import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-12cf785b"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row items-lg-center courier-text text-primary q-col-gutter-sm full-width" };
const _hoisted_2 = { class: "col-3 text-bold" };
const _hoisted_3 = { class: "col-7" };
const _hoisted_4 = { class: "col-2 text-primary" };
const _hoisted_5 = { class: "row items-lg-center courier-text text-primary q-col-gutter-sm full-width" };
const _hoisted_6 = { class: "col-3 text-bold" };
const _hoisted_7 = { class: "col-7" };
const _hoisted_8 = { class: "col-2 text-primary" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_linear_progress = _resolveComponent("q-linear-progress");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_list = _resolveComponent("q-list");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_q_list, {
            dense: "",
            class: _normalizeClass(_ctx.shouldScroll ? 'scroll' : '')
        }, {
            default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                    return (_openBlock(), _createBlock(_component_q_item, {
                        key: index,
                        class: "average-delivery-time",
                        dense: ""
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("courier." + item.courierCode.toLowerCase())), 1),
                                        _createElementVNode("div", _hoisted_3, [
                                            _createElementVNode("div", {
                                                style: _normalizeStyle({ width: _ctx.rowSize(item.time) + '%' })
                                            }, [
                                                _createVNode(_component_q_linear_progress, {
                                                    class: "average-delivery-time__progress",
                                                    size: "15px",
                                                    value: 1,
                                                    color: "indigo-12"
                                                })
                                            ], 4)
                                        ]),
                                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.format(item.time)) + "h", 1)
                                    ])
                                ]),
                                _: 2
                            }, 1024)
                        ]),
                        _: 2
                    }, 1024));
                }), 128))
            ]),
            _: 1
        }, 8, ["class"]),
        (_ctx.hasOverallAverage)
            ? (_openBlock(), _createBlock(_component_q_item, {
                key: 0,
                class: "average-delivery-time",
                dense: ""
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_item_section, null, {
                        default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("reports.average")), 1),
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", {
                                        style: _normalizeStyle({ width: _ctx.rowSize(_ctx.overallAverage) + '%' })
                                    }, [
                                        _createVNode(_component_q_linear_progress, {
                                            class: "average-delivery-time__progress",
                                            size: "15px",
                                            value: 1,
                                            color: "primary"
                                        })
                                    ], 4)
                                ]),
                                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.format(_ctx.overallAverage)) + "h", 1)
                            ])
                        ]),
                        _: 1
                    })
                ]),
                _: 1
            }))
            : _createCommentVNode("", true)
    ], 64));
}
