import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8ef6b318"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row content__main q-pa-sm" };
const _hoisted_2 = { class: "box-padding" };
const _hoisted_3 = { class: "row" };
const _hoisted_4 = { class: "col" };
const _hoisted_5 = { class: "row text-h6 text-bold text-primary q-pb-sm" };
const _hoisted_6 = { class: "row q-col-gutter-sm" };
const _hoisted_7 = { class: "col col-6" };
const _hoisted_8 = { class: "delivery-service q-mt-sm" };
const _hoisted_9 = { class: "font-service q-ml-md text-primary" };
const _hoisted_10 = ["innerHTML"];
const _hoisted_11 = { class: "q-py-sm q-px-md" };
const _hoisted_12 = ["innerHTML"];
const _hoisted_13 = ["innerHTML"];
const _hoisted_14 = { class: "q-py-sm q-px-md" };
const _hoisted_15 = { class: "col col-6" };
const _hoisted_16 = { class: "row" };
const _hoisted_17 = { class: "col col-12" };
const _hoisted_18 = { class: "q-mt-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_radio = _resolveComponent("q-radio");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_tooltip = _resolveComponent("q-tooltip");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_item_label = _resolveComponent("q-item-label");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_select = _resolveComponent("q-select");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_ServiceType = _resolveComponent("ServiceType");
    const _component_q_form = _resolveComponent("q-form");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
        }, [
            _createElementVNode("div", {
                class: _normalizeClass(_ctx.$q.screen.lg.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
            }, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("summary.additionalServices")) + ":", 1)
                        ])
                    ]),
                    _createVNode(_component_q_card, { class: "card-primary" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_form, { class: "form q-py-sm q-px-md" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("div", _hoisted_7, [
                                            _createElementVNode("div", _hoisted_8, [
                                                _createVNode(_component_q_radio, {
                                                    modelValue: _ctx.deliveryTimeServiceState,
                                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.deliveryTimeServiceState) = $event)),
                                                    val: null,
                                                    class: "delivery-service__radio"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("additionalService.standardDelivery")), 1)
                                                    ]),
                                                    _: 1
                                                }, 8, ["modelValue"])
                                            ]),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getDeliveryTimeServices, (service, index) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                    key: index,
                                                    class: "delivery-service q-mt-sm"
                                                }, [
                                                    _createVNode(_component_q_radio, {
                                                        modelValue: _ctx.deliveryTimeServiceState,
                                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.deliveryTimeServiceState) = $event)),
                                                        val: service.service,
                                                        disable: !_ctx.canSelectService(service),
                                                        class: "delivery-service__radio"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createElementVNode("div", {
                                                                class: "font-service q-ml-md text-primary",
                                                                innerHTML: _ctx.getServiceHtml(service.service)
                                                            }, null, 8, _hoisted_10)
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["modelValue", "val", "disable"]),
                                                    _createVNode(_component_q_icon, {
                                                        class: "q-pl-sm",
                                                        name: "mdi-help-circle-outline",
                                                        size: "xs"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_q_tooltip, {
                                                                anchor: "top middle",
                                                                self: "bottom left"
                                                            }, {
                                                                default: _withCtx(() => [
                                                                    _createVNode(_component_q_card, {
                                                                        bordered: "",
                                                                        class: "tooltip shadow-2"
                                                                    }, {
                                                                        default: _withCtx(() => [
                                                                            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("additionalService.24hTooltip")), 1)
                                                                        ]),
                                                                        _: 1
                                                                    })
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 1
                                                    })
                                                ]));
                                            }), 128)),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getShipmentServices, (service, index) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                    key: index,
                                                    class: "delivery-service q-mt-sm q-pt-xs"
                                                }, [
                                                    _createVNode(_component_q_checkbox, {
                                                        "model-value": service.state,
                                                        class: "delivery-service__radio",
                                                        disable: !_ctx.canSelectService(service),
                                                        onClick: ($event) => (_ctx.toggleService(service))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createElementVNode("div", {
                                                                class: "font-service q-ml-md text-primary",
                                                                innerHTML: _ctx.getServiceHtml(service.service)
                                                            }, null, 8, _hoisted_12)
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["model-value", "disable", "onClick"])
                                                ]));
                                            }), 128)),
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOtherServices, (service, index) => {
                                                return (_openBlock(), _createElementBlock("div", {
                                                    key: index,
                                                    class: "delivery-service q-mt-sm q-pt-xs q-mb-sm"
                                                }, [
                                                    _createVNode(_component_q_checkbox, {
                                                        "model-value": service.state,
                                                        class: "delivery-service__radio",
                                                        disable: !_ctx.canSelectService(service),
                                                        onClick: ($event) => (_ctx.toggleService(service))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createElementVNode("div", {
                                                                class: "font-service q-ml-md text-primary",
                                                                innerHTML: _ctx.getServiceHtml(service.service)
                                                            }, null, 8, _hoisted_13)
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["model-value", "disable", "onClick"]),
                                                    (service.service === _ctx.shipmentServiceEnum.ProofOfDelivery)
                                                        ? (_openBlock(), _createBlock(_component_q_icon, {
                                                            key: 0,
                                                            class: "q-pl-sm",
                                                            name: "mdi-help-circle-outline",
                                                            size: "xs"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_q_tooltip, {
                                                                    anchor: "top middle",
                                                                    self: "bottom left"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_card, {
                                                                            bordered: "",
                                                                            class: "tooltip shadow-2"
                                                                        }, {
                                                                            default: _withCtx(() => [
                                                                                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("additionalService.epod")), 1)
                                                                            ]),
                                                                            _: 1
                                                                        })
                                                                    ]),
                                                                    _: 1
                                                                })
                                                            ]),
                                                            _: 1
                                                        }))
                                                        : _createCommentVNode("", true)
                                                ]));
                                            }), 128))
                                        ]),
                                        _createElementVNode("div", _hoisted_15, [
                                            _createElementVNode("div", _hoisted_16, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOtherServicesWithVal, (service, index) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                        key: index,
                                                        class: "row q-pt-sm full-width"
                                                    }, [
                                                        _createElementVNode("div", _hoisted_17, [
                                                            _createVNode(_component_ServiceType, {
                                                                ref_for: true,
                                                                ref: "additionalServices",
                                                                value: service,
                                                                "show-value-input": true,
                                                                "show-additional-input": service.service === _ctx.shipmentServiceEnum.CashOnDelivery,
                                                                "bank-account-currency": _ctx.getServiceCurrencyCode(service.service)
                                                            }, {
                                                                additionalData: _withCtx(() => [
                                                                    _createVNode(_component_LabelWrapper, {
                                                                        label: _ctx.$t('shipmentValuation.defaultBankAccount'),
                                                                        "label-class": "text-primary",
                                                                        "inner-class": "bg-light",
                                                                        "outer-class": "transparent"
                                                                    }, {
                                                                        default: _withCtx(() => [
                                                                            _createElementVNode("div", _hoisted_18, [
                                                                                _createVNode(_component_q_select, {
                                                                                    modelValue: _ctx.bankAccount,
                                                                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.bankAccount) = $event)),
                                                                                    outlined: "",
                                                                                    "bg-color": "light",
                                                                                    "no-error-icon": "",
                                                                                    disable: !service.state,
                                                                                    style: { "word-break": "break-all" },
                                                                                    options: _ctx.bankAccounts,
                                                                                    "virtual-scroll-slice-size": "300",
                                                                                    rules: [(val) => (val && val.id) || _ctx.$t('shipmentValuation.bankAccountRequired')]
                                                                                }, {
                                                                                    "selected-item": _withCtx(() => [
                                                                                        _createVNode(_component_q_item, { style: { "overflow": "hidden" } }, {
                                                                                            default: _withCtx(() => [
                                                                                                _createVNode(_component_q_item_section, null, {
                                                                                                    default: _withCtx(() => [
                                                                                                        _createVNode(_component_q_item_label, null, {
                                                                                                            default: _withCtx(() => [
                                                                                                                _createTextVNode(_toDisplayString(_ctx.bankAccount.description), 1)
                                                                                                            ]),
                                                                                                            _: 1
                                                                                                        }),
                                                                                                        _createVNode(_component_q_item_label, {
                                                                                                            caption: "",
                                                                                                            lines: "1"
                                                                                                        }, {
                                                                                                            default: _withCtx(() => [
                                                                                                                _createTextVNode(_toDisplayString(_ctx.bankAccount.iban), 1)
                                                                                                            ]),
                                                                                                            _: 1
                                                                                                        })
                                                                                                    ]),
                                                                                                    _: 1
                                                                                                })
                                                                                            ]),
                                                                                            _: 1
                                                                                        })
                                                                                    ]),
                                                                                    option: _withCtx(({ itemProps, opt }) => [
                                                                                        _createVNode(_component_q_item, _mergeProps(itemProps, { style: { "overflow": "hidden" } }), {
                                                                                            default: _withCtx(() => [
                                                                                                _createVNode(_component_q_item_section, null, {
                                                                                                    default: _withCtx(() => [
                                                                                                        _createVNode(_component_q_item_label, null, {
                                                                                                            default: _withCtx(() => [
                                                                                                                _createTextVNode(_toDisplayString(opt.description), 1)
                                                                                                            ]),
                                                                                                            _: 2
                                                                                                        }, 1024),
                                                                                                        _createVNode(_component_q_item_label, {
                                                                                                            caption: "",
                                                                                                            lines: "1"
                                                                                                        }, {
                                                                                                            default: _withCtx(() => [
                                                                                                                _createTextVNode(_toDisplayString(opt.currency.currencyCode), 1)
                                                                                                            ]),
                                                                                                            _: 2
                                                                                                        }, 1024),
                                                                                                        _createVNode(_component_q_item_label, {
                                                                                                            caption: "",
                                                                                                            lines: "1"
                                                                                                        }, {
                                                                                                            default: _withCtx(() => [
                                                                                                                _createTextVNode(_toDisplayString(opt.iban), 1)
                                                                                                            ]),
                                                                                                            _: 2
                                                                                                        }, 1024)
                                                                                                    ]),
                                                                                                    _: 2
                                                                                                }, 1024)
                                                                                            ]),
                                                                                            _: 2
                                                                                        }, 1040)
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1032, ["modelValue", "disable", "options", "rules"])
                                                                            ])
                                                                        ]),
                                                                        _: 2
                                                                    }, 1032, ["label"])
                                                                ]),
                                                                _: 2
                                                            }, 1032, ["value", "show-additional-input", "bank-account-currency"])
                                                        ])
                                                    ]));
                                                }), 128))
                                            ])
                                        ])
                                    ])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })
                ])
            ], 2)
        ], 2)
    ]));
}
