import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-39008c1f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "selection-plate__overlay absolute-full"
};
const _hoisted_2 = { class: "q-pa-sm" };
const _hoisted_3 = { class: "q-pa-md" };
const _hoisted_4 = { class: "q-py-sm q-px-md" };
const _hoisted_5 = { class: "row q-py-sm q-px-md" };
const _hoisted_6 = ["src"];
const _hoisted_7 = {
    class: "q-pa-sm secondary",
    style: { "display": "inline-block" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_radio = _resolveComponent("q-radio");
    const _component_q_toggle = _resolveComponent("q-toggle");
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_tooltip = _resolveComponent("q-tooltip");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_item_label = _resolveComponent("q-item-label");
    const _component_q_item = _resolveComponent("q-item");
    return (_openBlock(), _createBlock(_component_q_card, {
        class: _normalizeClass(["selection-plate", [_ctx.backgroundClass, _ctx.textClass]]),
        flat: _ctx.flat,
        bordered: ""
    }, {
        default: _withCtx(() => [
            (_ctx.isDisable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1))
                : _createCommentVNode("", true),
            _createVNode(_component_q_item, {
                class: _normalizeClass(["selection-plate__item", [_ctx.isDisable ? 'disable' : '']]),
                tag: "label"
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_item_section, {
                        avatar: "",
                        class: "selection-plate__input"
                    }, {
                        default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "input", {}, () => [
                                (_ctx.type == 'radio')
                                    ? (_openBlock(), _createBlock(_component_q_radio, {
                                        key: 0,
                                        modelValue: _ctx.radioModel,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.radioModel) = $event)),
                                        disable: _ctx.disable,
                                        color: _ctx.currentColor,
                                        "keep-color": "",
                                        val: _ctx.val
                                    }, null, 8, ["modelValue", "disable", "color", "val"]))
                                    : _createCommentVNode("", true),
                                (_ctx.type == 'toggle')
                                    ? (_openBlock(), _createBlock(_component_q_toggle, {
                                        key: 1,
                                        modelValue: _ctx.d_modelValue,
                                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.d_modelValue) = $event)),
                                        disable: _ctx.disable,
                                        color: _ctx.currentColor,
                                        "keep-color": "",
                                        "toggle-indeterminate": _ctx.toggle_indeterminate,
                                        "indeterminate-value": _ctx.indeterminate_value
                                    }, null, 8, ["modelValue", "disable", "color", "toggle-indeterminate", "indeterminate-value"]))
                                    : _createCommentVNode("", true),
                                (_ctx.type == 'checkbox')
                                    ? (_openBlock(), _createBlock(_component_q_checkbox, {
                                        key: 2,
                                        modelValue: _ctx.d_modelValue,
                                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.d_modelValue) = $event)),
                                        disable: _ctx.disable,
                                        color: _ctx.currentColor,
                                        "keep-color": "",
                                        val: _ctx.val,
                                        "toggle-indeterminate": _ctx.toggle_indeterminate,
                                        "indeterminate-value": _ctx.indeterminate_value
                                    }, null, 8, ["modelValue", "disable", "color", "val", "toggle-indeterminate", "indeterminate-value"]))
                                    : _createCommentVNode("", true)
                            ], true)
                        ]),
                        _: 3
                    }),
                    _createVNode(_component_q_item_section, {
                        class: _normalizeClass(["selection-plate__content q-pt-md", [_ctx.isCenter ? 'center' : '']])
                    }, {
                        default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "label", {}, () => [
                                _createVNode(_component_q_item_label, { class: "selection-plate__content__primary" }, {
                                    default: _withCtx(() => [
                                        _renderSlot(_ctx.$slots, "primary", {}, () => [
                                            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
                                            (_ctx.tooltip)
                                                ? (_openBlock(), _createBlock(_component_q_icon, {
                                                    key: 0,
                                                    name: "mdi-help-circle-outline",
                                                    size: "sm"
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createVNode(_component_q_tooltip, {
                                                            anchor: "top left",
                                                            self: "bottom right"
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createVNode(_component_q_card, {
                                                                    bordered: "",
                                                                    class: "tooltip shadow-2"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tooltipPrimary), 1),
                                                                        _createVNode(_component_q_separator),
                                                                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.tooltipSecondary), 1),
                                                                        _createElementVNode("div", _hoisted_5, [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tooltipImages, (source, index) => {
                                                                                return (_openBlock(), _createElementBlock("img", {
                                                                                    class: "q-pa-xs",
                                                                                    key: index,
                                                                                    src: require(`@/assets/shipment/${source}`)
                                                                                }, null, 8, _hoisted_6));
                                                                            }), 128))
                                                                        ])
                                                                    ]),
                                                                    _: 1
                                                                })
                                                            ]),
                                                            _: 1
                                                        })
                                                    ]),
                                                    _: 1
                                                }))
                                                : _createCommentVNode("", true)
                                        ], true)
                                    ]),
                                    _: 3
                                }),
                                _renderSlot(_ctx.$slots, "secondary", {}, () => [
                                    _createVNode(_component_q_item_label, null, {
                                        default: _withCtx(() => [
                                            (_ctx.icon)
                                                ? (_openBlock(), _createBlock(_component_q_icon, {
                                                    key: 0,
                                                    name: _ctx.icon,
                                                    size: "lg"
                                                }, null, 8, ["name"]))
                                                : _createCommentVNode("", true)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_q_item_label, null, {
                                        default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.labelSecondary), 1)
                                        ]),
                                        _: 1
                                    })
                                ], true)
                            ], true)
                        ]),
                        _: 3
                    }, 8, ["class"])
                ]),
                _: 3
            }, 8, ["class"])
        ]),
        _: 3
    }, 8, ["class", "flat"]));
}
