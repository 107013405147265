import { CourierCodeEnum, ShipmentServiceEnum, } from "@/api/client";
import isEqual from "lodash.isequal";
import Router from "@/router";
import { shipmentModule } from ".";
import i18n from "@/i18n";
import { isValidEmail } from "@/validation/field-validation";
export const isContactComplete = (contact) => {
    return !!contact.firstName && !!contact.lastName && !!contact.phone && !!contact.email && isValidEmail(contact.email);
};
export const isAddressComplete = (address) => {
    return !!address.street && !!address.streetNumber && !!address.postalCode && !!address.city && !!address.countryCode;
};
export const isPackageComplete = (shipmentPackage) => {
    return (!!shipmentPackage.height &&
        !!shipmentPackage.weight &&
        !!shipmentPackage.length &&
        !!shipmentPackage.quantity &&
        (!shipmentPackage.description || shipmentPackage.description.length <= 40));
};
export const isCustomsDeclarationComplete = (customsDeclaration) => {
    return (customsDeclaration.commercialShipment == false ||
        (!!customsDeclaration.currencyCode &&
            !!customsDeclaration.shipperEori &&
            !!customsDeclaration.incoterm &&
            !!customsDeclaration.commercialShipment));
};
export const canShipmentBeEvaluated = (request) => {
    let correspondenceAddressComplete = true;
    if (request.recipientCorrespondeceAddressDefined && request.recipientCorrespondeceAddress) {
        correspondenceAddressComplete &&= isAddressComplete(request.recipientCorrespondeceAddress);
    }
    if (request.shipperCorrespondeceAddressDefined && request.shipperCorrespondeceAddress) {
        correspondenceAddressComplete &&= isAddressComplete(request.shipperCorrespondeceAddress);
    }
    return (correspondenceAddressComplete &&
        isContactComplete(request.recipientContact) &&
        isAddressComplete(request.recipientAddress) &&
        isContactComplete(request.shipperContact) &&
        isAddressComplete(request.shipperAddress) &&
        request.packages.length > 0 &&
        request.packages.every(isPackageComplete));
};
export const customsCompleteOrNotNeeded = (request) => {
    return (!shipmentModule.customsNeeded() ||
        shipmentModule.state.shipmentRequest.customsDeclaration?.userSelectedNoClearanceShipment ||
        (request.customsDeclaration && isCustomsDeclarationComplete(request.customsDeclaration)));
};
export const isReevaluationRequired = (current, previous) => {
    if (shipmentModule.state.triggerValuation)
        return true;
    if (previous == undefined)
        return true;
    const packages = isEqual(current.packages, previous.packages);
    const recipient = isEqual(current.recipientAddress, previous.recipientAddress);
    const recipientContact = isEqual(current.recipientContact, previous.recipientContact);
    const vatId = isEqual(current.recipientContact.nip, previous.recipientContact.nip);
    const shipper = isEqual(current.shipperAddress, previous.shipperAddress);
    const services = isEqual(current.selectedServices, previous.selectedServices);
    const valuationRoute = Router.currentRoute.value.fullPath === "/send-package/valuation";
    const customs = isEqual(current.customsDeclaration, previous.customsDeclaration);
    if (valuationRoute && !services && packages && recipient && shipper && recipientContact && vatId && customs) {
        return false;
    }
    return !packages || !recipient || !shipper || !services || !recipientContact || !vatId || !customs;
};
export const isFurgonetkaCode = (code) => {
    return (code == CourierCodeEnum.FurgonetkaDPD ||
        code == CourierCodeEnum.FurgonetkaUPS ||
        code == CourierCodeEnum.FurgonetkaDHL ||
        code == CourierCodeEnum.FurgonetkaFedEx ||
        code == CourierCodeEnum.FurgonetkaInPost ||
        code == CourierCodeEnum.FurgonetkaPocztex ||
        code == CourierCodeEnum.FurgonetkaGLS ||
        code == CourierCodeEnum.FurgonetkaAmbroExpress ||
        code == CourierCodeEnum.FurgonetkaDeliGoo ||
        code == CourierCodeEnum.FurgonetkaXpressDelivery);
};
export const getServiceName = (service, courier) => {
    if ([ShipmentServiceEnum.DeliveryUpTo9, ShipmentServiceEnum.DeliveryUpTo12].some((v) => v == service)) {
        const morning = service == ShipmentServiceEnum.DeliveryUpTo9;
        let hour = morning ? "09:00" : "12:00";
        if (!courier)
            return `${i18n.global.t("shipmentService.deliveryUpTo")} <b>${hour}</b>`;
        if (service == ShipmentServiceEnum.DeliveryUpTo9) {
            if (courier == CourierCodeEnum.DPD || isFurgonetkaCode(courier)) {
                hour = "09:30";
            }
            if (courier == CourierCodeEnum.GLS || courier == CourierCodeEnum.InPostShipX) {
                hour = "10:00";
            }
        }
        if (service == ShipmentServiceEnum.DeliveryUpTo12) {
            if (courier == CourierCodeEnum.DPD) {
                //
            }
        }
        return `${i18n.global.t("shipmentService.deliveryUpTo")} <b>${hour}</b>`;
    }
    return i18n.global.t(`shipmentService.${service}`);
};
