import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col col-12 col-md-6 col-sm-12" };
const _hoisted_3 = { class: "col col-12 col-md-6 col-sm-12" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ChartsData = _resolveComponent("ChartsData");
    const _component_Map = _resolveComponent("Map");
    const _component_MainComponent = _resolveComponent("MainComponent");
    const _component_q_page = _resolveComponent("q-page");
    return (_openBlock(), _createBlock(_component_q_page, null, {
        default: _withCtx(() => [
            _createVNode(_component_MainComponent, null, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_ChartsData)
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_Map)
                        ])
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
