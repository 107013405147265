import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "column" };
const _hoisted_2 = { class: "row items-stretch" };
const _hoisted_3 = { class: "row bg-color-secondary" };
const _hoisted_4 = { class: "box-padding" };
const _hoisted_5 = { class: "q-pa-md" };
const _hoisted_6 = { class: "row items-stretch" };
const _hoisted_7 = { class: "col-grow col-12 q-pa-xs" };
const _hoisted_8 = { style: { "white-space": "pre" } };
const _hoisted_9 = { style: { "white-space": "pre" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dialog = _resolveComponent("Dialog");
    const _component_BankAccount = _resolveComponent("BankAccount");
    const _component_EditorDialog = _resolveComponent("EditorDialog");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_menu = _resolveComponent("q-menu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_card = _resolveComponent("q-card");
    const _component_ButtonDialog = _resolveComponent("ButtonDialog");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_openBlock(), _createBlock(_component_ButtonDialog, {
        ref: "orgDialog",
        label: "common.setup",
        title: "settings.bankAccountSettings",
        hideSaveBtn: true
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, { style: { "width": "1200px" } }, {
                default: _withCtx(() => [
                    _createVNode(_component_Dialog, {
                        ref: "deleteMultipleBankAccountsDialog",
                        title: "dataTable.bankAccount.actions.deleteMultipleTitle",
                        action: _ctx.deleteMultipleConfirm,
                        "submit-label": "common.confirm"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("dataTable.bankAccount.actions.deleteMultipleMessage")), 1)
                        ]),
                        _: 1
                    }, 8, ["action"]),
                    _createVNode(_component_EditorDialog, {
                        modelValue: _ctx.entry,
                        "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event) => ((_ctx.entry) = $event)),
                            _ctx.processEntry
                        ],
                        open: _ctx.editorOpen,
                        "onUpdate:open": _cache[1] || (_cache[1] = ($event) => ((_ctx.editorOpen) = $event)),
                        mode: _ctx.editorMode,
                        "dialog-class": "editor-dialog",
                        "label-entry": _ctx.entryLabel,
                        "label-entry-type": _ctx.$t('dataTable.bankAccount.editorEntryType'),
                        "label-new": _ctx.$t('dataTable.bankAccount.editorNew'),
                        "model-builder": _ctx.makeEntry,
                        "has-model-errors": _ctx.hasModelErrors,
                        processing: _ctx.processing
                    }, {
                        editor: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_BankAccount, {
                                        ref: "bankAccount",
                                        value: scope.value
                                    }, null, 8, ["value"])
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["modelValue", "open", "mode", "label-entry", "label-entry-type", "label-new", "model-builder", "has-model-errors", "processing", "onUpdate:modelValue"]),
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$q.screen.lt.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                            }, [
                                _createElementVNode("div", _hoisted_4, [
                                    _createVNode(_component_q_form, { ref: "form" }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_5, [
                                                _createElementVNode("div", _hoisted_6, [
                                                    _createElementVNode("div", _hoisted_7, [
                                                        _createVNode(_component_DataTable, {
                                                            ref: "dataTable",
                                                            pagination: _ctx.pagination,
                                                            "onUpdate:pagination": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                                                            "data-type": "bankAccount",
                                                            "editor-class": "editor-dialog",
                                                            "selection-column": "id",
                                                            loading: _ctx.loading,
                                                            "has-model-errors": _ctx.hasModelErrors,
                                                            entries: _ctx.entries,
                                                            columns: _ctx.columns,
                                                            filters: _ctx.filters,
                                                            "model-builder": _ctx.makeEntry,
                                                            onFetch: _ctx.fetchEntries,
                                                            onCreate: _ctx.createEntry
                                                        }, {
                                                            actions: _withCtx(() => [
                                                                _createVNode(_component_q_btn, {
                                                                    class: "bg-accent text-white",
                                                                    onClick: _ctx.addNewEntry
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_icon, {
                                                                            class: "q-mr-md",
                                                                            name: "mdi-plus-circle",
                                                                            color: "white"
                                                                        }),
                                                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.bankAccount.actions.add")), 1)
                                                                    ]),
                                                                    _: 1
                                                                }, 8, ["onClick"])
                                                            ]),
                                                            "group-actions": _withCtx((props) => [
                                                                _createVNode(_component_q_btn, {
                                                                    flat: "",
                                                                    "no-caps": "",
                                                                    onClick: ($event) => (_ctx.deleteMultiple(props.selection))
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_icon, {
                                                                            class: "q-mr-md",
                                                                            name: "mdi-delete-sweep",
                                                                            color: "primary"
                                                                        }),
                                                                        _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.bankAccount.actions.deleteMultiple")), 1)
                                                                    ]),
                                                                    _: 2
                                                                }, 1032, ["onClick"])
                                                            ]),
                                                            "body-cell": _withCtx((cell) => [
                                                                _createElementVNode("div", _hoisted_8, _toDisplayString(cell.value), 1)
                                                            ]),
                                                            "body-cell-isDefault": _withCtx((cell) => [
                                                                _createElementVNode("div", _hoisted_9, [
                                                                    _createVNode(_component_q_checkbox, {
                                                                        class: "no-pointer-events",
                                                                        "model-value": cell.value
                                                                    }, null, 8, ["model-value"])
                                                                ])
                                                            ]),
                                                            "body-cell-actions": _withCtx((cell) => [
                                                                _createVNode(_component_q_btn, { flat: "" }, {
                                                                    default: _withCtx(() => [
                                                                        _createVNode(_component_q_icon, { name: "mdi-dots-vertical" }),
                                                                        _createVNode(_component_q_menu, null, {
                                                                            default: _withCtx(() => [
                                                                                _createVNode(_component_q_list, null, {
                                                                                    default: _withCtx(() => [
                                                                                        _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                                                            clickable: "",
                                                                                            onClick: ($event) => (_ctx.editEntry(cell.row.entry))
                                                                                        }, {
                                                                                            default: _withCtx(() => [
                                                                                                _createVNode(_component_q_item_section, null, {
                                                                                                    default: _withCtx(() => [
                                                                                                        _createTextVNode(_toDisplayString(_ctx.$t("dataTable.bankAccount.actions.edit")), 1)
                                                                                                    ]),
                                                                                                    _: 1
                                                                                                })
                                                                                            ]),
                                                                                            _: 2
                                                                                        }, 1032, ["onClick"])), [
                                                                                            [_directive_close_popup]
                                                                                        ]),
                                                                                        _createVNode(_component_q_separator),
                                                                                        _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                                                            clickable: "",
                                                                                            onClick: ($event) => (_ctx.deleteEntry(cell.row.entry))
                                                                                        }, {
                                                                                            default: _withCtx(() => [
                                                                                                _createVNode(_component_q_item_section, null, {
                                                                                                    default: _withCtx(() => [
                                                                                                        _createTextVNode(_toDisplayString(_ctx.$t("dataTable.bankAccount.actions.delete")), 1)
                                                                                                    ]),
                                                                                                    _: 1
                                                                                                })
                                                                                            ]),
                                                                                            _: 2
                                                                                        }, 1032, ["onClick"])), [
                                                                                            [_directive_close_popup]
                                                                                        ])
                                                                                    ]),
                                                                                    _: 2
                                                                                }, 1024)
                                                                            ]),
                                                                            _: 2
                                                                        }, 1024)
                                                                    ]),
                                                                    _: 2
                                                                }, 1024)
                                                            ]),
                                                            _: 1
                                                        }, 8, ["pagination", "loading", "has-model-errors", "entries", "columns", "filters", "model-builder", "onFetch", "onCreate"])
                                                    ])
                                                ])
                                            ])
                                        ]),
                                        _: 1
                                    }, 512)
                                ])
                            ], 2)
                        ], 2)
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 512));
}
