import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d407296c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "content" };
const _hoisted_2 = { class: "justify-end" };
const _hoisted_3 = { class: "q-mb-sm content__action-btns" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col col-12 text-h6 text-bold selected-location" };
const _hoisted_6 = { class: "q-mr-md" };
const _hoisted_7 = { class: "row content__charts q-col-gutter-md q-mt-none scrollbar-sm" };
const _hoisted_8 = { class: "col col-12 col-sm-12 col-md-6" };
const _hoisted_9 = { class: "col col-12 col-sm-12 col-md-6" };
const _hoisted_10 = { class: "col col-12 col-sm-12 col-md-6" };
const _hoisted_11 = { class: "col col-12 col-sm-12 col-md-6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown");
    const _component_q_radio = _resolveComponent("q-radio");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_btn_group = _resolveComponent("q-btn-group");
    const _component_Calendar = _resolveComponent("Calendar");
    const _component_Popover = _resolveComponent("Popover");
    const _component_q_chip = _resolveComponent("q-chip");
    const _component_Punctuality = _resolveComponent("Punctuality");
    const _component_ChartDataWrapper = _resolveComponent("ChartDataWrapper");
    const _component_AverageDeliveryTime = _resolveComponent("AverageDeliveryTime");
    const _component_DeliveryHours = _resolveComponent("DeliveryHours");
    const _component_Returns = _resolveComponent("Returns");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_q_btn_dropdown, {
                    class: _normalizeClass(["bg-light rounded-borders shadow-1 q-mr-md", _ctx.$q.screen.lt.sm ? 'fit' : '']),
                    align: "between",
                    style: { "min-width": "170px", "flex-grow": "1" },
                    label: _ctx.selectedService.label,
                    "no-caps": "",
                    flat: ""
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_list, {
                            class: "bg-light",
                            style: { "min-width": "150px" }
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getServiceOptions, (serviceGroup, index) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                        _createVNode(_component_q_item, null, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_item_section, { style: { "font-size": "13px" } }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(serviceGroup.label), 1)
                                                    ]),
                                                    _: 2
                                                }, 1024)
                                            ]),
                                            _: 2
                                        }, 1024),
                                        _createVNode(_component_q_separator),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(serviceGroup.items, (service, indexItem) => {
                                            return _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                key: indexItem,
                                                clickable: "",
                                                disable: service.disabled,
                                                onClick: ($event) => (_ctx.selectedService = service)
                                            }, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_q_item_section, { class: "text-primary text-bold" }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(service.label), 1)
                                                        ]),
                                                        _: 2
                                                    }, 1024)
                                                ]),
                                                _: 2
                                            }, 1032, ["disable", "onClick"])), [
                                                [_directive_close_popup]
                                            ]);
                                        }), 128))
                                    ], 64));
                                }), 128))
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["class", "label"]),
                _createElementVNode("div", {
                    class: _normalizeClass(["q-mr-md", _ctx.$q.screen.lt.sm ? 'fit q-mt-sm' : ''])
                }, [
                    _createVNode(_component_q_btn_group, {
                        class: _normalizeClass([_ctx.$q.screen.lt.sm ? 'fit' : '', "rounded-borders shadow-1"]),
                        flat: ""
                    }, {
                        default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipmentFilterOptions, (option, index) => {
                                return (_openBlock(), _createBlock(_component_q_btn, {
                                    key: index,
                                    color: _ctx.isOptionActive(option) ? 'primary' : 'white',
                                    "no-caps": "",
                                    style: _normalizeStyle(_ctx.$q.screen.lt.sm ? 'width:50%' : ''),
                                    class: "full-height bg-light"
                                }, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_radio, {
                                            modelValue: _ctx.optionFilter,
                                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.optionFilter) = $event)),
                                            class: _normalizeClass(_ctx.isOptionActive(option) ? 'text-white' : 'text-primary'),
                                            color: _ctx.isOptionActive(option) ? 'white' : 'primary',
                                            val: option
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("reports." + option)), 1)
                                            ]),
                                            _: 2
                                        }, 1032, ["modelValue", "class", "color", "val"])
                                    ]),
                                    _: 2
                                }, 1032, ["color", "style"]));
                            }), 128))
                        ]),
                        _: 1
                    }, 8, ["class"])
                ], 2),
                _createVNode(_component_q_btn, {
                    class: _normalizeClass(["bg-light rounded-borders shadow-1", _ctx.$q.screen.lt.sm ? 'fit q-mt-sm' : '']),
                    icon: "mdi-calendar-blank-outline",
                    flat: "",
                    "no-caps": "",
                    label: _ctx.period.label
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_Popover, { position: "bottom" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_Calendar, {
                                    period: _ctx.period,
                                    "onUpdate:period": _cache[1] || (_cache[1] = ($event) => ((_ctx.period) = $event)),
                                    date: _ctx.date,
                                    "onUpdate:date": _cache[2] || (_cache[2] = ($event) => ((_ctx.date) = $event))
                                }, null, 8, ["period", "date"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["class", "label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("reports.dataForSelectedLocation")), 1),
                (_ctx.selectedLocation && _ctx.selectedLocation !== '')
                    ? (_openBlock(), _createBlock(_component_q_chip, {
                        key: 0,
                        color: "primary",
                        removable: _ctx.removeLocationFilterEnabled,
                        "text-color": "white",
                        square: "",
                        onRemove: _cache[3] || (_cache[3] = ($event) => (_ctx.selectedLocation = ''))
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getCountryName(_ctx.selectedLocation)), 1)
                        ]),
                        _: 1
                    }, 8, ["removable"]))
                    : (_openBlock(), _createBlock(_component_q_chip, {
                        key: 1,
                        class: "text-weight-medium",
                        color: "accent",
                        "text-color": "white",
                        square: ""
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("reports.selectLocationOnMap")), 1)
                        ]),
                        _: 1
                    }))
            ])
        ]),
        _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_ChartDataWrapper, {
                    "primary-label": _ctx.$t('reports.punctuality.' + _ctx.optionFilter),
                    "is-empty": _ctx.isEmpty
                }, {
                    default: _withCtx(() => [
                        (!_ctx.isEmpty)
                            ? (_openBlock(), _createBlock(_component_Punctuality, {
                                key: 0,
                                value: _ctx.stats,
                                total: _ctx.statsTotal,
                                scroll: ""
                            }, null, 8, ["value", "total"]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["primary-label", "is-empty"])
            ]),
            _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_ChartDataWrapper, {
                    "primary-label": _ctx.$t('reports.averageDelivery.' + _ctx.optionFilter),
                    "is-empty": _ctx.hasNoAverageDeliveryTimes
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_AverageDeliveryTime, {
                            value: _ctx.averageDeliveryTimes,
                            scroll: ""
                        }, null, 8, ["value"])
                    ]),
                    _: 1
                }, 8, ["primary-label", "is-empty"])
            ]),
            _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_ChartDataWrapper, {
                    "primary-label": _ctx.deliveryHoursLabel,
                    "is-empty": _ctx.isEmpty
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_DeliveryHours, {
                            value: _ctx.selectedStats,
                            tooltip: _ctx.deliveryHoursTooltip
                        }, null, 8, ["value", "tooltip"])
                    ]),
                    _: 1
                }, 8, ["primary-label", "is-empty"])
            ]),
            _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_ChartDataWrapper, {
                    "primary-label": _ctx.$t('reports.returns'),
                    "is-empty": _ctx.isEmpty
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_Returns)
                    ]),
                    _: 1
                }, 8, ["primary-label", "is-empty"])
            ])
        ])
    ]));
}
