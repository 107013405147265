var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VuexModule, Module, Action } from "vuex-class-modules";
import store from "@/store";
import { UserClient, } from "@/api/client";
import { shipmentModule } from "@/store/modules/shipment";
import moment from "moment";
import i18n from "@/i18n";
let UserModule = class UserModule extends VuexModule {
    _client = new UserClient();
    state = { demoEmail: "" };
    async getUserOrganizationDetails() {
        try {
            this.state.userDetails = await this._client.getUserDetails();
            this.state.userFullName = `${this.state.userDetails.firstName} ${this.state.userDetails.lastName}`;
        }
        catch (error) {
            //
        }
        return this.state.userDetails;
    }
    async updateUserLanguage(language) {
        await this._client.updateApplicationLanguage({ language: language });
        await this.getUserOrganizationDetails();
        this.updateApplicationLanguage();
    }
    updateApplicationLanguage() {
        const language = this.state.userDetails?.applicationLanguage;
        if (language != undefined)
            i18n.global.locale.value = language;
        moment.locale(language ?? "pl");
    }
    async setAddressDetails(addressDetails) {
        try {
            await this._client.setUserAddressDetails(addressDetails);
        }
        catch (error) {
            //
        }
        await this.getUserOrganizationDetails();
        await shipmentModule.resetStore();
        return this.state.userDetails;
    }
    async setContactDetails(contactDetails) {
        try {
            await this._client.updateUserContact(contactDetails);
        }
        catch (error) {
            //
        }
        await this.getUserOrganizationDetails();
        await shipmentModule.resetStore();
        return this.state.userDetails;
    }
    clearUserData() {
        this.state.userDetails = undefined;
        this.state.userFullName = undefined;
    }
    hasRole(...roleNames) {
        return this.state.userDetails?.roles.some((v) => roleNames.some((vv) => v == vv.toUpperCase()));
    }
    async requestDemoAccess(email) {
        return await this._client.addDemoUser({ email: email });
    }
};
__decorate([
    Action
], UserModule.prototype, "getUserOrganizationDetails", null);
__decorate([
    Action
], UserModule.prototype, "setAddressDetails", null);
__decorate([
    Action
], UserModule.prototype, "setContactDetails", null);
__decorate([
    Action
], UserModule.prototype, "clearUserData", null);
__decorate([
    Action
], UserModule.prototype, "hasRole", null);
__decorate([
    Action
], UserModule.prototype, "requestDemoAccess", null);
UserModule = __decorate([
    Module
], UserModule);
export const userModule = new UserModule({ store, name: "user" });
