import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "row q-col-gutter-lg q-mb-md" };
const _hoisted_2 = { class: "col col-6" };
const _hoisted_3 = { class: "col col-7" };
const _hoisted_4 = { class: "col col-12" };
const _hoisted_5 = { class: "col-7" };
const _hoisted_6 = { class: "col-4" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_select = _resolveComponent("q-select");
    return (_openBlock(), _createElementBlock("div", null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_q_checkbox, {
                    modelValue: _ctx.value.isDefault,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.value.isDefault) = $event)),
                    label: _ctx.$t('bankAccount.boolMainAccount')
                }, null, 8, ["modelValue", "label"])
            ]),
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('bankAccount.description'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.description,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.value.description) = $event)),
                            modelModifiers: { trim: true },
                            type: "text",
                            outlined: "",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('bankAccount.descriptionRequired'),
                                (val) => _ctx.isCorrectLength(val, 1, 100) || _ctx.$t('bankAccount.descriptionLength'),
                            ]
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('bankAccount.accountOwner'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.accountOwner,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.value.accountOwner) = $event)),
                            type: "text",
                            outlined: "",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('bankAccount.accountOwnerRequired'),
                                (val) => _ctx.isCorrectLength(val, 1, 100) || _ctx.$t('bankAccount.accountOwnerNameLength'),
                            ]
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('bankAccount.iban'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.iban,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.iban) = $event)),
                            modelModifiers: { trim: true },
                            type: "text",
                            outlined: "",
                            style: { "width": "100%" },
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [
                                (val) => _ctx.requiredField(val) || _ctx.$t('bankAccount.ibanRequired'),
                                (val) => _ctx.isCorrectLength(val, 15, 34) || _ctx.$t('bankAccount.ibanLength'),
                                (val) => _ctx.ibanValidator.isValid(val) || _ctx.$t('bankAccount.ibanIncorrectFormat'),
                            ]
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('bankAccount.currency'),
                    "label-class": "text-primary text-small",
                    "inner-class": "bg-light",
                    "outer-class": "bg-light"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_select, {
                            modelValue: _ctx.value.currency,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.value.currency) = $event)),
                            outlined: "",
                            style: { "width": "100%" },
                            "no-error-icon": "",
                            "option-value": "id",
                            "option-label": "currencyCode",
                            options: _ctx.currencies,
                            "lazy-rules": "",
                            "map-options": "",
                            rules: [(val) => (val && _ctx.requiredField(val.currencyCode)) || _ctx.$t('bankAccount.currencyRequired')]
                        }, null, 8, ["modelValue", "options", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ])
    ]));
}
