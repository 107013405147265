import { CourierCodeEnum } from "@/api/client";
export const courierIconHelper = [
    {
        name: CourierCodeEnum.DPD,
        icon: "dpd.svg",
    },
    {
        name: CourierCodeEnum.DHLExpress,
        icon: "dhlExpress.svg",
    },
    {
        name: CourierCodeEnum.DHLParcel,
        icon: "dhlParcel.svg",
    },
    {
        name: CourierCodeEnum.FedexInternational,
        icon: "fedEx.svg",
    },
    {
        name: CourierCodeEnum.FedexLocal,
        icon: "fedEx.svg",
    },
    {
        name: CourierCodeEnum.GLS,
        icon: "gls.svg",
    },
    {
        name: CourierCodeEnum.UPS,
        icon: "ups.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaDPD,
        icon: "dpd.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaUPS,
        icon: "ups.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaDHL,
        icon: "dhlParcel.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaInPost,
        icon: "inPost.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaFedEx,
        icon: "fedEx.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaPocztex,
        icon: "pocztex.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaGLS,
        icon: "gls.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaAmbroExpress,
        icon: "ambroExpress.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaDeliGoo,
        icon: "deliGoo.svg",
    },
    {
        name: CourierCodeEnum.FurgonetkaXpressDelivery,
        icon: "xpressDelivery.svg",
    },
    {
        name: CourierCodeEnum.InPostShipX,
        icon: "inPost.svg",
    },
];
