import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "delivery-hours full-height" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_BarChart = _resolveComponent("BarChart");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
            (_ctx.hours?.length)
                ? (_openBlock(), _createBlock(_component_BarChart, {
                    key: 0,
                    class: "d-chart",
                    "chart-data": _ctx.chartData,
                    options: _ctx.chartOptions,
                    width: 270,
                    height: 220
                }, null, 8, ["chart-data", "options"]))
                : _createCommentVNode("", true)
        ])
    ]));
}
