import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-49c48bf8"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row content__main" };
const _hoisted_2 = { class: "row" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_space = _resolveComponent("q-space");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_Package = _resolveComponent("Package");
    const _component_q_icon = _resolveComponent("q-icon");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
                class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$q.screen.lt.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packages, (_, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "q-mb-sm"
                        }, [
                            _createVNode(_component_Package, {
                                ref_for: true,
                                ref: "packageComponents",
                                index: index,
                                "model-value": _ctx.isExpanded(index),
                                "onUpdate:modelValue": ($event) => (_ctx.handleExpand(index, $event))
                            }, {
                                actions: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_2, [
                                        _createVNode(_component_q_space),
                                        (_ctx.canRemove)
                                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                                key: 0,
                                                class: "q-mr-sm",
                                                color: "primary",
                                                label: _ctx.$t('common.remove'),
                                                onClick: ($event) => (_ctx.remove(index))
                                            }, null, 8, ["label", "onClick"]))
                                            : _createCommentVNode("", true)
                                    ])
                                ]),
                                _: 2
                            }, 1032, ["index", "model-value", "onUpdate:modelValue"])
                        ]));
                    }), 128))
                ], 2)
            ], 2)
        ]),
        (_openBlock(), _createBlock(_Teleport, { to: "#footer-actions" }, [
            _createVNode(_component_q_btn, {
                "no-caps": "",
                class: "bg-color-secondary text-bold text-primary",
                onClick: _ctx.addNewPackage
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_icon, {
                        class: "q-mr-md",
                        name: "mdi-plus-circle",
                        color: "accent"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("package.addNextPackage")), 1)
                ]),
                _: 1
            }, 8, ["onClick"])
        ]))
    ], 64));
}
