import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f7d1145e"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "chart-data__title__primary" };
const _hoisted_2 = {
    key: 0,
    style: { "padding-left": "5px" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_card_section = _resolveComponent("q-card-section");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_inner_loading = _resolveComponent("q-inner-loading");
    const _component_q_card = _resolveComponent("q-card");
    return (_openBlock(), _createBlock(_component_q_card, { class: "chart-data full-height" }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "chart-data__title text-primary" }, {
                default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.primaryLabel), 1),
                    (_ctx.secondaryLabel)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.secondaryLabel), 1))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            }),
            _createVNode(_component_q_separator),
            _createVNode(_component_q_card_section, {
                class: _normalizeClass(["chart-data__content", _ctx.shouldScroll ? 'scroll' : ''])
            }, {
                default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
            }, 8, ["class"]),
            _createVNode(_component_q_inner_loading, {
                showing: _ctx.isEmpty,
                label: _ctx.$t(`reports.noData`),
                size: "0"
            }, null, 8, ["showing", "label"])
        ]),
        _: 3
    }));
}
