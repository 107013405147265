import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-413bbcf2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "box-padding" };
const _hoisted_2 = { class: "col col-12 text-h6 q-mt-sm text-primary" };
const _hoisted_3 = { class: "content-row" };
const _hoisted_4 = { class: "text-bold" };
const _hoisted_5 = { class: "col col-12 text-h6 text-bold q-mt-sm text-primary" };
const _hoisted_6 = {
    key: 0,
    class: "row q-col-gutter-lg q-pt-lg"
};
const _hoisted_7 = { class: "service-price-content text-primary" };
const _hoisted_8 = { class: "service-price-content text-primary" };
const _hoisted_9 = ["innerHTML"];
const _hoisted_10 = {
    key: 0,
    class: "col col-12 q-pt-sm"
};
const _hoisted_11 = { class: "row-info" };
const _hoisted_12 = { class: "q-pl-md text-justify text-primary" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_scroll_area = _resolveComponent("q-scroll-area");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("shipmentValuation.plannedPickupDate")), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.plannedPickupDate), 1)
            ])
        ]),
        _createElementVNode("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.$t("shipmentValuation.selectedServicesInValuation")) + " ", 1),
            _createVNode(_component_q_separator, { class: "q-mt-sm" })
        ]),
        (_ctx.selectedCourier)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_q_scroll_area, { class: "services-scroll" }, {
                    default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getBasePrice, (value, key, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "col col-12 q-pt"
                            }, [
                                _createElementVNode("div", _hoisted_7, [
                                    _createElementVNode("div", null, _toDisplayString(_ctx.$t("surcharges." + key)), 1),
                                    _createElementVNode("div", null, _toDisplayString(value), 1)
                                ])
                            ]));
                        }), 128)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.valuatedServices, (service, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "col col-12 q-pt"
                            }, [
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("div", {
                                        class: "font-service",
                                        innerHTML: _ctx.getServiceHtml(service.service)
                                    }, null, 8, _hoisted_9),
                                    _createElementVNode("div", null, _toDisplayString(_ctx.getSelectedValuation(service.service)), 1)
                                ])
                            ]));
                        }), 128)),
                        (_ctx.upsEnvelopes())
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                    _createVNode(_component_q_icon, {
                                        name: "mdi-information-outline",
                                        size: "md"
                                    }),
                                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("shipmentValuation.upsEnvelopeInfo")), 1)
                                ])
                            ]))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                })
            ]))
            : _createCommentVNode("", true),
        _createVNode(_component_q_separator, { class: "q-mt-sm" })
    ]));
}
