import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8da7bf5a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "row q-pa-md" };
const _hoisted_2 = { class: "row q-pa-md" };
const _hoisted_3 = { class: "row q-pa-md" };
const _hoisted_4 = { class: "row q-pa-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_slider = _resolveComponent("q-slider");
    const _component_q_form = _resolveComponent("q-form");
    const _component_ButtonDialog = _resolveComponent("ButtonDialog");
    return (_openBlock(), _createBlock(_component_ButtonDialog, {
        label: "common.setup",
        title: "settings.parametersSelection",
        action: _ctx.saveParameters
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", null, [
                _createVNode(_component_q_form, null, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            _createVNode(_component_LabelWrapper, {
                                label: _ctx.$t('settings.price'),
                                "label-class": "text-primary",
                                "inner-class": "bg-light",
                                "outer-class": "bg-light-secondary"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_input, {
                                        modelValue: _ctx.price,
                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.price) = $event)),
                                        modelModifiers: { number: true },
                                        outlined: "",
                                        "bg-color": "light",
                                        type: "number",
                                        suffix: "%",
                                        class: "parameter-input input-border",
                                        "no-error-icon": "",
                                        "lazy-rules": "",
                                        onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.price = _ctx.price > 100 ? 100 : _ctx.price < 0 ? 0 : _ctx.price)),
                                        rules: [(val) => _ctx.isPercentageValue(val) || _ctx.$t('settings.wrongPercentageValue')]
                                    }, null, 8, ["modelValue", "rules"])
                                ]),
                                _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_q_slider, {
                                modelValue: _ctx.price,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.price) = $event)),
                                min: 0,
                                max: 100
                            }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_LabelWrapper, {
                                label: _ctx.$t('settings.deliveryDays'),
                                "label-class": "text-primary",
                                "inner-class": "bg-light",
                                "outer-class": "bg-light-secondary"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_input, {
                                        modelValue: _ctx.deliveryDays,
                                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.deliveryDays) = $event)),
                                        modelModifiers: { number: true },
                                        outlined: "",
                                        "bg-color": "light",
                                        type: "number",
                                        suffix: "%",
                                        class: "parameter-input input-border",
                                        "no-error-icon": "",
                                        "lazy-rules": "",
                                        onChange: _cache[4] || (_cache[4] = ($event) => (_ctx.deliveryDays = _ctx.deliveryDays > 100 ? 100 : _ctx.deliveryDays < 0 ? 0 : _ctx.deliveryDays)),
                                        rules: [(val) => _ctx.isPercentageValue(val) || _ctx.$t('settings.wrongPercentageValue')]
                                    }, null, 8, ["modelValue", "rules"])
                                ]),
                                _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_q_slider, {
                                modelValue: _ctx.deliveryDays,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.deliveryDays) = $event)),
                                min: 0,
                                max: 100
                            }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_LabelWrapper, {
                                label: _ctx.$t('settings.pickupDays'),
                                "label-class": "text-primary",
                                "inner-class": "bg-light",
                                "outer-class": "bg-light-secondary"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_input, {
                                        modelValue: _ctx.pickupDays,
                                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.pickupDays) = $event)),
                                        modelModifiers: { number: true },
                                        outlined: "",
                                        "bg-color": "light",
                                        type: "number",
                                        suffix: "%",
                                        class: "parameter-input input-border",
                                        "no-error-icon": "",
                                        "lazy-rules": "",
                                        onChange: _cache[7] || (_cache[7] = ($event) => (_ctx.pickupDays = _ctx.pickupDays > 100 ? 100 : _ctx.pickupDays < 0 ? 0 : _ctx.pickupDays)),
                                        rules: [(val) => _ctx.isPercentageValue(val) || _ctx.$t('settings.wrongPercentageValue')]
                                    }, null, 8, ["modelValue", "rules"])
                                ]),
                                _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_q_slider, {
                                modelValue: _ctx.pickupDays,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.pickupDays) = $event)),
                                min: 0,
                                max: 100
                            }, null, 8, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_LabelWrapper, {
                                label: _ctx.$t('settings.returnRate'),
                                "label-class": "text-primary",
                                "inner-class": "bg-light",
                                "outer-class": "bg-light-secondary"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_input, {
                                        modelValue: _ctx.returnRate,
                                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((_ctx.returnRate) = $event)),
                                        modelModifiers: { number: true },
                                        outlined: "",
                                        "bg-color": "light",
                                        type: "number",
                                        suffix: "%",
                                        class: "parameter-input input-border",
                                        maxlength: "3",
                                        "no-error-icon": "",
                                        "lazy-rules": "",
                                        onChange: _cache[10] || (_cache[10] = ($event) => (_ctx.returnRate = _ctx.returnRate > 100 ? 100 : _ctx.returnRate < 0 ? 0 : _ctx.returnRate)),
                                        rules: [(val) => _ctx.isPercentageValue(val) || _ctx.$t('settings.wrongPercentageValue')]
                                    }, null, 8, ["modelValue", "rules"])
                                ]),
                                _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_q_slider, {
                                modelValue: _ctx.returnRate,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((_ctx.returnRate) = $event)),
                                min: 0,
                                max: 100
                            }, null, 8, ["modelValue"])
                        ])
                    ]),
                    _: 1
                })
            ])
        ]),
        _: 1
    }, 8, ["action"]));
}
