import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_item_label = _resolveComponent("q-item-label");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_ctx.isLoggedIn)
        ? (_openBlock(), _createBlock(_component_q_btn_dropdown, {
            key: 0,
            stretch: "",
            class: "title",
            flat: "",
            label: _ctx.userState.userFullName
        }, {
            default: _withCtx(() => [
                _createVNode(_component_q_list, { class: "user-actions" }, {
                    default: _withCtx(() => [
                        _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                            clickable: "",
                            to: _ctx.toMyAccount
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("toolbar.menu.myAccount")), 1)
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }, 8, ["to"])), [
                            [_directive_close_popup]
                        ]),
                        _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                            clickable: "",
                            onClick: _ctx.logout
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_item_section, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_item_label, null, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("toolbar.menu.logout")), 1)
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                })
                            ]),
                            _: 1
                        }, 8, ["onClick"])), [
                            [_directive_close_popup]
                        ])
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["label"]))
        : _createCommentVNode("", true);
}
