import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_select = _resolveComponent("q-select");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    return (_openBlock(), _createBlock(_component_LabelWrapper, {
        label: _ctx.$t(_ctx.label),
        "label-class": "text-primary",
        "inner-class": "bg-light",
        "outer-class": "transparent"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_select, {
                modelValue: _ctx.costCenter,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.costCenter) = $event)),
                "input-debounce": "200",
                "input-class": "placeholder-primary",
                "hide-selected": "",
                placeholder: _ctx.getCostCenterDescription(_ctx.costCenter),
                outlined: "",
                dense: _ctx.dense,
                "bg-color": "light",
                "no-error-icon": "",
                options: _ctx.items,
                "option-label": _ctx.getCostCenterDescription,
                "virtual-scroll-slice-size": "300",
                "lazy-rules": "",
                "use-input": "",
                rules: _ctx.rules,
                clearable: _ctx.optional,
                onFilter: _ctx.filterFn
            }, null, 8, ["modelValue", "placeholder", "dense", "options", "option-label", "rules", "clearable", "onFilter"])
        ]),
        _: 1
    }, 8, ["label"]));
}
