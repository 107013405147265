import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-509abbd2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "summary-title" };
const _hoisted_2 = { class: "text-left" };
const _hoisted_3 = { class: "text-left" };
const _hoisted_4 = { class: "text-left" };
const _hoisted_5 = { class: "text-left" };
const _hoisted_6 = { class: "text-left" };
const _hoisted_7 = { class: "text-left" };
const _hoisted_8 = { class: "text-left" };
const _hoisted_9 = { class: "text-left" };
const _hoisted_10 = { class: "text-left" };
const _hoisted_11 = { class: "text-left" };
const _hoisted_12 = {
    class: "text-right",
    colspan: "4"
};
const _hoisted_13 = { class: "text-left" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_card_section = _resolveComponent("q-card-section");
    const _component_q_markup_table = _resolveComponent("q-markup-table");
    const _component_q_card = _resolveComponent("q-card");
    return (_openBlock(), _createBlock(_component_q_card, { class: "content__card summary-card-shadow" }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card_section, { class: "content__card__title text-bold q-ma-none" }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t(_ctx.title)), 1)
                ]),
                _: 1
            }),
            _createVNode(_component_q_card_section, { class: "q-pt-none body" }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_markup_table, {
                        class: "table-color",
                        flat: "",
                        dense: "",
                        "wrap-cells": _ctx.$q.screen.gt.sm
                    }, {
                        default: _withCtx(() => [
                            _createElementVNode("thead", null, [
                                _createElementVNode("tr", _hoisted_1, [
                                    _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.$t("summary.length")), 1),
                                    _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.$t("summary.width")), 1),
                                    _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.$t("summary.height")), 1),
                                    _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("summary.weight")), 1),
                                    _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("summary.quantity")), 1)
                                ])
                            ]),
                            _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (p, i) => {
                                    return (_openBlock(), _createElementBlock("tr", { key: i }, [
                                        _createElementVNode("td", _hoisted_7, _toDisplayString(p.length) + " " + _toDisplayString(_ctx.metricUnit), 1),
                                        _createElementVNode("td", _hoisted_8, _toDisplayString(p.width) + " " + _toDisplayString(_ctx.metricUnit), 1),
                                        _createElementVNode("td", _hoisted_9, _toDisplayString(p.height) + " " + _toDisplayString(_ctx.metricUnit), 1),
                                        _createElementVNode("td", _hoisted_10, _toDisplayString(p.weight) + " " + _toDisplayString(_ctx.weightUnit), 1),
                                        _createElementVNode("td", _hoisted_11, _toDisplayString(p.quantity), 1)
                                    ]));
                                }), 128)),
                                _createElementVNode("tr", null, [
                                    _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.$t("summary.packageSum")), 1),
                                    _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.packagesSum), 1)
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["wrap-cells"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
