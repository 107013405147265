var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { MetadataClient } from "@/api/client";
let MedatataModule = class MedatataModule extends VuexModule {
    _client = new MetadataClient();
    state = {
        version: "0.0.0",
    };
    async fetchVersion() {
        try {
            this.state.version = await this._client.getVersionNumber();
        }
        catch (error) {
            //
        }
    }
};
__decorate([
    Action
], MedatataModule.prototype, "fetchVersion", null);
MedatataModule = __decorate([
    Module
], MedatataModule);
export const metadataModule = new MedatataModule({ store, name: "metadata" });
