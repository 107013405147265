import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';
;
import quasarUserOptions from "./quasar-user-options";
import { authModule } from "./store/modules/auth";
import { userModule } from "./store/modules/user";
import i18n from "./i18n";
import { metadataModule } from "@/store/modules/metadata";
import permission from "@/directives/permission";
(async () => {
    await metadataModule.fetchVersion();
    await authModule.validateToken();
    await userModule.getUserOrganizationDetails();
    userModule.updateApplicationLanguage();
    const app = createApp(App, {}).use(i18n);
    app.use(Quasar, quasarUserOptions);
    app.use(store);
    app.use(router);
    app.use(i18n);
    app.directive("permission", permission);
    app.mount("#app");
})();
