import i18n from "@/i18n";
export function currencyFormat(value, currency) {
    if (value === undefined)
        return "";
    return value.toLocaleString(i18n.global.locale.value, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: currency ? "currency" : "decimal",
        currency: currency,
        useGrouping: true,
    });
}
export function integerFormat(value) {
    if (value === undefined)
        return "";
    return value.toLocaleString(i18n.global.locale.value, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "decimal",
        useGrouping: true,
    });
}
export function numberFormat(value, minFractionDigits = 2, maxFractionDigits = 4) {
    if (value === undefined)
        return "";
    return value.toLocaleString(i18n.global.locale.value, {
        minimumFractionDigits: minFractionDigits,
        maximumFractionDigits: maxFractionDigits,
        style: "decimal",
        useGrouping: true,
    });
}
