import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-df13440a"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "wrapper" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col col-12 q-pa-sm" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = {
    key: 0,
    class: "col col-6 q-pa-sm"
};
const _hoisted_6 = { class: "private-address q-mt-sm q-pt-xs" };
const _hoisted_7 = { class: "font-service q-ml-md text-primary" };
const _hoisted_8 = {
    key: 1,
    class: "col col-6"
};
const _hoisted_9 = { class: "row q-pa-sm" };
const _hoisted_10 = { class: "col col-10" };
const _hoisted_11 = { class: "col col-2 q-pl-sm q-pt-sm" };
const _hoisted_12 = {
    key: 0,
    class: "row"
};
const _hoisted_13 = { class: "col col-6 q-pa-sm" };
const _hoisted_14 = { class: "col col-6 q-pa-sm" };
const _hoisted_15 = {
    key: 1,
    class: "row"
};
const _hoisted_16 = { class: "col col-12 q-pa-sm" };
const _hoisted_17 = {
    key: 2,
    class: "row"
};
const _hoisted_18 = { class: "col col-12 q-pa-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_CostCenterSelect = _resolveComponent("CostCenterSelect");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_CostCenterDialog = _resolveComponent("CostCenterDialog");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_LabelWrapper, {
                        label: _ctx.$t('contactDataForm.companyName'),
                        "label-class": "text-primary",
                        "inner-class": "bg-light",
                        "outer-class": "bg-color-secondary"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                                modelValue: _ctx.value.companyName,
                                "onUpdate:modelValue": [
                                    _cache[0] || (_cache[0] = ($event) => ((_ctx.value.companyName) = $event)),
                                    _ctx.companyChanged
                                ],
                                modelModifiers: { trim: true },
                                outlined: "",
                                "stack-label": "",
                                "bg-color": "light",
                                "no-error-icon": "",
                                "lazy-rules": "",
                                "bottom-slots": ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                    }, 8, ["label"])
                ])
            ]),
            _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                    class: _normalizeClass(["col q-pa-sm", [_ctx.useShorthand || _ctx.hasCostCenter ? 'col-6' : 'col-12']])
                }, [
                    _createVNode(_component_LabelWrapper, {
                        label: _ctx.$t('contactDataForm.nip'),
                        "label-class": "text-primary",
                        "inner-class": "bg-light",
                        "outer-class": "bg-color-secondary"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                                modelValue: _ctx.value.nip,
                                "onUpdate:modelValue": [
                                    _cache[1] || (_cache[1] = ($event) => ((_ctx.value.nip) = $event)),
                                    _ctx.nipChanged
                                ],
                                modelModifiers: { trim: true },
                                outlined: "",
                                "stack-label": "",
                                "bg-color": "light",
                                "no-error-icon": "",
                                "lazy-rules": "",
                                "bottom-slots": ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 1
                    }, 8, ["label"])
                ], 2),
                (_ctx.useShorthand)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_q_checkbox, {
                                modelValue: _ctx.value.isIndividual,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.value.isIndividual) = $event)),
                                class: "private-address__radio"
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("contactDataForm.isIndividual")), 1)
                                ]),
                                _: 1
                            }, 8, ["modelValue"])
                        ])
                    ]))
                    : _createCommentVNode("", true),
                (_ctx.hasCostCenter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_CostCenterSelect, {
                                    "model-value": _ctx.value.costCenter,
                                    optional: "",
                                    label: "contactDataForm.mpk",
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => (_ctx.updateCostCenter($event)))
                                }, null, 8, ["model-value"])
                            ]),
                            _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", null, [
                                    _createVNode(_component_q_btn, {
                                        color: "primary",
                                        icon: "mdi-plus",
                                        round: "",
                                        onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.newCostCenter = true))
                                    }),
                                    _createVNode(_component_CostCenterDialog, {
                                        modelValue: _ctx.newCostCenter,
                                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.newCostCenter) = $event))
                                    }, null, 8, ["modelValue"])
                                ])
                            ])
                        ])
                    ]))
                    : _createCommentVNode("", true)
            ])
        ]),
        (!_ctx.isHidden())
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_LabelWrapper, {
                        label: _ctx.$t('contactDataForm.firstName'),
                        "label-class": "text-primary",
                        "inner-class": "bg-light",
                        "outer-class": "bg-color-secondary"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                                modelValue: _ctx.value.firstName,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.value.firstName) = $event)),
                                modelModifiers: { trim: true },
                                disable: _ctx.isDisabled(),
                                outlined: "",
                                "stack-label": "",
                                "bg-color": "light",
                                "no-error-icon": "",
                                "lazy-rules": "",
                                "bottom-slots": "",
                                rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.firstNameRequired')]
                            }, null, 8, ["modelValue", "disable", "rules"])
                        ]),
                        _: 1
                    }, 8, ["label"])
                ]),
                _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_LabelWrapper, {
                        label: _ctx.$t('contactDataForm.lastName'),
                        "label-class": "text-primary",
                        "inner-class": "bg-light",
                        "outer-class": "bg-color-secondary"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                                modelValue: _ctx.value.lastName,
                                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((_ctx.value.lastName) = $event)),
                                modelModifiers: { trim: true },
                                disable: _ctx.isDisabled(),
                                outlined: "",
                                "stack-label": "",
                                "bg-color": "light",
                                "no-error-icon": "",
                                "lazy-rules": "",
                                "bottom-slots": "",
                                rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.lastNameRequired')]
                            }, null, 8, ["modelValue", "disable", "rules"])
                        ]),
                        _: 1
                    }, 8, ["label"])
                ])
            ]))
            : _createCommentVNode("", true),
        (!_ctx.isHidden())
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_LabelWrapper, {
                        label: _ctx.$t('contactDataForm.phone'),
                        "label-class": "text-primary",
                        "inner-class": "bg-light",
                        "outer-class": "bg-color-secondary"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                                modelValue: _ctx.value.phone,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.value.phone) = $event)),
                                modelModifiers: { trim: true },
                                disable: _ctx.isDisabled(),
                                type: "tel",
                                outlined: "",
                                "stack-label": "",
                                "bg-color": "light",
                                placeholder: _ctx.$t('contactDataForm.egPhone'),
                                "no-error-icon": "",
                                "lazy-rules": "",
                                "bottom-slots": "",
                                rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.phoneRequired')]
                            }, null, 8, ["modelValue", "disable", "placeholder", "rules"])
                        ]),
                        _: 1
                    }, 8, ["label"])
                ])
            ]))
            : _createCommentVNode("", true),
        (!_ctx.isHidden())
            ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_LabelWrapper, {
                        label: _ctx.$t('contactDataForm.email'),
                        "label-class": "text-primary",
                        "inner-class": "bg-light",
                        "outer-class": "bg-color-secondary"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_input, {
                                modelValue: _ctx.value.email,
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((_ctx.value.email) = $event)),
                                modelModifiers: { trim: true },
                                disable: _ctx.isDisabled(),
                                type: "email",
                                outlined: "",
                                "stack-label": "",
                                "bg-color": "light",
                                "no-error-icon": "",
                                "lazy-rules": "",
                                "bottom-slots": "",
                                rules: [
                                    (val) => _ctx.isValidEmail(val) || _ctx.$t('contactDataForm.incorrectEmail'),
                                    (val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.emailRequired'),
                                ]
                            }, null, 8, ["modelValue", "disable", "rules"])
                        ]),
                        _: 1
                    }, 8, ["label"])
                ])
            ]))
            : _createCommentVNode("", true)
    ], 64));
}
