import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6eba5863"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "q-ml-md" };
const _hoisted_2 = { class: "row bg-color-secondary" };
const _hoisted_3 = { class: "box-padding" };
const _hoisted_4 = { class: "q-pa-md" };
const _hoisted_5 = { class: "row items-stretch" };
const _hoisted_6 = { class: "col-grow col-5 q-pa-xs" };
const _hoisted_7 = { class: "col-auto q-px-lg q-pb-lg" };
const _hoisted_8 = { class: "col-grow col-5 q-pa-xs" };
const _hoisted_9 = {
    key: 0,
    class: "row"
};
const _hoisted_10 = { class: "column col-grow col-5 q-pa-xs" };
const _hoisted_11 = { class: "text-h5" };
const _hoisted_12 = { class: "col-auto q-px-lg q-pb-lg" };
const _hoisted_13 = { class: "col-grow col-5 q-pa-xs" };
const _hoisted_14 = { class: "q-px-xs" };
const _hoisted_15 = { class: "row" };
const _hoisted_16 = { class: "col col-12 border-light info-flex" };
const _hoisted_17 = { class: "q-pr-md q-pl-md text-justify right-data-warning" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DestinationTemplateDialog = _resolveComponent("DestinationTemplateDialog");
    const _component_AddressBookSearch = _resolveComponent("AddressBookSearch");
    const _component_RequestContactComponent = _resolveComponent("RequestContactComponent");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_RequestAddressComponent = _resolveComponent("RequestAddressComponent");
    const _component_q_space = _resolveComponent("q-space");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_card = _resolveComponent("q-card");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_DestinationTemplateDialog, {
            modelValue: _ctx.templateDialog,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.templateDialog) = $event)),
            contact: _ctx.requestContact,
            address: _ctx.requestAddress,
            type: _ctx.addressBookType
        }, null, 8, ["modelValue", "contact", "address", "type"]),
        (_openBlock(), _createBlock(_Teleport, { to: "#step-actions" }, [
            _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_AddressBookSearch, {
                    standalone: true,
                    type: _ctx.addressBookType,
                    onChange: _ctx.setSaved
                }, null, 8, ["type", "onChange"])
            ])
        ])),
        _createVNode(_component_q_card, null, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                        class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$q.screen.lt.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                        }, [
                            _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_q_form, { ref: "form" }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_4, [
                                            _createElementVNode("div", _hoisted_5, [
                                                _createElementVNode("div", _hoisted_6, [
                                                    _createVNode(_component_RequestContactComponent, {
                                                        value: _ctx.requestContact,
                                                        hasCostCenter: true
                                                    }, null, 8, ["value"])
                                                ]),
                                                _createElementVNode("div", _hoisted_7, [
                                                    _createVNode(_component_q_separator, {
                                                        vertical: "",
                                                        inset: "",
                                                        style: { "height": "100%" }
                                                    })
                                                ]),
                                                _createElementVNode("div", _hoisted_8, [
                                                    _createVNode(_component_RequestAddressComponent, { value: _ctx.requestAddress }, null, 8, ["value"])
                                                ])
                                            ]),
                                            (_ctx.differentCorrespondenceAddress)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                    _createElementVNode("div", _hoisted_10, [
                                                        _createElementVNode("div", null, [
                                                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("contactDataForm.correspondenceAddress")) + ":", 1)
                                                        ]),
                                                        _createVNode(_component_q_space),
                                                        _createElementVNode("div", null, [
                                                            _createVNode(_component_q_btn, {
                                                                class: "q-mr-sm",
                                                                color: "primary",
                                                                label: _ctx.$t('common.remove'),
                                                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.differentCorrespondenceAddress = false))
                                                            }, null, 8, ["label"])
                                                        ])
                                                    ]),
                                                    _createElementVNode("div", _hoisted_12, [
                                                        _createVNode(_component_q_separator, {
                                                            vertical: "",
                                                            inset: "",
                                                            style: { "height": "100%" }
                                                        })
                                                    ]),
                                                    _createElementVNode("div", _hoisted_13, [
                                                        _createVNode(_component_RequestAddressComponent, { value: _ctx.correspondenceAddress }, null, 8, ["value"])
                                                    ])
                                                ]))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_14, [
                                                _createElementVNode("div", _hoisted_15, [
                                                    _createElementVNode("div", _hoisted_16, [
                                                        _createElementVNode("div", null, [
                                                            _createVNode(_component_q_icon, {
                                                                name: "mdi-information-outline",
                                                                size: "md"
                                                            })
                                                        ]),
                                                        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("contactDataForm.fillRightDataWarning")), 1)
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ]),
                                    _: 1
                                }, 512)
                            ])
                        ], 2)
                    ], 2)
                ])
            ]),
            _: 1
        }),
        (_openBlock(), _createBlock(_Teleport, { to: "#footer-actions" }, [
            _createVNode(_component_q_btn, {
                class: "q-mr-sm text-weight-bold",
                color: "light",
                "text-color": "primary",
                "no-caps": "",
                label: _ctx.$t('contactDataForm.save'),
                onClick: _ctx.openTemplateDialog
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_q_btn, {
                class: "q-mr-sm text-weight-bold",
                color: "light",
                "text-color": "primary",
                "no-caps": "",
                label: _ctx.$t('contactDataForm.swapRecipients'),
                onClick: _ctx.swapRecipients
            }, null, 8, ["label", "onClick"]),
            (!_ctx.differentCorrespondenceAddress)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    class: "q-mr-sm text-weight-bold",
                    color: "light",
                    "text-color": "primary",
                    "no-caps": "",
                    label: _ctx.$t('contactDataForm.differentShipperAddress'),
                    onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.differentCorrespondenceAddress = true))
                }, null, 8, ["label"]))
                : _createCommentVNode("", true)
        ]))
    ], 64));
}
