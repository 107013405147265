var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { OrganizationClient, } from "@/api/client";
import moment from "moment";
import { notifyProblems } from "@/helper/notifyHelper";
import i18n from "@/i18n";
import { shipmentModule } from "@/store/modules/shipment";
const defaultState = () => {
    return {
        invoiceSettings: {},
        organizationSettings: {},
        organizationDetails: {},
    };
};
let SettingsModule = class SettingsModule extends VuexModule {
    _client = new OrganizationClient();
    state = defaultState();
    async fetchInvoiceSettings() {
        const response = await this._client.getInvoiceSettings();
        this.state.invoiceSettings = { id: response.id, settings: response.invoiceSettings };
    }
    async updateInvoiceSettings() {
        await this._client.updateInvoiceSettings(this.state.invoiceSettings);
    }
    async fetchOrganizationSettings() {
        const response = await this._client.getOrganizationSettings();
        this.state.organizationSettings = {
            timeFrom: moment(response.timeFrom, [moment.ISO_8601, "HH:mm"]).format("HH:mm"),
            timeTo: moment(response.timeTo, [moment.ISO_8601, "HH:mm"]).format("HH:mm"),
            motherlandCode: response.motherlandCode,
        };
    }
    async fetchActiveCouriers() {
        const response = await this._client.getActiveCouriers();
        this.state.activeCouriers = response.couriers;
    }
    async fetchBankAccounts() {
        const response = await this._client.getAllBankAccounts();
        return response.bankAccounts;
    }
    async updateOrganizationSettings(saveRequest) {
        try {
            const request = Object.assign({}, saveRequest);
            request.timeTo = moment(request.timeTo, [moment.ISO_8601, "HH:mm"]).format("HH:mm:ss");
            request.timeFrom = moment(request.timeFrom, [moment.ISO_8601, "HH:mm"]).format("HH:mm:ss");
            await this._client.updateOrganizationSettings(request);
            if (request.motherlandCode !== this.state.organizationSettings.motherlandCode) {
                shipmentModule.revaluateShipment();
            }
        }
        catch (error) {
            notifyProblems(i18n.global.t("exception.saveError"));
        }
        await this.fetchOrganizationSettings();
    }
    async fetchOrganizationDetails() {
        const response = await this._client.getOrganizationDetails();
        this.state.organizationDetails = response;
    }
    async getOrganizationDetails() {
        if (this.state.organizationDetails && this.state.organizationDetails.detailsExist) {
            return this.state.organizationDetails;
        }
        try {
            await this.fetchOrganizationDetails();
        }
        catch (error) {
            //
        }
        return this.state.organizationDetails;
    }
    async addUpdateOrganizationDetails(addUpdateRequest) {
        try {
            const request = Object.assign({}, addUpdateRequest);
            await this._client.updateOrganizationDetails(request);
        }
        catch (error) {
            notifyProblems(i18n.global.t("exception.saveError"));
        }
        await this.fetchOrganizationDetails();
    }
    async fetchCourierPickupTimes() {
        try {
            const response = await this._client.getCouriersPickupTimes();
            this.state.courierPickupsSettings = response.courierPickupTimes;
        }
        catch (error) {
            //
        }
    }
    async saveCourierPickupTime(pickupTime) {
        try {
            await this._client.setPickupTime(pickupTime);
        }
        catch (error) {
            notifyProblems(i18n.global.t("exception.saveError"));
        }
    }
    async fetchOrganizationBranches() {
        try {
            const result = await this._client.getOrganizationBranches();
            this.state.organizationBranches = result.branches;
        }
        catch (error) {
            //
        }
    }
    async fetchCostCenters() {
        try {
            const getCostCentersResponse = await this._client.getCostCenters();
            this.state.costCenters = getCostCentersResponse.costCenters;
        }
        catch (error) {
            //
        }
    }
    async createCostCenter(request) {
        try {
            const response = await this._client.createCostCenter(request);
            if (response.errors != undefined) {
                if (response.errors["code"] != undefined) {
                    notifyProblems(i18n.global.t("contactDataForm.costCenterExists"));
                }
                else
                    notifyProblems(i18n.global.t("exception.saveError"));
            }
            await this.fetchCostCenters();
        }
        catch (error) {
            notifyProblems(i18n.global.t("exception.saveError"));
        }
    }
    clear() {
        this.state = defaultState();
    }
};
__decorate([
    Action
], SettingsModule.prototype, "fetchInvoiceSettings", null);
__decorate([
    Action
], SettingsModule.prototype, "updateInvoiceSettings", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchOrganizationSettings", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchActiveCouriers", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchBankAccounts", null);
__decorate([
    Action
], SettingsModule.prototype, "updateOrganizationSettings", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchOrganizationDetails", null);
__decorate([
    Action
], SettingsModule.prototype, "getOrganizationDetails", null);
__decorate([
    Action
], SettingsModule.prototype, "addUpdateOrganizationDetails", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchCourierPickupTimes", null);
__decorate([
    Action
], SettingsModule.prototype, "saveCourierPickupTime", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchOrganizationBranches", null);
__decorate([
    Action
], SettingsModule.prototype, "fetchCostCenters", null);
__decorate([
    Action
], SettingsModule.prototype, "createCostCenter", null);
__decorate([
    Mutation
], SettingsModule.prototype, "clear", null);
SettingsModule = __decorate([
    Module
], SettingsModule);
export const settingsModule = new SettingsModule({ store, name: "settings" });
