import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    class: "absolute",
    style: { "top": "1em", "right": "1em" }
};
const _hoisted_2 = { class: "text-h6" };
const _hoisted_3 = {
    colspan: "5",
    class: "no-border no-padding"
};
const _hoisted_4 = { class: "row no-wrap items-center" };
const _hoisted_5 = { class: "text-left" };
const _hoisted_6 = { class: "text-left" };
const _hoisted_7 = { class: "text-left" };
const _hoisted_8 = { class: "text-left" };
const _hoisted_9 = { class: "text-left" };
const _hoisted_10 = { class: "q-tr--no-hover" };
const _hoisted_11 = { class: "text-left" };
const _hoisted_12 = { class: "text-left" };
const _hoisted_13 = { class: "text-left" };
const _hoisted_14 = { class: "text-left" };
const _hoisted_15 = { class: "text-left" };
const _hoisted_16 = {
    key: 0,
    class: "absolute-full",
    style: { "background": "rgba(0, 0, 0, 0.15)" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_card_section = _resolveComponent("q-card-section");
    const _component_q_input = _resolveComponent("q-input");
    const _component_q_markup_table = _resolveComponent("q-markup-table");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_spinner_oval = _resolveComponent("q-spinner-oval");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_dialog = _resolveComponent("q-dialog");
    return (_openBlock(), _createBlock(_component_q_dialog, {
        modelValue: _ctx.open,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.open) = $event)),
        persistent: ""
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                    _createVNode(_component_q_form, {
                        onReset: _ctx.reset,
                        onSubmit: _ctx.submit
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                        _createVNode(_component_q_btn, {
                                            round: "",
                                            size: "sm",
                                            color: "primary",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.open = false))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_icon, { name: "mdi-close" })
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("package.templateDialogTitle")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_card_section, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_markup_table, {
                                        class: "sizes-table border-light cursor-pointer",
                                        dense: "",
                                        flat: ""
                                    }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("thead", null, [
                                                _createElementVNode("tr", null, [
                                                    _createElementVNode("th", _hoisted_3, [
                                                        _createElementVNode("div", _hoisted_4, [
                                                            _createVNode(_component_q_input, {
                                                                modelValue: _ctx.description,
                                                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.description) = $event)),
                                                                square: "",
                                                                outlined: "",
                                                                label: _ctx.$t('package.templateName'),
                                                                "no-error-icon": "",
                                                                "lazy-rules": "",
                                                                class: "full-width",
                                                                rules: [(val) => _ctx.requiredField(val) || _ctx.$t('package.fillTemplateName')]
                                                            }, null, 8, ["modelValue", "label", "rules"])
                                                        ])
                                                    ])
                                                ]),
                                                _createElementVNode("tr", null, [
                                                    _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("package.length")), 1),
                                                    _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("package.width")), 1),
                                                    _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("package.height")), 1),
                                                    _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.$t("package.weight")), 1),
                                                    _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t("package.shape")), 1)
                                                ])
                                            ]),
                                            _createElementVNode("tbody", null, [
                                                _createElementVNode("tr", _hoisted_10, [
                                                    _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.length) + " cm", 1),
                                                    _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.width) + " cm", 1),
                                                    _createElementVNode("td", _hoisted_13, _toDisplayString(_ctx.height) + " cm", 1),
                                                    _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.weight) + " kg", 1),
                                                    _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.shape), 1)
                                                ])
                                            ])
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_card_section, { class: "float-right" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                        _createVNode(_component_q_btn, {
                                            type: "submit",
                                            color: "primary"
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("common.save")), 1)
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }, 8, ["onReset", "onSubmit"]),
                    (_ctx.loading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                            _createVNode(_component_q_spinner_oval, {
                                color: "primary",
                                size: "2em",
                                class: "absolute-center"
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modelValue"]));
}
