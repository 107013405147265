import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f02e64d2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    class: "content",
    style: { "height": "100%", "width": "100%" }
};
const _hoisted_2 = { class: "overflow-hidden rounded-borders" };
const _hoisted_3 = { class: "ellipsis" };
const _hoisted_4 = {
    class: "q-pa-sm text-primary",
    style: { "max-width": "300px", "width": "180px", "white-space": "normal" }
};
const _hoisted_5 = { class: "map-tooltip" };
const _hoisted_6 = { class: "tooltip-counter q-mt-md" };
const _hoisted_7 = { class: "text-preserve" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_l_control_zoom = _resolveComponent("l-control-zoom");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_select = _resolveComponent("q-select");
    const _component_l_control = _resolveComponent("l-control");
    const _component_q_btn_toggle = _resolveComponent("q-btn-toggle");
    const _component_l_tile_layer = _resolveComponent("l-tile-layer");
    const _component_l_marker = _resolveComponent("l-marker");
    const _component_l_tooltip = _resolveComponent("l-tooltip");
    const _component_l_map = _resolveComponent("l-map");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_l_map, {
            ref: "map",
            modelValue: _ctx.zoom,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.zoom) = $event)),
            zoom: _ctx.zoom,
            "onUpdate:zoom": _cache[3] || (_cache[3] = ($event) => ((_ctx.zoom) = $event)),
            center: _ctx.center,
            options: { zoomControl: false },
            class: "rounded-borders shadow-2"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_l_control_zoom, { position: "bottomright" }),
                _createVNode(_component_l_control, {
                    class: "search-control shadow-1",
                    style: { "max-width": "262px" },
                    position: "topleft"
                }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_q_select, {
                                modelValue: _ctx.selectedLocation,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.selectedLocation) = $event)),
                                class: "search-loc-input q-px-sm",
                                "input-style": "background-color:white",
                                "use-input": "",
                                clearable: "",
                                borderless: "",
                                dense: "",
                                "fill-input": "",
                                multiple: false,
                                style: { "max-width": "262px" },
                                options: _ctx.locationSearchOptions,
                                "input-debounce": 1000,
                                "option-label": "label",
                                "hide-selected": "",
                                label: _ctx.$t('reports.searchLocation'),
                                color: "primary",
                                "bg-color": "white",
                                standout: "bg-white text-primary",
                                onFilter: _ctx.filterLocations
                            }, {
                                prepend: _withCtx(() => [
                                    _createVNode(_component_q_icon, { name: "mdi-map-marker-outline" })
                                ]),
                                "selected-item": _withCtx((scope) => [
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(scope.opt.label), 1)
                                ]),
                                _: 1
                            }, 8, ["modelValue", "options", "label", "onFilter"])
                        ])
                    ]),
                    _: 1
                }),
                _createVNode(_component_l_control, {
                    class: "search-control",
                    position: "topright"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_btn_toggle, {
                            modelValue: _ctx.mapFilterOption,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.mapFilterOption) = $event)),
                            class: _normalizeClass(["rounded-borders bg-white", _ctx.displayGrid ? 'options-control-block' : '']),
                            options: _ctx.mapFilterOptions,
                            "toggle-color": "primary",
                            size: "sm",
                            "no-caps": ""
                        }, null, 8, ["modelValue", "class", "options"])
                    ]),
                    _: 1
                }),
                _createVNode(_component_l_tile_layer, {
                    url: _ctx.url,
                    attribution: _ctx.attribution
                }, null, 8, ["url", "attribution"]),
                (_ctx.selectedLocation && _ctx.selectedLocation !== '')
                    ? (_openBlock(), _createBlock(_component_l_marker, {
                        key: 0,
                        "lat-lng": [_ctx.selectedLocation.y, _ctx.selectedLocation.x]
                    }, null, 8, ["lat-lng"]))
                    : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCountries, (country) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                        key: country.mapData.country
                    }, [
                        (country.mapData.latitude && country.mapData.longitude)
                            ? (_openBlock(), _createBlock(_component_l_marker, {
                                key: 0,
                                class: _normalizeClass(`${country.mapData.country + 'Color'} text-green`),
                                "lat-lng": [country.mapData.latitude, country.mapData.longitude],
                                icon: _ctx.getDotIcon(country.mapData.country, Number.parseInt(_ctx.toPercent(country.deliveriesCount, country.punctualDeliveriesCount))),
                                onClick: ($event) => (_ctx.location = country.mapData.country)
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_l_tooltip, null, {
                                        default: _withCtx(() => [
                                            _createElementVNode("div", _hoisted_4, [
                                                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.isDelivery
                                                    ? _ctx.$t("reports.averagePackageShipmentPunctuality")
                                                    : _ctx.$t("reports.averagePackagePickupPunctuality")), 1),
                                                _createElementVNode("div", _hoisted_6, [
                                                    _createElementVNode("div", null, _toDisplayString(_ctx.countryI18N?.of(country.mapData.country)), 1),
                                                    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.toPercent(country.deliveriesCount, country.punctualDeliveriesCount)) + " % ", 1)
                                                ])
                                            ])
                                        ]),
                                        _: 2
                                    }, 1024)
                                ]),
                                _: 2
                            }, 1032, ["class", "lat-lng", "icon", "onClick"]))
                            : _createCommentVNode("", true)
                    ], 64));
                }), 128))
            ]),
            _: 1
        }, 8, ["modelValue", "zoom", "center"])
    ]));
}
