import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-529d4849"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "wrapper" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col col-12 q-pa-sm" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "col col-6 q-pa-sm" };
const _hoisted_6 = { class: "col col-6 q-pa-sm" };
const _hoisted_7 = { class: "row" };
const _hoisted_8 = { class: "col col-4 q-pa-sm" };
const _hoisted_9 = { class: "col col-8 q-pa-sm" };
const _hoisted_10 = { class: "row" };
const _hoisted_11 = { class: "col col-12 q-pa-sm" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col col-12 q-pa-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_CountrySelect = _resolveComponent("CountrySelect");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('contactDataForm.street'),
                    "label-class": "text-primary",
                    "inner-class": "bg-light",
                    "outer-class": "bg-color-secondary"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.street,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.value.street) = $event)),
                            modelModifiers: { trim: true },
                            outlined: "",
                            "bg-color": "light",
                            placeholder: _ctx.$t('contactDataForm.egStreet'),
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.streetRequired')]
                        }, null, 8, ["modelValue", "placeholder", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('contactDataForm.streetNumber'),
                    "label-class": "text-primary",
                    "inner-class": "bg-light",
                    "outer-class": "bg-color-secondary"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.streetNumber,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.value.streetNumber) = $event)),
                            modelModifiers: { trim: true },
                            outlined: "",
                            "bg-color": "light",
                            placeholder: _ctx.$t('contactDataForm.egStreetNumber'),
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.streetNumberRequired')]
                        }, null, 8, ["modelValue", "placeholder", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('contactDataForm.localeNumber'),
                    "label-class": "text-primary",
                    "inner-class": "bg-light",
                    "outer-class": "bg-color-secondary"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.localNumber,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.value.localNumber) = $event)),
                            modelModifiers: { trim: true },
                            outlined: "",
                            "bg-color": "light",
                            placeholder: _ctx.$t('contactDataForm.egLocalNumber'),
                            "no-error-icon": "",
                            hint: `(${_ctx.$t('common.optional')})`
                        }, null, 8, ["modelValue", "placeholder", "hint"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('contactDataForm.postalCode'),
                    "label-class": "text-primary",
                    "inner-class": "bg-light",
                    "outer-class": "bg-color-secondary"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.postalCode,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.value.postalCode) = $event)),
                            modelModifiers: { trim: true },
                            outlined: "",
                            "bg-color": "light",
                            placeholder: _ctx.$t('contactDataForm.egPostalCode'),
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.postalCodeRequired')]
                        }, null, 8, ["modelValue", "placeholder", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ]),
            _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('contactDataForm.city'),
                    "label-class": "text-primary",
                    "inner-class": "bg-light",
                    "outer-class": "bg-color-secondary"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.city,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.value.city) = $event)),
                            modelModifiers: { trim: true },
                            outlined: "",
                            "bg-color": "light",
                            placeholder: _ctx.$t('contactDataForm.egCity'),
                            "no-error-icon": "",
                            "lazy-rules": "",
                            rules: [(val) => _ctx.requiredField(val) || _ctx.$t('contactDataForm.cityRequired')]
                        }, null, 8, ["modelValue", "placeholder", "rules"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ]),
        _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_CountrySelect, {
                    modelValue: _ctx.value.countryCode,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.value.countryCode) = $event))
                }, null, 8, ["modelValue"])
            ])
        ]),
        _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_LabelWrapper, {
                    label: _ctx.$t('contactDataForm.additionalNotes'),
                    "label-class": "text-primary",
                    "inner-class": "bg-light",
                    "outer-class": "bg-color-secondary"
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_input, {
                            modelValue: _ctx.value.additionalNotes,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.value.additionalNotes) = $event)),
                            modelModifiers: { trim: true },
                            outlined: "",
                            "bg-color": "light",
                            "no-error-icon": "",
                            "lazy-rules": "",
                            hint: `(${_ctx.$t('common.optional')})`
                        }, null, 8, ["modelValue", "hint"])
                    ]),
                    _: 1
                }, 8, ["label"])
            ])
        ])
    ]));
}
