import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_popup_proxy = _resolveComponent("q-popup-proxy");
    return (_openBlock(), _createBlock(_component_q_popup_proxy, {
        ref: "menu",
        modelValue: _ctx.dataModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.dataModelValue) = $event)),
        offset: _ctx.menuOffset,
        target: _ctx.target,
        anchor: _ctx.anchorOrigin,
        self: _ctx.selfOrigin,
        class: _normalizeClass(_ctx.menuClass),
        fit: _ctx.fit,
        "no-parent-event": _ctx.skipMenuEvents,
        breakpoint: _ctx.breakpoint
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", {
                class: _normalizeClass(_ctx.caretClass()),
                style: _normalizeStyle(_ctx.caretStyle)
            }, null, 6),
            _createElementVNode("div", {
                ref: "root",
                class: _normalizeClass(_ctx.rootClass)
            }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 2)
        ]),
        _: 3
    }, 8, ["modelValue", "offset", "target", "anchor", "self", "class", "fit", "no-parent-event", "breakpoint"]));
}
