import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "wrapper" };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col col-12 q-pa-sm" };
const _hoisted_4 = { class: "col col-12 q-pa-sm" };
const _hoisted_5 = { class: "col col-12 q-pa-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_ButtonDialog = _resolveComponent("ButtonDialog");
    return (_openBlock(), _createBlock(_component_ButtonDialog, {
        action: _ctx.update,
        clear: _ctx.clearForm,
        title: "changePasswordForm.changePassword",
        label: "common.setup"
    }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_LabelWrapper, {
                            label: _ctx.$t('changePasswordForm.currentPassword'),
                            "label-class": "text-primary",
                            "inner-class": "bg-light",
                            "outer-class": "bg-white"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_input, {
                                    modelValue: _ctx.passwordRequest.currentPassword,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.passwordRequest.currentPassword) = $event)),
                                    outlined: "",
                                    type: "password",
                                    "stack-label": "",
                                    "bg-color": "light",
                                    "no-error-icon": "",
                                    "lazy-rules": "",
                                    "bottom-slots": "",
                                    error: _ctx.showError,
                                    "error-message": _ctx.error,
                                    rules: [(val) => _ctx.requiredField(val) || _ctx.$t('changePasswordForm.currentPasswordRequired')]
                                }, null, 8, ["modelValue", "error", "error-message", "rules"])
                            ]),
                            _: 1
                        }, 8, ["label"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_LabelWrapper, {
                            label: _ctx.$t('changePasswordForm.newPassword'),
                            "label-class": "text-primary",
                            "inner-class": "bg-light",
                            "outer-class": "bg-white"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_input, {
                                    modelValue: _ctx.passwordRequest.password,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.passwordRequest.password) = $event)),
                                    outlined: "",
                                    ref: "refPwd",
                                    "stack-label": "",
                                    type: "password",
                                    "bg-color": "light",
                                    "no-error-icon": "",
                                    "lazy-rules": "",
                                    "bottom-slots": "",
                                    onBlur: _cache[2] || (_cache[2] =
                                        () => {
                                            if (_ctx.$refs.refPwdRpt.modelValue) {
                                                _ctx.$refs.refPwdRpt.resetValidation();
                                                _ctx.$refs.refPwdRpt.validate();
                                            }
                                            if (_ctx.$refs.refPwd.modelValue) {
                                                _ctx.$refs.refPwd.resetValidation();
                                                _ctx.$refs.refPwd.validate();
                                            }
                                        }),
                                    rules: [
                                        (val) => _ctx.requiredField(val) || _ctx.$t('changePasswordForm.newPasswordRequired'),
                                        (val) => (val.length >= 8 && val.length <= 64) || _ctx.$t('changePasswordForm.incorrectLength'),
                                        (val) => [...val].some((a) => isNaN(a) && a === a.toUpperCase()) ||
                                            _ctx.$t('changePasswordForm.atLeastOneUpperCase'),
                                        (val) => [...val].some((a) => isNaN(a) && a === a.toLowerCase()) ||
                                            _ctx.$t('changePasswordForm.atLeastOneLowerCase'),
                                    ]
                                }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                        }, 8, ["label"])
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_LabelWrapper, {
                            label: _ctx.$t('changePasswordForm.repeatPassword'),
                            "label-class": "text-primary",
                            "inner-class": "bg-light",
                            "outer-class": "bg-white"
                        }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_input, {
                                    modelValue: _ctx.passwordRequest.repeatPassword,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.passwordRequest.repeatPassword) = $event)),
                                    outlined: "",
                                    ref: "refPwdRpt",
                                    type: "password",
                                    "stack-label": "",
                                    onBlur: _cache[4] || (_cache[4] =
                                        () => {
                                            if (_ctx.$refs.refPwd.modelValue) {
                                                _ctx.$refs.refPwd.resetValidation();
                                                _ctx.$refs.refPwd.validate();
                                            }
                                            if (_ctx.$refs.refPwdRpt.modelValue) {
                                                _ctx.$refs.refPwdRpt.resetValidation();
                                                _ctx.$refs.refPwdRpt.validate();
                                            }
                                        }),
                                    "bg-color": "light",
                                    "no-error-icon": "",
                                    "lazy-rules": "",
                                    "bottom-slots": "",
                                    rules: [
                                        (val) => _ctx.requiredField(val) || _ctx.$t('changePasswordForm.repeatPasswordRequired'),
                                        (val) => _ctx.checkRepeat(val, _ctx.passwordRequest.password) || _ctx.$t('changePasswordForm.passwordDifferent'),
                                    ]
                                }, null, 8, ["modelValue", "rules"])
                            ]),
                            _: 1
                        }, 8, ["label"])
                    ])
                ])
            ])
        ]),
        _: 1
    }, 8, ["action", "clear"]));
}
