import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dialog = _resolveComponent("Dialog");
    const _component_q_btn = _resolveComponent("q-btn");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_Dialog, {
            ref: "dialog",
            "close-on-submit": _ctx.closeOnSubmit,
            title: _ctx.title,
            action: _ctx.action,
            clear: _ctx.clear,
            onFormClosed: _cache[0] || (_cache[0] = ($event) => (_ctx.formClosed())),
            hideSaveBtn: _ctx.hideSaveBtn
        }, {
            default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
        }, 8, ["close-on-submit", "title", "action", "clear", "hideSaveBtn"]),
        _createVNode(_component_q_btn, _mergeProps(_ctx.$attrs, {
            loading: _ctx.loading,
            disabled: _ctx.loading,
            color: "primary",
            onClick: _ctx.open
        }), {
            default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(_ctx.label)), 1)
            ]),
            _: 1
        }, 16, ["loading", "disabled", "onClick"])
    ]));
}
