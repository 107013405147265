import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-70e04ce6"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "service-type full-width" };
const _hoisted_2 = { class: "service-type__checkbox" };
const _hoisted_3 = { class: "service-type__content q-pt-sm q-pa-xs" };
const _hoisted_4 = {
    key: 0,
    class: "service-type__content__label"
};
const _hoisted_5 = {
    key: 1,
    class: "service-type__content__container q-mt-xs"
};
const _hoisted_6 = {
    key: 2,
    class: "service-type__content__container q-mt-xs"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_checkbox = _resolveComponent("q-checkbox");
    const _component_q_input = _resolveComponent("q-input");
    const _component_LabelWrapper = _resolveComponent("LabelWrapper");
    const _component_q_form = _resolveComponent("q-form");
    return (_openBlock(), _createBlock(_component_q_form, { ref: "form" }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_q_checkbox, {
                        modelValue: _ctx.value.state,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.value.state) = $event)),
                        onClick: _ctx.resetValidation,
                        disable: _ctx.value.disabled
                    }, null, 8, ["modelValue", "onClick", "disable"])
                ]),
                _createElementVNode("div", _hoisted_3, [
                    (!_ctx.showValueInput)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("span", {
                                class: _normalizeClass(["text-primary", { disabled: _ctx.value.disabled }])
                            }, _toDisplayString(_ctx.$t("shipmentService." + _ctx.firstLetterLower(_ctx.value.service))), 3)
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.showValueInput)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_LabelWrapper, {
                                label: _ctx.$t('shipmentService.' + _ctx.firstLetterLower(_ctx.value.service)),
                                "label-class": "text-primary",
                                "inner-class": "bg-light",
                                "outer-class": "bg-light"
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_input, {
                                        modelValue: _ctx.value.value,
                                        "onUpdate:modelValue": [
                                            _cache[1] || (_cache[1] = ($event) => ((_ctx.value.value) = $event)),
                                            _ctx.onChange
                                        ],
                                        type: "number",
                                        class: "input-border",
                                        suffix: _ctx.currency,
                                        "stack-label": "",
                                        outlined: "",
                                        "bg-color": "light",
                                        "no-error-icon": "",
                                        "lazy-rules": "",
                                        "bottom-slots": "",
                                        rules: [(val) => !_ctx.value.state || (val && val > 0) || _ctx.$t('additionalService.requiredField')]
                                    }, null, 8, ["modelValue", "suffix", "onUpdate:modelValue", "rules"])
                                ]),
                                _: 1
                            }, 8, ["label"])
                        ]))
                        : _createCommentVNode("", true),
                    (this.$props.showAdditionalInput)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _renderSlot(_ctx.$slots, "additionalData", {}, undefined, true)
                        ]))
                        : _createCommentVNode("", true)
                ])
            ])
        ]),
        _: 3
    }, 512));
}
