import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f15df03c"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "column" };
const _hoisted_2 = { class: "row items-stretch" };
const _hoisted_3 = { style: { "white-space": "pre" } };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Dialog = _resolveComponent("Dialog");
    const _component_PackageSize = _resolveComponent("PackageSize");
    const _component_EditorDialog = _resolveComponent("EditorDialog");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_menu = _resolveComponent("q-menu");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_MainComponent = _resolveComponent("MainComponent");
    const _component_q_page = _resolveComponent("q-page");
    const _directive_close_popup = _resolveDirective("close-popup");
    return (_openBlock(), _createBlock(_component_q_page, null, {
        default: _withCtx(() => [
            _createVNode(_component_MainComponent, null, {
                default: _withCtx(() => [
                    _createVNode(_component_Dialog, {
                        ref: "deleteMultipleSizesDialog",
                        title: "dataTable.packageSize.actions.deleteMultipleTitle",
                        action: _ctx.deleteMultipleConfirm,
                        "submit-label": "common.confirm"
                    }, {
                        default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("dataTable.packageSize.actions.deleteMultipleMessage")), 1)
                        ]),
                        _: 1
                    }, 8, ["action"]),
                    _createVNode(_component_EditorDialog, {
                        modelValue: _ctx.entry,
                        "onUpdate:modelValue": [
                            _cache[0] || (_cache[0] = ($event) => ((_ctx.entry) = $event)),
                            _ctx.processEntry
                        ],
                        open: _ctx.editorOpen,
                        "onUpdate:open": _cache[1] || (_cache[1] = ($event) => ((_ctx.editorOpen) = $event)),
                        mode: _ctx.editorMode,
                        "dialog-class": "editor-dialog",
                        "label-entry": _ctx.entryLabel,
                        "label-entry-type": _ctx.$t('dataTable.packageSize.editorEntryType'),
                        "label-new": _ctx.$t('dataTable.packageSize.editorNew'),
                        "model-builder": _ctx.makeEntry,
                        "has-model-errors": _ctx.hasModelErrors,
                        processing: _ctx.processing
                    }, {
                        editor: _withCtx((scope) => [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("div", _hoisted_2, [
                                    _createVNode(_component_PackageSize, {
                                        ref: "packageSize",
                                        value: scope.value
                                    }, null, 8, ["value"])
                                ])
                            ])
                        ]),
                        _: 1
                    }, 8, ["modelValue", "open", "mode", "label-entry", "label-entry-type", "label-new", "model-builder", "has-model-errors", "processing", "onUpdate:modelValue"]),
                    _createVNode(_component_DataTable, {
                        ref: "dataTable",
                        pagination: _ctx.pagination,
                        "onUpdate:pagination": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                        "data-type": "packageSize",
                        "editor-class": "editor-dialog",
                        "selection-column": "id",
                        loading: _ctx.loading,
                        "has-model-errors": _ctx.hasModelErrors,
                        entries: _ctx.entries,
                        columns: _ctx.columns,
                        filters: _ctx.filters,
                        "model-builder": _ctx.makeEntry,
                        onFetch: _ctx.fetchEntries,
                        onCreate: _ctx.createEntry
                    }, {
                        actions: _withCtx(() => [
                            _createVNode(_component_q_btn, {
                                class: "bg-accent text-white",
                                onClick: _ctx.addNewEntry
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        class: "q-mr-md",
                                        name: "mdi-plus-circle",
                                        color: "white"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.packageSize.actions.add")), 1)
                                ]),
                                _: 1
                            }, 8, ["onClick"])
                        ]),
                        "group-actions": _withCtx((props) => [
                            _createVNode(_component_q_btn, {
                                flat: "",
                                "no-caps": "",
                                onClick: ($event) => (_ctx.deleteMultiple(props.selection))
                            }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        class: "q-mr-md",
                                        name: "mdi-delete-sweep",
                                        color: "primary"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t("dataTable.packageSize.actions.deleteMultiple")), 1)
                                ]),
                                _: 2
                            }, 1032, ["onClick"])
                        ]),
                        "body-cell": _withCtx((cell) => [
                            _createElementVNode("div", _hoisted_3, _toDisplayString(cell.value), 1)
                        ]),
                        "body-cell-actions": _withCtx((cell) => [
                            _createVNode(_component_q_btn, { flat: "" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, { name: "mdi-dots-vertical" }),
                                    _createVNode(_component_q_menu, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_list, null, {
                                                default: _withCtx(() => [
                                                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                        clickable: "",
                                                        onClick: ($event) => (_ctx.editEntry(cell.row.entry))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_q_item_section, null, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t("dataTable.packageSize.actions.edit")), 1)
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["onClick"])), [
                                                        [_directive_close_popup]
                                                    ]),
                                                    _createVNode(_component_q_separator),
                                                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                                                        clickable: "",
                                                        onClick: ($event) => (_ctx.deleteEntry(cell.row.entry))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_q_item_section, null, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.$t("dataTable.packageSize.actions.delete")), 1)
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["onClick"])), [
                                                        [_directive_close_popup]
                                                    ])
                                                ]),
                                                _: 2
                                            }, 1024)
                                        ]),
                                        _: 2
                                    }, 1024)
                                ]),
                                _: 2
                            }, 1024)
                        ]),
                        _: 1
                    }, 8, ["pagination", "loading", "has-model-errors", "entries", "columns", "filters", "model-builder", "onFetch", "onCreate"])
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
