export var ShipmentStep;
(function (ShipmentStep) {
    ShipmentStep["Package"] = "package";
    ShipmentStep["Recipient"] = "recipient";
    ShipmentStep["Shipper"] = "shipper";
    ShipmentStep["Customs"] = "customs";
    ShipmentStep["Valuation"] = "valuation";
    ShipmentStep["Summary"] = "summary";
})(ShipmentStep || (ShipmentStep = {}));
// NOTE: label/stepName should contain i18n paths instead
export const steps = [
    {
        id: 1,
        label: ShipmentStep.Package,
        component: "PackagesData",
        stepName: ShipmentStep.Package,
        paramName: ShipmentStep.Package,
    },
    {
        id: 2,
        label: ShipmentStep.Recipient,
        component: "RecipientData",
        stepName: ShipmentStep.Recipient,
        paramName: ShipmentStep.Recipient,
    },
    {
        id: 3,
        label: ShipmentStep.Shipper,
        component: "ShipperData",
        stepName: ShipmentStep.Shipper,
        paramName: ShipmentStep.Shipper,
    },
    {
        id: 4,
        label: ShipmentStep.Customs,
        component: "CustomsData",
        stepName: ShipmentStep.Customs,
        paramName: ShipmentStep.Customs,
    },
    {
        id: 5,
        label: ShipmentStep.Valuation + "Full",
        component: "ShipmentValuation",
        stepName: ShipmentStep.Valuation,
        paramName: ShipmentStep.Valuation,
    },
    {
        id: 6,
        label: ShipmentStep.Summary + "Full",
        component: "Summary",
        stepName: ShipmentStep.Summary,
        paramName: ShipmentStep.Summary,
    },
];
