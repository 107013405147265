import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_input = _resolveComponent("q-input");
    const _component_q_btn_group = _resolveComponent("q-btn-group");
    const _component_q_field = _resolveComponent("q-field");
    return (_openBlock(), _createBlock(_component_q_field, {
        outlined: "",
        dense: "",
        class: "number-input"
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_btn_group, { flat: "" }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_btn, {
                        dense: "",
                        flat: "",
                        icon: "remove",
                        class: "number-btn bg-light",
                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.numberSub(_ctx.model)))
                    }),
                    _createVNode(_component_q_input, {
                        "model-value": _ctx.modelInput,
                        class: "input number-input",
                        "input-class": "text-center",
                        type: "number",
                        borderless: "",
                        dense: "",
                        onChange: _cache[1] || (_cache[1] = ($event) => (_ctx.modelInput = $event))
                    }, null, 8, ["model-value"]),
                    _createVNode(_component_q_btn, {
                        dense: "",
                        flat: "",
                        icon: "add",
                        class: "number-btn bg-light",
                        onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.numberAdd(_ctx.model)))
                    })
                ]),
                _: 1
            })
        ]),
        _: 1
    }));
}
