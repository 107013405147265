import { ECourierErrorType } from "@/api/client";
import Notify from 'quasar/src/plugins/Notify.js';
;
import i18n from "@/i18n";
export const notifyCourierProblems = (details) => {
    const maxErrorsVisible = 5;
    const subtype = details.extensions["subtype"];
    const courier = details.extensions["courier"];
    let courierName = courier?.toLowerCase() ?? "generic";
    if (courierName && courierName.indexOf("furgonetka") >= 0)
        courierName = "furgonetka";
    const basePath = ["exception", courierName].filter((e) => e).join(".");
    if (subtype == ECourierErrorType.Validation) {
        const validationDetails = details;
        const errorPaths = Object.keys(validationDetails.errors);
        const errorLines = [];
        // unique errors only variant
        // NOTE: certain errors are too generic to be handled like this
        // (ie. Furgonetka issue with points - code: 'invalid', path: '/pickup/point')
        const uniqueErrors = [...new Set(errorPaths.flatMap((v) => validationDetails.errors[v]))];
        errorLines.push(...uniqueErrors.slice(0, maxErrorsVisible).map((v) => i18n.global.t(`${basePath}.${v}`)));
        if (uniqueErrors.length > maxErrorsVisible) {
            errorLines.push(`+ ${uniqueErrors.length - maxErrorsVisible} ${i18n.global.t("exception.remainingErrors")}`);
        }
        return notifyProblems(errorLines.join("<br/>"), true);
        // errors with paths variant
        /*
          let visibleErrorCount = 0;
          let totalErrorCount = 0;
          for (let i = 0; i < errorPaths.length; i++){
            let path = errorPaths[i];
            let errors = validationDetails.errors[path];
            if(visibleErrorCount < maxErrorsVisible){
              errorLines.push(`${_.escape(path)}:`);
              errors.forEach(v => {
                visibleErrorCount++;
                totalErrorCount++;
                errorLines.push("- " + i18n.global.t(`${basePath}.${v}`));
              });
            }else {
              totalErrorCount += errors.length;
            }
          }
          if(totalErrorCount > visibleErrorCount){
            errorLines.push(`+ ${totalErrorCount-visibleErrorCount} ${i18n.global.t("exception.remainingErrors")}`);
          }
          // possible TODO: filter errors without translation, unify & make paths human-readable
          return notifyProblems(errorLines.join("<br/>"), true, true);
          */
    }
    if (subtype == ECourierErrorType.Request) {
        const errorCode = details.extensions["code"];
        if (errorCode)
            return notifyProblems(i18n.global.t(`${basePath}.${errorCode}`), false);
        else
            return notifyProblems(i18n.global.t("exception.unknownError"));
    }
    return notifyProblems(i18n.global.t("exception.unknownError"));
};
export const notifyProblems = (value, asHtml = false, persistent = false) => {
    console.log(value);
    const notifyOptions = {
        type: "negative",
        message: typeof value === "string" ? value : "",
        actions: [{ icon: "close", color: "white" }],
        html: typeof value === "string" && asHtml,
        timeout: persistent ? 0 : undefined,
    };
    if (value instanceof Object) {
        notifyOptions.message = JSON.stringify(value);
    }
    return Notify.create(notifyOptions);
};
export const notifyWarning = (value) => {
    console.log(value);
    const notifyOptions = {
        type: "warning",
        message: value ? i18n.global.t(value) : "",
        actions: [{ icon: "close", color: "white" }],
        color: "primary",
        textColor: "white",
        timeout: 10000,
    };
    return Notify.create(notifyOptions);
};
/** NOTE: do NOT run with ANY user-defined data, as this is XSS vulnerable method*/
export const notifyImportant = (options) => {
    console.log(options.body);
    return Notify.create({
        attrs: {
            role: "alertdialog",
        },
        timeout: options.timeout,
        group: options.group ?? false,
        message: "<div style='padding:2px 0 0 15px;'><div class='notification-title'>" +
            options.title +
            "</div>" +
            "<div class='notification-content'>" +
            options.body +
            "</div></div>",
        html: true,
        classes: "q-pa-lg notification-main",
        color: "primary",
        icon: "warning",
        iconColor: "yellow",
        iconSize: "lg",
        actions: [
            {
                icon: "close",
                "aria-label": "Dismiss",
                color: "white",
            },
        ],
    });
};
export const notifyInfo = (options) => {
    console.log(options.body);
    return Notify.create({
        attrs: {
            role: "alertdialog",
        },
        timeout: options.timeout,
        group: options.group ?? false,
        message: "<div style='padding:2px 0 0 15px;'><div class='notification-title'>" +
            options.title +
            "</div>" +
            "<div class='notification-content'>" +
            options.body +
            "</div></div>",
        html: true,
        classes: "q-pa-lg notification-main",
        color: "primary",
        icon: "info",
        iconColor: "white",
        iconSize: "lg",
        actions: [
            {
                icon: "close",
                "aria-label": "Dismiss",
                color: "white",
            },
        ],
    });
};
