import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    class: "absolute",
    style: { "top": "1em", "right": "1em" }
};
const _hoisted_2 = { class: "text-h6" };
const _hoisted_3 = { class: "no-border text-left" };
const _hoisted_4 = { class: "row" };
const _hoisted_5 = { class: "text-h6" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { class: "text-left" };
const _hoisted_8 = { class: "text-left" };
const _hoisted_9 = { class: "text-left" };
const _hoisted_10 = { key: 1 };
const _hoisted_11 = { class: "text-left" };
const _hoisted_12 = {
    key: 0,
    class: "absolute-full",
    style: { "background": "rgba(0, 0, 0, 0.15)" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_card_section = _resolveComponent("q-card-section");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_markup_table = _resolveComponent("q-markup-table");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_spinner_oval = _resolveComponent("q-spinner-oval");
    const _component_q_dialog = _resolveComponent("q-dialog");
    return (_openBlock(), _createBlock(_component_q_dialog, {
        modelValue: _ctx.open,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.open) = $event)),
        persistent: ""
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, { style: { "min-width": "600px" } }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_form, {
                        onReset: _ctx.reset,
                        onSubmit: _ctx.submit
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                        _createVNode(_component_q_btn, {
                                            round: "",
                                            size: "sm",
                                            color: "primary",
                                            onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.open = false))
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_icon, { name: "mdi-close" })
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("contactDataForm.templateDialogTitle")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_card_section, { class: "q-px-lg q-py-sm" }, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_card, {
                                        flat: "",
                                        bordered: ""
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_markup_table, {
                                                class: "sizes-table border-light q-pa-xs cursor-pointer",
                                                dense: "",
                                                flat: ""
                                            }, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("thead", null, [
                                                        _createElementVNode("tr", null, [
                                                            _createElementVNode("th", _hoisted_3, [
                                                                _createElementVNode("div", _hoisted_4, [
                                                                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getMainName(_ctx.contact)), 1)
                                                                ])
                                                            ])
                                                        ])
                                                    ]),
                                                    _createElementVNode("tbody", null, [
                                                        (_ctx.contact.nip)
                                                            ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                                                                _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("common.nip")) + ": " + _toDisplayString(_ctx.contact.nip), 1)
                                                            ]))
                                                            : _createCommentVNode("", true),
                                                        _createElementVNode("tr", null, [
                                                            _createElementVNode("th", _hoisted_8, _toDisplayString(_ctx.contact.firstName) + " " + _toDisplayString(_ctx.contact.lastName) + ", " + _toDisplayString(_ctx.contact.phone), 1)
                                                        ]),
                                                        _createElementVNode("tr", null, [
                                                            _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.address.street) + " " + _toDisplayString(_ctx.address.streetNumber) + _toDisplayString(!!_ctx.address.localNumber ? "/" + _ctx.address.localNumber : "") + ", " + _toDisplayString(_ctx.address.postalCode) + " " + _toDisplayString(_ctx.address.city), 1)
                                                        ]),
                                                        (!!_ctx.address.additionalNotes)
                                                            ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                                                                _createElementVNode("th", _hoisted_11, [
                                                                    _createVNode(_component_q_separator, { class: "q-my-sm" }),
                                                                    _createTextVNode(" " + _toDisplayString(_ctx.address.additionalNotes), 1)
                                                                ])
                                                            ]))
                                                            : _createCommentVNode("", true)
                                                    ])
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_card_section, { class: "float-right" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                        _createVNode(_component_q_btn, {
                                            type: "submit",
                                            color: "primary"
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t("common.save")), 1)
                                            ]),
                                            _: 1
                                        })
                                    ])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    }, 8, ["onReset", "onSubmit"]),
                    (_ctx.loading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createVNode(_component_q_spinner_oval, {
                                color: "primary",
                                size: "2em",
                                class: "absolute-center"
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modelValue"]));
}
