import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-bad85834"), n = n(), _popScopeId(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "row content__main bg-color-secondary" };
const _hoisted_3 = { class: "box-padding" };
const _hoisted_4 = {
    key: 0,
    class: "row bg-color-secondary",
    style: { "height": "100%", "width": "100%" }
};
const _hoisted_5 = { class: "carousel-wrapper bg-color-secondary" };
const _hoisted_6 = { class: "row" };
const _hoisted_7 = { class: "col col-7 q-pa-xs" };
const _hoisted_8 = { class: "col col-5 q-py-xs q-px-md" };
const _hoisted_9 = {
    key: 0,
    class: "row q-col-gutter-lg"
};
const _hoisted_10 = { class: "col col-12" };
const _hoisted_11 = { class: "col col-12 q-py-xs" };
const _hoisted_12 = { class: "row" };
const _hoisted_13 = { class: "col col-9" };
const _hoisted_14 = { class: "alert-brutto-price" };
const _hoisted_15 = { class: "q-pl-md text-justify text-primary" };
const _hoisted_16 = { class: "col col-3" };
const _hoisted_17 = {
    class: "",
    style: { "float": "right" }
};
const _hoisted_18 = {
    key: 1,
    class: "row"
};
const _hoisted_19 = { class: "col col-9" };
const _hoisted_20 = { class: "alert-brutto-price" };
const _hoisted_21 = { class: "q-pr-md q-pl-md text-justify text-primary" };
const _hoisted_22 = { class: "col col-3" };
const _hoisted_23 = {
    class: "",
    style: { "float": "right" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_CourierIcon = _resolveComponent("CourierIcon");
    const _component_q_carousel_slide = _resolveComponent("q-carousel-slide");
    const _component_q_carousel = _resolveComponent("q-carousel");
    const _component_AdditionalService = _resolveComponent("AdditionalService");
    const _component_ShipmentValuationServices = _resolveComponent("ShipmentValuationServices");
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_inner_loading = _resolveComponent("q-inner-loading");
    const _component_q_card = _resolveComponent("q-card");
    return (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_q_card, null, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", {
                            class: _normalizeClass(["col col-12", _ctx.$q.screen.gt.sm ? 'box-padding-lg' : 'box-padding-sm'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$q.screen.lg.sm ? 'data-view__main-data' : 'data-view__main-data-sm')
                            }, [
                                _createElementVNode("div", _hoisted_3, [
                                    _createVNode(_component_q_form, {
                                        class: "form q-pa-xs",
                                        ref: "form"
                                    }, {
                                        default: _withCtx(() => [
                                            ((_ctx.ranking && _ctx.ranking.length > 0) || !_ctx.loading)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                                    _createVNode(_component_q_carousel, {
                                                        modelValue: _ctx.carouselPosition,
                                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.carouselPosition) = $event)),
                                                        "transition-prev": "slide-right",
                                                        "transition-next": "slide-left",
                                                        swipeable: "",
                                                        animated: "",
                                                        "control-color": "primary",
                                                        "control-type": "regular",
                                                        padding: "",
                                                        arrows: "",
                                                        height: "170px",
                                                        class: "rounded-borders full-width col-12"
                                                    }, {
                                                        default: _withCtx(() => [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Math.ceil(_ctx.ranking.length / _ctx.itemsPerCarouselPage), (r, index) => {
                                                                return (_openBlock(), _createBlock(_component_q_carousel_slide, {
                                                                    key: index,
                                                                    name: Math.ceil((index * _ctx.itemsPerCarouselPage + 1) / _ctx.itemsPerCarouselPage)
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createElementVNode("div", _hoisted_5, [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ranking.slice(index * _ctx.itemsPerCarouselPage, index * _ctx.itemsPerCarouselPage + _ctx.itemsPerCarouselPage), (courier, index2) => {
                                                                                return (_openBlock(), _createElementBlock("div", {
                                                                                    key: index2,
                                                                                    class: "full-width full-height"
                                                                                }, [
                                                                                    _createVNode(_component_CourierIcon, {
                                                                                        valuation: courier.valuation,
                                                                                        cost: courier.cost,
                                                                                        "is-cheapest": index === 0 && index2 === 0,
                                                                                        "append-bottom-badge": _ctx.isFurgonetka(courier.valuation.courierCode)
                                                                                    }, null, 8, ["valuation", "cost", "is-cheapest", "append-bottom-badge"])
                                                                                ]));
                                                                            }), 128))
                                                                        ])
                                                                    ]),
                                                                    _: 2
                                                                }, 1032, ["name"]));
                                                            }), 128))
                                                        ]),
                                                        _: 1
                                                    }, 8, ["modelValue"])
                                                ]))
                                                : _createCommentVNode("", true),
                                            _createElementVNode("div", _hoisted_6, [
                                                _createElementVNode("div", _hoisted_7, [
                                                    _createVNode(_component_AdditionalService, { ref: "additionalService" }, null, 512)
                                                ]),
                                                _createElementVNode("div", _hoisted_8, [
                                                    (_ctx.anyValidValuations())
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                            _createElementVNode("div", _hoisted_10, [
                                                                _createVNode(_component_ShipmentValuationServices)
                                                            ]),
                                                            _createElementVNode("div", _hoisted_11, [
                                                                _createElementVNode("div", _hoisted_12, [
                                                                    _createElementVNode("div", _hoisted_13, [
                                                                        _createElementVNode("div", _hoisted_14, [
                                                                            _createVNode(_component_q_icon, {
                                                                                name: "mdi-information-outline",
                                                                                size: "md"
                                                                            }),
                                                                            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("shipmentValuation.priceBruttoInfo")), 1)
                                                                        ])
                                                                    ]),
                                                                    _createElementVNode("div", _hoisted_16, [
                                                                        _createElementVNode("div", _hoisted_17, [
                                                                            _createVNode(_component_q_btn, {
                                                                                class: "q-pt-xs",
                                                                                label: _ctx.$t('shipmentValuation.reload'),
                                                                                color: "primary",
                                                                                size: "md",
                                                                                onClick: _ctx.reload,
                                                                                disable: _ctx.valuationChanged
                                                                            }, null, 8, ["label", "onClick", "disable"])
                                                                        ])
                                                                    ])
                                                                ])
                                                            ])
                                                        ]))
                                                        : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                                            _createElementVNode("div", _hoisted_19, [
                                                                _createElementVNode("div", _hoisted_20, [
                                                                    _createVNode(_component_q_icon, {
                                                                        name: "mdi-information-outline",
                                                                        size: "md"
                                                                    }),
                                                                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("shipmentValuation.noValuationsInfo")), 1)
                                                                ])
                                                            ]),
                                                            _createElementVNode("div", _hoisted_22, [
                                                                _createElementVNode("div", _hoisted_23, [
                                                                    _createVNode(_component_q_btn, {
                                                                        class: "q-pt-xs",
                                                                        label: _ctx.$t('shipmentValuation.reload'),
                                                                        color: "primary",
                                                                        size: "md",
                                                                        onClick: _ctx.reload
                                                                    }, null, 8, ["label", "onClick"])
                                                                ])
                                                            ])
                                                        ]))
                                                ])
                                            ])
                                        ]),
                                        _: 1
                                    }, 512)
                                ])
                            ], 2)
                        ], 2)
                    ]),
                    _createVNode(_component_q_inner_loading, { showing: _ctx.loading }, null, 8, ["showing"])
                ]),
                _: 1
            })
        ]))
        : _createCommentVNode("", true);
}
