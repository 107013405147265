import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "main-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_toolbar_title = _resolveComponent("q-toolbar-title");
    const _component_User = _resolveComponent("User");
    const _component_q_toolbar = _resolveComponent("q-toolbar");
    const _component_q_header = _resolveComponent("q-header");
    const _component_Menu = _resolveComponent("Menu");
    const _component_router_view = _resolveComponent("router-view");
    const _component_q_page_container = _resolveComponent("q-page-container");
    const _component_Footer = _resolveComponent("Footer");
    const _component_q_layout = _resolveComponent("q-layout");
    return (_openBlock(), _createBlock(_component_q_layout, {
        view: "lHh Lpr lFf",
        class: "bg-color-primary"
    }, {
        default: _withCtx(() => [
            (_ctx.loggedIn)
                ? (_openBlock(), _createBlock(_component_q_header, { key: 0 }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_toolbar, { class: "main-toolbar" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_toolbar_title, null, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t(`page.${_ctx.$route.name}`)), 1)
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_User)
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
            (_ctx.loggedIn)
                ? (_openBlock(), _createBlock(_component_Menu, { key: 1 }))
                : _createCommentVNode("", true),
            _createVNode(_component_q_page_container, { class: "bg-color-primary" }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_router_view)
                    ])
                ]),
                _: 1
            }),
            _createVNode(_component_Footer)
        ]),
        _: 1
    }));
}
